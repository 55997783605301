import React from "react";
import { Box, Button, Grid, Typography, styled } from "@material-ui/core";
import HtmlTypography from "../../../../components/src/HtmlTypography";

interface ImageDivProps {
  title: string;
  description: string;
  image: any;
  direction?: string;
  fullView?:boolean;
  handleNavigation : (screenName: string) => void;
}

export const StyledContainer = styled(Box)({
    "& *": {
      fontFamily: "Silika-Medium",
    },
    "& .findTalentTitleContainer": {
      paddingTop: "116px",
      paddingRight: "142px",
      paddingLeft: "146px",
      justifyContent: "center",
      "@media(max-width:1280px)": {
        paddingRight: "70px",
        paddingLeft: "70px",
        paddingTop: "70px",
      },
      "@media(max-width:720px)": {
        paddingRight: "10px",
        paddingLeft: "10px",
        paddingTop: "30px",
      },
      "@media(max-width:620px)": {
        paddingRight: "5px",
        paddingLeft: "5px",
        paddingTop: "15px",
      },
    },
    "& .findTalentTitle": {
      fontFamily: "Silika-Medium",
      fontWeight: 500,
      fontSize: "64px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "@media(max-width:720px)": {
        fontSize: "36px",
      },
    },
    "& .findPara": {
      fontWeight: "300 !important",
      fontSize: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "#0D0C22",
      marginTop: "49px",
      fontFamily: "Silika-Light",
      lineHeight: "25px",
      "@media(max-width:1280px)": {
        marginTop: "30px",
        fontSize: "14px",
      },
      "@media(max-width:959px)": {
        marginTop: "15px",
        fontSize: "12px",
      },
    },
    "& .blockTitle": {
      fontSize: "48px !important",
      justifyContent: "flex-start",
      "@media(max-width:1100px)": {
        fontSize: "36px !important",
      },
    },
    "& .paraOfDesign": {
      justifyContent: "flex-start !important",
      alignItems: "flex-start !important",
      lineHeight: "25px",
      "@media(max-width:1100px)": {
        fontSize: "14px !important",
        lineHeight: "20px",
        marginTop: "20px !important",
      },
    },
    "& .getStartedBtn": {
      width: "125px",
      height: "48px",
      borderRadius: "2px",
      color: "white",
      textTransform: "none",
      fontFamily: "Silika-Medium",
      marginTop: "32px",
    },
    "& .startBtnProject": {
      backgroundColor: "#0D0C22",
    },
    "& .designResearchContainer": {
      paddingTop: "129px",
      paddingBottom: "129px",
      display: "flex",
      justifyContent: "space-between",
      alignItems : "center",
      paddingRight: "133px",
      paddingLeft: "133px",
      backgroundColor:"#F5F3F3",
      "@media(max-width:1300px)": {
        paddingTop: "100px",
        paddingBottom: "100px",
        paddingRight: "110px",
        paddingLeft: "110px",
      },
      "@media(max-width:1100px)": {
        paddingTop: "60px",
        paddingBottom: "60px",
        paddingRight: "70px",
        paddingLeft: "70px",
      },
      "@media(max-width:959px)": {
        justifyContent: "center",
      },
      "@media(max-width:100px)": {
        paddingTop: "30px",
        paddingBottom: "30px",
        paddingRight: "40px",
        paddingLeft: "40px",
      },
    },
    "& .leftImage": {
      width: "441px",
      height: "313px",
      marginTop: "29px",
      objectFit : "cover",
      "@media(max-width:1100px)": {
        width: "80%",
        marginTop: "5px",
      },
      "@media(max-width:959px)": {
        width: "100%",
      },
    },
    "& .fullImage": {
      width: "612px",
      height: "552px",
      marginTop: "29px",
      objectFit : "cover",
      "@media(max-width:1500px)": {
        width: "80%",
        marginTop: "5px",
      },
      "@media(max-width:959px)": {
        width: "100%",
      },
    },
    "& .rightImage": {
      width: "570px",
      height: "411.74px",
      objectFit : "cover",
      "@media(max-width:1100px)": {
        width: "90%",
      },
      "@media(max-width:959px)": {
        width: "100%",
      },
    },
    "& .imgDivContaier": {
      display: "flex",
      "@media(max-width:959px)": {
        justifyContent: "center",
      },
    },
    "& .contentDivSideDesc": {
      "@media(max-width:1400px)": {
        maxWidth: "582px",
      },
      "@media(max-width:959px)": {
        maxWidth: "100%",
        padding: "20px",
      },
    },
    "& .paragraphTitle": {
      textAlign: "center",
      maxWidth: "1112px",
    },
    "& .questionDiv": {
      display : "flex",
      flexDirection : "column",
      justifyContent : "center",
      alignItems : "center",
      paddingTop : "140px",
      paddingBottom : "140px",
      "@media(max-width:886px)": {
        paddingBottom : "50px",
        paddingTop : "50px",
      },
    },
    "& .questionBox" : {
      width : "886px",
      "@media(max-width:886px)": {
        width : "90%"
      },
    },
    "& .questionBoxTitle" : {
      paddingLeft  :"90px",
      paddingRight : "90px",
      "@media(max-width:886px)": {
        paddingLeft  :"50px",
        paddingRight : "50px",
      }
    },
    "& .questionTitle" : {
      fontFamily: "Silika-Medium",
      textAlign : "center",
      marginBottom : "30px",
      fontWeight: 500,
      fontSize: "48px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "@media(max-width:886px)": {
        fontSize: "30px",
        marginBottom : "20px",
      },
    },
    "& .questionDescription" : {
      fontFamily: "Silika-Light",
      textAlign : "center",
      marginBottom : "30px",
      fontWeight: 300,
      fontSize: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "@media(max-width:886px)": {
        fontSize: "14px",
        marginBottom : "20px",
      },
    }
  });

const ImageDiv: React.FC<ImageDivProps> = ({
  title,
  description,
  image,
  direction = "left",
  fullView=false,
  handleNavigation
}) => {
  return (
    <Grid
      container
      className={`findTalentTitleContainer designResearchContainer ${
        direction === "right" ? "reverseDirection" : ""
      }`}
      style={{
        flexDirection: direction === "right" ? "row-reverse" : "row",
        backgroundColor: direction === "right" ? "white" : "none",
      }}
    >
      <Grid item xl={6} lg={6} md={6} sm={12} className="imgDivContaier">
        <img src={image} alt="image" className={fullView? "fullImage" : `${direction}Image`} />
      </Grid>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        className="contentDivSideDesc"
        style={{
          paddingRight: direction === "right" || fullView ? "40px" : "0",
          paddingLeft : direction === "left" || fullView ? "40px" : "0"
        }}
      >
        <HtmlTypography className="findTalentTitle blockTitle" htmlContent={title} />
        <HtmlTypography className="findPara paraOfDesign" htmlContent={description} />
        <Button className="getStartedBtn startBtnProject" onClick={()=>handleNavigation("AccountLogin")}>Find Talent</Button>
      </Grid>
    </Grid>
  );
};

export default ImageDiv;
