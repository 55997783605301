import React from "react";
// Customizable Area Start
import PostCreationWebController, { Post, Props } from "./PostCreationWebController";
import {
  Grid,
  Typography,
  Container,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Avatar,
  DialogTitle,
  IconButton,
  Chip,
  TextField,
  TextareaAutosize,
  Tooltip,
  Theme, styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import HeaderNavbar from "../../../components/src/Navbar"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline"
import { likeImage, shareImage, saveImage, optionIcon, removeIcon, addIcon, dislikeImage, CopyImage, ChatIcon, BackBtn } from "../../customisableuserprofiles/src/assets";
import PostCreationPopUp from "./components/PostCreationPopUp"
import Preview from "./components/Preview"
import { returnTruthyString } from "../../../components/src/HelperUtils"
import "./PostCreation.css";
import AddCollabarator from "./components/AddCollabarator";
import DeleteCollab from "./components/DeleteCollab";
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import { DialogActionWrapper, DialogContentTextWrapper, DialogContentTextWrapperChat, StyledBox } from "../../../blocks/events/src/EventView.web";
import ClearIcon from '@material-ui/icons/Clear';
import { Autocomplete } from "@material-ui/lab";
import Carousel from "react-elastic-carousel";
import FavoriteIcon from '@mui/icons-material/Favorite';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { orangeBanner } from "../../landingpage/src/assets";
import { customArrow } from "../../../components/src/CustomArrow.web";
import { cancle, restricted, visibilityOn, visibilityOff } from "./assets"

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const ProfileAvatar = styled(Avatar)({
  backgroundColor: "#DFDFDF",
  color: "#9B9B9D",
  fontFamily: "Silika-Medium !important",
  "& .MuiAvatar-img": {
    objectFit: "cover",
  }
});

const StyledCarousel = styled("div")({
  maxWidth: "1140px",
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
  "@media (max-width: 768px)": {
    display: "contents !important"
  },
  "& .collaborationGridItem:hover .collaborationGridItemInside": {
    filter: "grayscale(0.3)",
    backgroundColor: "rgba(217, 217, 217, 0.36)",
    transition: "opacity 0.3s ease",
  },
  "& .collaborationGridItem:hover .collabImgHover, .collaborationGridItem:hover .likeShareSaveButtonsDiv, .collaborationGridItem:hover .removeCollab": {
    opacity: 1
  },
  "& .collaborationGridItem:hover .collabImg": {
    opacity: 0.5
  },
  "& .collabImg": {
    width: "332px",
    height: "280px",
    "@media (max-width: 1138px)": {
      width: "290px",
    },
    "@media (max-width: 1112px)": {
      width: "290px",
    },
    "@media (max-width: 1040px)": {
      width: "230px",
    },
    "@media (max-width: 990px)": {
      width: "250px",
    },
    "@media (max-width: 972px)": {
      width: "260px",
    },
    "@media (max-width: 948px)": {
      width: "240px",
    },
    "@media (max-width: 890px)": {
      width: "220px",
    },
    "@media (max-width: 804px)": {
      width: "200px",
    },
    "@media (max-width: 780px)": {
      width: "200px",
      height: "150px"
    },
    "@media (max-width: 720px)": {
      width: "180px",
    },
    "@media (max-width: 602px)": {
      width: "150px",
    },
    "@media (max-width: 506px)": {
      width: "130px",
    },
    "@media (max-width: 450px)": {
      width: "110px",
      height: "100px"
    },
    "@media (max-width: 390px)": {
      width: "99px",
      height: "77px"
    },
  },
  "& .collabImgHover": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0,
    gap: "10px",
    fontFamily: "Silika-Medium",
    color: "#010101",
    fontSize: "20px",
    "@media (max-width: 824px)": {
      fontSize: "14px",
    },
  },
  "& .restrictedImg": {
    width: "24px",
    height: "24px",
  },
  "& .restrictedDiv": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .restrictedDivMain": {
    position: "absolute",
    top: "0%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.6)"
  },
  "& .confidentialText": {
    fontFamily: "Silika-Medium",
    fontSize: "20px",
    color: "#FFFFFF",
    fontWeight: 500,
    marginLeft: "4px",
    "@media (max-width: 768px)": {
      display: "none"
    },
  },
  "& .confidentialPara": {
    color: "#FFFFFF",
    fontWeight: 500,
    fontFamily: "Silika-Light",
    margin: 0,
    maxWidth: "220px",
    fontSize: "14px",
    "@media (max-width: 768px)": {
      display: "none"
    },
  },
  "& .likeShareSaveButtonsDiv": {
    display: "inline-flex",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "12px",
    opacity: 0,
    "& .actionButton": {
      height: "24px",
      width: "24px",
      pointer:"pointer",
      "@media (max-width: 802px)": {
        height: "18px",
        width: "18px",
      },
      "&:first-child": {
        marginRight: "12px"
      }
    },
    "@media (max-width: 768px)": {
      display: "none"
    },
  },
  "& .actionButtonImg": {
    height: "22px",
    width: "22px",
    marginRight: "12px",
    cursor:"pointer",
    "@media (max-width: 802px)": {
      height: "18px",
      width: "18px",
    },
  },
  "& .customArrow": {
    position: "absolute",
    top: "45%",
    transform: "translateY(-50%)",
    zIndex: 1,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    }
  },
  "& .prevArrow": {
    left: "30px"
  },
  "& .nextArrow": {
    right: "30px"
  },
  "& .rec-swipable": {
    width: "100% !important"
  },
  "@media (max-width: 2000px)": {
    "& .carousel-item": {
      width: "33.33%",
    },
  },
  "@media (max-width: 480px)": {
    "& .carousel-item": {
      width: "100%",
    },
  },
});


const StyledShareChatDial = styled(Dialog)({
  "& .headingText": {
    fontFamily: "Silika-Medium !important"
  }
})

export const dropdownLinks = (navText: string, handleLink: () => void) => {
  return (
    <div >
      <div>
        <div onClick={handleLink}
          data-test-id="dropdownLinks"
          style={{ ...webStyle.discoverHyperLink }}>
          {navText}
        </div>
      </div>
      <Box style={webStyle.discoverDropDownWidth}>
        <Typography
          style={webStyle.discoverOptionBorder}
        />
      </Box>
    </div>
  )
}

const StyledProfile = styled("div")({
  "@media (max-width: 768px)": {
    border: "1px solid #DFDFDF",
    margin: "10px !important",
    borderRadius: "4px !important",
    width: "96.5% !important",
    display: "flex",
    flexDirection: "column-reverse"
  },
  "@media (max-width: 564px)": {
    width: "95% !important"
  },
  "@media (max-width: 394px)": {
    width: "94% !important"
  },
  "& .user-profile-img": {
    width: "20%",
    "@media (max-width: 768px)": {
      marginTop: "-130px"
    },
    "@media (max-width: 450px)": {
      marginTop: "-110px"
    }
  },
  "& .user-detail-div": {
    width: "78%",
    "@media (max-width: 768px)": {
      width: "100%"
    }
  },
  "& .outerMostMobileSizer": {
    height: "auto !important",
    // border: "1px solid #DFDFDF",
    marginTop: "10px !important",
    paddingBottom: "10px !important",
    borderRadius: "4px !important",
    justifyContent: "center !important",
    width: "86% !important",
    marginLeft: "auto !important",
    marginRight: "auto !important",
    marginBottom: "10px !important",
  },
  "& .profileImgMob": {
    "@media (max-width: 1026px)": {
      height: "170px !important"
    },
    "@media (max-width: 924px)": {
      height: "150px !important"
    },
    "@media (max-width: 768px)": {
      border: "5px solid white",
      height: "100px !important"
    },
    "@media (max-width: 450px)": {
      height: "61px !important",
      width: "61px !important"
    },
  },
  "& .levelDivMobile": {
    "@media (max-width: 768px)": {
      width: "100% !important"
    }
  },
  "& .userNameMob": {
    "@media (max-width: 768px)": {
      display: "none !important"
    }
  },
  "& .locationOuterDiv": {
    "@media (max-width: 768px)": {
      marginRight: "0px !important",
      marginTop: "0px !important"
    }
  }
})
// Customizable Area End

export default class ListPost extends PostCreationWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderCopyStatusFun() {
    return this.renderCopyStatus() && <Typography className="isNoErrorTextStyle">{this.renderCopyStatus()}</Typography>
  }

  renderCopyStatus() {
    if (this.state.msgSend) {
      return "Message sent!"
    }
    if (this.state.isCopied !== null) {
      return this.state.isCopied ? "Link copied!" : "Copy error to clipboard";
    }
  }

  ShareLinkDialoge = () => {
    return (<StyledShareChatDial
      open={this.state.isOpenModelShare}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      keepMounted
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Box sx={webStyle.modelHeadingstyle}>
              <Box sx={webStyle.headingText} className="headingText yes">
                Share
              </Box>
              <Box>
                <IconButton data-testId="clsoePopupModelId" onClick={this.handleClose}>
                  <ClearIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentTextWrapper id="alert-dialog-slide-description">
          <Box className="text-field-contant">
            <Box className="textfieldCopy"><Typography className="textfieldCopy-input">{this.state.linkShare}</Typography>
              <IconButton onClick={this.handleClickCopy} className="copyImg-contant" data-testId="copyBtnId">
                <img src={CopyImage} className="copyImg" />
              </IconButton>
            </Box>
          </Box>
          {this.renderCopyStatusFun()}
        </DialogContentTextWrapper>
      </DialogContent>
      <DialogActionWrapper>
        <Button onClick={this.handleChat} className="copyBtnStyle" data-testId="chatModelId">
          <img src={ChatIcon} />
        </Button>
      </DialogActionWrapper>
    </StyledShareChatDial>
    );
  }

  getButtonClass() {
    return this.state.shareData?.length === 0 ? 'opacity-05' : '';
  }

  ShareLinkChat = () => {
    return (<Dialog
      maxWidth="sm"
      fullWidth
      onClose={() => { }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      open={this.state.isSendLinkToChatModalOpen}
      keepMounted
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Box sx={webStyle.modelHeadingstyleChatModel}>
              <Box onClick={this.handleNavigateBack} data-testId="navigateBackId"><img src={BackBtn} /></Box>
              <Box sx={webStyle.headingText}>
                Share
              </Box>
              <Box>
                <IconButton onClick={this.handleCloseChatModel} data-testId="closePopupModelId">
                  <ClearIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentTextWrapperChat id="alert-dialog-slide-description">
          <Box className="DialogContentDiv">
            <Typography variant="subtitle1">To:</Typography>
            <Autocomplete
              multiple
              id="tags-outlined"
              value={this.state.shareData}
              data-testId="autoId"
              options={this.state.getAllData?.map((option: { attributes: { first_name: string, last_name: string, photo: string } }) => ({
                firstName: option?.attributes?.first_name,
                lastName: option?.attributes?.last_name,
                photo: option?.attributes?.photo,
              }))}
              getOptionLabel={(option: { firstName: string, lastName: string, photo: string }) => `${option.photo} ${option.firstName} ${option.lastName}`}
              renderOption={(option, props) => (
                <StyledBox component={"span"} {...props}>
                  <img className="imageLogoStyle" src={option?.photo} height={24} width={24} alt="avtrrrr" />
                  <Box style={{ fontFamily: "Silika-Medium" }}>{`${option?.firstName} ${option?.lastName}`}</Box>
                </StyledBox>
              )}
              onChange={this.handleshareDataChatChange}
              renderTags={(value, getTagProps) => (
                value?.map((option, index) => (
                  <Chip
                    label={
                      <Box component="span" className="chip-box-style">
                        <img className="imageLogoStyle" width={24} height={24} src={option?.photo} alt="AvatarLogo" />
                        <Box>{option.firstName} {option.lastName}</Box>
                      </Box>
                    }
                    {...getTagProps({ index })}
                    variant="outlined"
                  />
                ))
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search by name"
                />
              )}
            />
          </Box>
          <TextareaAutosize
            className="textAreaStyle"
            aria-label="minimum height"
            minRows={4}
            value={this.state.sendChatMessage}
            data-testId="send"
            onChange={this.handlesendChatMessage}
            placeholder="Write a message"
          />
        </DialogContentTextWrapperChat>
      </DialogContent>
      <DialogActionWrapper>
        <Button onClick={this.handleSend} className={`SendBtnStyle ${this.getButtonClass()}`} data-testId="sendDataId" disabled={this.state.shareData?.length === 0}>Send</Button>
      </DialogActionWrapper>
    </Dialog>)
  }

  renderLikeSaveShare = (index: number, isFavorited: boolean, isLiked: boolean, id: string, postID: number) => {
    return <>
      <div className="likeShareSaveButtonsDiv" data-test-id="likeShareSaveButtonsDiv" key={index}>
        {isLiked ? (
          <FavoriteIcon className="actionButton" data-test-id="favoriteIconCreator" onClick={()=>{this.likeCreatorPost("unlike",postID)}} />
        ) : (
          <FavoriteBorderIcon className="actionButton" data-test-id="favoriteBorderIcon" onClick={()=>{this.likeCreatorPost("like",postID)}}  />
        )}

        <img src={shareImage} className="actionButtonImg" height={20} width={20} data-test-id="openPopupGallery" onClick={() => { this.openPopUpShareModal(postID, "PostDetails") }}  />
        {isFavorited ? (
          <BookmarkIcon className="actionButton" data-test-id="bookmarkIconCreator" onClick={() => this.handleDeleteFavProfile(postID, "post")}  />
        ) : (
          <BookmarkBorderIcon className="actionButton" data-test-id="bookmarkBorderIcon" onClick={()=>{this.handleAddFavouriteProfile(postID, "BxBlockPosts::Post")}} />
        )}
      </div>
    </>
  }

  isLikedPost=(postId:number):boolean=>{
    return this.state.AllPostLikes.some(
      (like: any) => like.attributes.post_id === postId
    );
  }

  isFavouritePost = (postId:number):boolean=>{
    return this.state.allSavedPost.some(
      (like: any) =>
        like.attributes.favouriteable_id === postId
    );
  }

  carouselCreatorPost = () => {
    const lengthIsGreater = this.state.getAllCreatorPost.length > 3;
    return (<StyledCarousel>
      <div style={{ marginTop: 44, position: "relative" }}>
        <Carousel
          renderArrow={(props) => customArrow({ ...props, lenghIsGreater: lengthIsGreater })}
          isRTL={false}
          itemsToShow={3}
          data-test-id="carousel-main-div"
        >
          {this.state.getAllCreatorPost.length > 0 && this.state.getAllCreatorPost.map((post: Post,index) => {
            return <Grid
              className="collaborationGridItem"
              data-test-id="listPostITemDiv"
              style={{ position: "relative", display: "inline-block",cursor:"pointer" }}
              onClick={()=>this.navigatePostDetailTOPostDetail(post.id)}
            >
              {!post.attributes.confidential && (
                <div className="collaborationGridItemInside" key={post.id}>
                  <img
                    data-test-id="postImg"
                    src={typeof post?.attributes?.images?.[0]?.url !== 'undefined' ? post.attributes.images[0].url : orangeBanner}
                    className="collabImg"
                   
                  />
                  <div className="collabImgHover">{post.attributes.project_title}</div>
                  {this.renderLikeSaveShare(
                    index,
                    this.isFavouritePost(post.attributes.id),
                    this.isLikedPost(post.attributes.id),
                    "1",
                    post.attributes.id,
                  )}
                </div>
              )}
              {post.attributes.confidential && (
                <div className="collaborationGridItemInside" key={post.id}>
                  <img
                    data-test-id="ImgPostConfidential"
                    style={{ opacity: "0.2" }}
                    src={typeof post?.attributes?.images?.[0]?.url !== 'undefined' ? post.attributes.images[0].url : orangeBanner}
                    className="collabImg"      
                  />
                  <div className="restrictedDivMain">
                    <div className="restrictedDiv">
                      <img
                        className="restrictedImg"
                        data-test-id="restrictedImgDiv"
                        src={restricted}
                      />
                      <p className="confidentialText">Confidential</p>
                    </div>
                    <p className="confidentialPara">
                      You have to be a collaborator to view this
                      content.
                    </p>
                  </div>
                </div>
              )}
            </Grid>
          })}
        </Carousel>
      </div>
    </StyledCarousel>)
  }

  creatorProfileLike = (isCollaborationPost: boolean, post: any, isLiked: boolean, isSavedProfile: boolean) => (
    <>{isCollaborationPost && (
      <div
        style={webStyle.shareButton}
        className="shareLikBtnMobile"
      >
        <Tooltip
          title={
            isLiked
              ? "You recommend working with this person."
              : "Do you recommend working with this person?"
          }
          data-testid="tooltip"
        >
          {isLiked ? (
            <img
              src={dislikeImage}
              onClick={() =>
                this.profileLike(post.profile.data.attributes.id)
              }
              style={{ cursor: "pointer" }}
              height={24}
              width={24}
              data-testid="thumb-up-icon"
            />
          ) : (
            <img
              src={likeImage}
              onClick={() =>
                this.profileLike(post.profile.data.attributes.id)
              }
              style={{ cursor: "pointer" }}
              height={24}
              width={24}
              data-testid="thumb-up-outlined-icon"
            />
          )}
        </Tooltip>
        <div>
          <ChatBubbleOutlineIcon
            height={24}
            width={24}
            style={webStyle.imgPaddingLeft}
          />
          <img
            src={shareImage}
            data-test-id="shareEventBtn"
            onClick={() => {
              this.openPopUpShareModal(
                post.profile.data.attributes.id, "profileInfo"
              );
            }}
            height={24}
            width={24}
            style={webStyle.imgPaddingLeft}
          ></img>
          {!isSavedProfile ? (
            <img
            height={24}
            width={24}
              src={saveImage}
              onClick={() =>
                this.handleAddFavouriteProfile(
                  post.profile.data.attributes.id, "BxBlockProfile::Profile"
                )
              }
              data-test-id="saveProfileImg"
            ></img>
          ) : (
            <TurnedInIcon
              onClick={() =>
                this.handleDeleteFavProfile(
                  post.profile.data.attributes.id, "profile"
                )
              }
              data-test-id="unSaveProfileImg"
            />
          )}
        </div>
      </div>
    )}
    </>
  )

  postDetailMedias = (isCollaborationPost: boolean, post: any,  showOptions: boolean, isOwner: boolean) => (<>
    {post.images.slice(1)?.map((image: any, index: number) => {
      const urlParams = new URLSearchParams(image.url.split('?')[1]);
      const type = urlParams.get('type');
      return (
        <>
        <div style={webStyle.postImgDivNew} data-test-id="post-img-div" className="postImgDivMob">
          {type && type.includes("image") ? (
            <img
              style={{ ...webStyle.postImg, objectFit: 'cover', filter: image.is_visible ? "blur(0)" : "blur(2px)" }}
              className="postImgMob"
              src={image.url}
              alt="postImage"
              data-test-id="post-image"
            />
          ) :
            (
              <video
                style={{ ...webStyle.postImg, objectFit: 'cover', filter: image.is_visible ? "blur(0)" : "blur(2px)" }}
                className="postImgMob"
                controls
                data-test-id="post-video"
              >
                <source src={image.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
         {(!isCollaborationPost && showOptions && isOwner) && <div>
          {this.renderVisibilityIcons(image.is_visible, () => this.setVisibleImage(image.id, false), () => this.setVisibleImage(image.id, true))}
          </div>}
        </div>
        <div style={webStyle.borderDiv}>
          <Typography style={webStyle.borderBottomNew} className="collabBtmBorderMob" />
        </div>
        </>
      )
    })}
  </>)

  postCollabrator = (isCollaborationPost:boolean, post:any, isOwner: boolean) => (<>
    {post.collaborators.map((collaborator:any, index:number) => {
      if (collaborator?.error) return null
      else return (
        <div>
          {collaborator.profile &&
            <Grid spacing={1}
              style={webStyle.collabCard}
              data-test-id="collab-grid-item"
              className="collabBoxMob"
            >
              {!isCollaborationPost && <div style={{ float: "right" }} className="deleteIconMob">
              {isOwner &&
                <img
                  data-test-id="deleteIcon"
                  onClick={() => { this.setState({ deleteCollabPopUp: true, deleteID: collaborator.id }) }

                  }
                  src={removeIcon}
                  style={webStyle.deleteCollabIcon}
                />
               }
              </div>}
              <div style={{ height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <ProfileAvatar src={collaborator.profile.photo} style={webStyle.collabImgStyle}>
                  {returnTruthyString(collaborator.profile.first_name[0]) + returnTruthyString(collaborator.profile.last_name[0])}
                </ProfileAvatar>
              </div>
              <div className="collabTlDescMob">
                <Typography style={{ color: '#0D0C22', fontSize: '20px', fontWeight: 500, lineHeight: '40px', fontFamily: "Silika-Medium" }}>
                  {collaborator.profile.first_name + " " + collaborator.profile.last_name}
                </Typography>
                <Typography style={webStyle.collabBtmBorder} className="collabBtmBorderMob df"></Typography>
                <Typography style={{ fontFamily: "Silika-Light", color: '#0D0C22', fontSize: '14px', fontWeight: 300, lineHeight: '19.69px', paddingTop: "10px" }} className="headlineMob">
                  {post.profile.data.attributes.headline}
                </Typography>
              </div>
            </Grid>}
        </div>
      )


    }

    )}
  </>)


  renderVisibilityIcons = (isVisible: boolean, onClickVisibilityOffHandler: () => void, onClickVisibilityOnHandler: () => void) => {
    if(isVisible){
      return (
        <StyledEyeIcon data-test-id="eyeOpen" 
        onClick={() => onClickVisibilityOffHandler()}
        ><img
          src={visibilityOn}
          alt="eyeOpen"
        />
        </StyledEyeIcon>
      )
    }else{
      return <StyledEyeIcon data-test-id="eyeClose"
      onClick={() => onClickVisibilityOnHandler()}
      ><img
        src={visibilityOff}
        alt="eyeClose"
      />
      </StyledEyeIcon>
    }
  }

  postImgDiv = (type:any,post:any,isOwner:boolean, showOptions: boolean) => (
    <div style={webStyle.postImgDiv} className="postImgDivMob">
    {type && type.includes("image") ? (
      <img
        style={{ ...webStyle.postImg, objectFit: 'cover', filter: post.images[0]?.is_visible ? "blur(0)" : "blur(2px)" }}
        className="postImgMob"
        src={post.images[0]?.url}
        alt="postImage"
        data-test-id="post-image"
      />
    ) :
      (
        <video
          style={{ ...webStyle.postImg, objectFit: 'cover', filter: post.images[0]?.is_visible ? "blur(0)" : "blur(2px)" }}
          className="postImgMob"
          controls

        >
          <source src={post.images[0]?.url} type="video/mp4" data-test-id="post-video" />
          Your browser does not support the video tag.
        </video>
      )}
      {isOwner && showOptions &&
    <div>
      
      {this.renderVisibilityIcons(post.images[0]?.is_visible, () => this.setVisibleImage(post.images[0]?.id, false), () => this.setVisibleImage(post.images[0]?.id, true))}
    </div>}
  </div>
  )


  // Customizable Area End 
  render() {
    // Customizable Area Start
    const post = this.state.postData.attributes ?? {}
    const showOptions = this.state.postData.attributes?.images.length>1
    const profileImage = returnTruthyString(post?.profile?.data.attributes.photo)
    const avatarName = returnTruthyString(post?.profile?.data.attributes.first_name[0]) + returnTruthyString(post?.profile?.data.attributes.last_name[0])
    const urlParams = new URLSearchParams(post?.images[0]?.url.split('?')[1]);
    const type = urlParams.get('type');
    const isOwner = post?.profile?.data.attributes.id.toString() === sessionStorage.getItem("profileId")
    const userProfileId = sessionStorage.getItem("profileId");
    const postProfileId = post?.profile?.data.attributes.id;
    const isCollaborationPost = userProfileId !== postProfileId.toString();
    const confirmDeletePost = () => {
      return (
        <Dialog
          style={{ padding: "20px" }}
          open={this.state.deleteModal}
          onClose={this.handleDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={webStyle.headerContent}>
            <DialogContentText id="alert-dialog-description" style={{ ...webStyle.textStyles, textAlign: "center" }}>
              Are you sure you want to delete this post?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "10px 20px" }}>
            <Button variant="outlined" id="close-delete-collab-popup" data-id="cancelButton" style={{ ...webStyle.buttonstyles, textTransform: "none" }} onClick={this.handleDelete}>Cancel</Button>
            <Button style={{ ...webStyle.containedButton, textTransform: "none" }} variant="contained" id="yes-delete-btn" onClick={this.handleDeletePost} >
              Yes, delete
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
    const isLiked = this.state.allLikes.some(
      (likes: any) =>
        likes.attributes.likeable_id === post.profile.data.attributes.id
    );


    const isSavedProfile = this.state.allSavedProfile.some(
      (likes: any) =>
        likes.attributes.favouriteable_id === post.profile.data.attributes.id
    );
    const isSavedPost = this.state.allSavedPost.some(
      (likes: any) =>
        likes.attributes.favouriteable_id === post.id
    );

    let hasHourlyRate = post.profile.data.attributes.hourly_rate_from !== null && post.profile.data.attributes.hourly_rate_to !== null;

    return (
      <ThemeProvider theme={theme}>
        <HeaderNavbar navigation={this.props.navigation} />
        <DeleteCollab state={this.state} handleDelete={this.handleDeleteCollab} deleteCollabApi={this.deleteCollabApi} />
        <AddCollabarator state={this.state} closeDialougCollab={this.closeDialougCollab} handleAddCollab={this.handleAddCollab} />
        {this.state.editPostPopUp && (
          <PostCreationPopUp
            handleKeyDownDes={this.handleKeyDownDes}
            handleKeyDown={this.handleKeyDown}
            handleChangeToggle={this.handleChangeToggle}
            updatePost={this.updatePost}
            removeImage={this.removeImage}
            handleImageChange={this.handleImageChange}
            state={this.state}
            handlePreviewOpen={this.handlePreviewOpen}
            handleClosePopUp={this.handleDisplayClose}
            savePopUpData={this.savePopUpData}
            isEditMode={true}
          />
        )}
        {this.state.displayPreview && (
          <Preview
            state={this.state}
            updatePost={this.updatePost}
            handlePreviewClose={this.handlePreviewClose}
            isEdited={true}
            profileImage={post.profile.data.attributes.photo}
            handleSetVisiblePreview={this.handleSetVisiblePreview} 
          />
        )}

        {this.state.deleteModal && (
          <div data-id="deleteModalOverlay" style={webStyle.deleteModalOverlay}>
            {confirmDeletePost()}
          </div>
        )}
        <div onClick={this.handleClick}>
          <Container data-test-id="postContainer" style={webStyle.boxContainer} className="boxContainerMob">
            <div style={webStyle.profileDiv} className="profileDivMob">
              <div style={webStyle.profileimgContainer} className="profileImgMob">
                <ProfileAvatar src={profileImage} style={webStyle.profileImgStyle}>
                  {avatarName}
                </ProfileAvatar>
              </div>
              <div style={webStyle.userNameDiv}>
                <div className="descNameMob">
                  <Typography style={webStyle.describtionInterior}>{post.project_title}</Typography>
                  <Typography style={webStyle.userNameInterior}>
                    {post.profile.data.attributes.first_name + " " + post.profile.data.attributes.last_name}
                  </Typography>
                </div>
              </div>
              <div style={webStyle.rightAlignOption} className="rightAlignOptionMob">
                {!isCollaborationPost && isOwner && <img
                  onClick={this.handleOptionOpen}
                  src={optionIcon}
                  alt="option"
                  style={webStyle.headerImage}
                  data-test-id="optionIcon"
                />}
                {this.state.optionModal && (
                  <div
                    data-id="optionBoxModal"
                    onClick={this.handleOptionClose}
                    style={webStyle.optionHyperLinkDiv}
                  >
                    {dropdownLinks("Edit", () => this.handleEditOpen(this.state.postData))}
                    {dropdownLinks("Delete", () => this.handleDeletePostPopUp(this.state.postData.id))}
                  </div>
                )}
                <img
                  onClick={this.navigateToWorkTab}
                  src={cancle}
                  alt="option"
                  style={webStyle.headerImage}
                  data-test-id="ProfileInfoPhoto"
                />
              </div>
            </div>
             {this.postImgDiv(type,post,isOwner, showOptions)}
            <div style={webStyle.postShareButton} className="shareBtnDivMob">
              <div style={{ display: "flex" }}>
                {this.state.isPostLiked ?
                  <FavoriteIcon height={24} onClick={() => this.likePost("unlike")} width={24} data-test-id="likeIcon2">
                  </FavoriteIcon>
                  :
                  <FavoriteBorderIcon height={24} onClick={() => this.likePost("like")} width={24} data-test-id="unLikeIcon2">
                  </FavoriteBorderIcon>
                }
                  <Typography style={{ paddingLeft: "12px", fontFamily: "Silika-Medium" }}>
                    {post.like_counts} Likes
                  </Typography>
              </div>
              <div>
                <img src={shareImage} data-test-id="sharePostBtn" onClick={() => { this.openPopUpShareModal(this.id, "PostDetails") }} height={24} width={24} style={webStyle.imgPaddingLeft}>
                </img>
                {!isSavedPost ? <img src={saveImage} height={24} width={24}
                  onClick={() => this.handleAddFavouriteProfile(post.id, "BxBlockPosts::Post")} data-test-id="savePostImg"></img> :
                  <TurnedInIcon onClick={() => this.handleDeleteFavProfile(post.id, "post")} data-test-id="unSavePostImg" />}
              </div>
            </div>
            <div style={webStyle.descBox} className="descBoxMob">
              <Typography style={webStyle.description}>
                {post.description}
              </Typography>
            </div>
            {this.postDetailMedias(isCollaborationPost, post, showOptions, isOwner)}
            <div className="tagsContainerMob">
              <Typography style={webStyle.collabHeader} className="tagHeaderMob">Project Tags</Typography>
              <Grid style={{ margin: "20px 100px", display: "flex", gap: "20px" }} className="tagMob" spacing={1}>
                {post.tag_list.map((tag, index) => (
                  <Grid spacing={1}
                    style={webStyle.tagCard}
                    data-test-id="tag-grid-item"
                    className="tagCardMob"
                  >
                    <div style={{ display: "flex", alignItems: "center" ,justifyContent: "center" }}>
                      <Typography style={webStyle.tagContent}>{tag}</Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
            <div style={webStyle.borderDiv}>
                  <Typography style={webStyle.borderBottomNew} className="collabBtmBorderMob" />
                </div>
            {this.state.postData.attributes.collaborators.length> 0 &&
              <div>
                <div className="tagsContainerMob">
                  <Box style={webStyle.collabAddBox}>
                    <Typography style={webStyle.collabHeader} className="tagHeaderMob">Collaborators</Typography>
                    {!isCollaborationPost && isOwner &&<img
                      onClick={() => this.openDialougCollab()}
                      src={addIcon}
                      alt="addCollab"
                      data-test-id="addCollabPopup"
                      style={webStyle.addCollabIcon}
                    />}
                  </Box>
                  <Grid style={{ margin: "20px 100px", display: "flex", flexWrap: "wrap", gap: "24px" }} className="collabDivMob" spacing={1}>
                   {this.postCollabrator(isCollaborationPost, post, isOwner)}
                  </Grid>
                </div>
                <div style={webStyle.borderDiv}>
                  <Typography style={webStyle.borderBottomNew} className="collabBtmBorderMob" />
                </div>
              </div>}
            <StyledProfile
              style={webStyle.outerMostContainerSize}
              className="outerMostMobileSizer">
              <div style={webStyle.outerMostContainerSize} className="outerMostMobileSizer yee ofd">
                <div style={webStyle.profileinfoDiv} className="imgDivMob">
                  <div className="user-profile-img">
                    <ProfileAvatar src={profileImage} style={webStyle.profileImg} className="profileImgMob">
                      {avatarName}
                    </ProfileAvatar>
                  </div>
                  <div className="user-detail-div">
                    <div style={webStyle.userNameDiv} className="userNameMob">
                      <div className="mobDescNameMob">
                        <Typography style={webStyle.userName}>{post.profile.data.attributes.first_name + " " + post.profile.data.attributes.last_name}</Typography>
                        <Typography style={webStyle.describtion}>
                          {post.profile.data.attributes.headline}
                          {hasHourlyRate ? " | " +`£${post.profile.data.attributes.hourly_rate_from} - £${post.profile.data.attributes.hourly_rate_to}` : ""}
                          </Typography>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                      </div>
                    </div>

                    <div style={webStyle.borderDiv}>
                      <Typography
                        style={webStyle.borderBottom}
                        className="collabBtmBorderMob"
                      />
                    </div>
                    <div className="table-description-wrapper">
                      <div style={webStyle.levelOuterDiv}>
                        <div style={webStyle.levelDiv}>
                          <Typography style={webStyle.level}>Level:</Typography>
                          <Typography style={webStyle.levelPosition}>
                            {post.profile.data.attributes.seniority_level}
                          </Typography>
                        </div>
                        <div style={webStyle.locationDiv}>
                          <Typography style={webStyle.location}>
                            Location:
                          </Typography>
                          <LocationOnIcon style={webStyle.locationIcon} />
                          <Typography style={webStyle.locationName}>
                            {post.profile.data.attributes.city +
                              ", " +
                              post.profile.data.attributes.country}
                          </Typography>
                        </div>
                      </div>
                      <div style={webStyle.borderDiv}>
                        <Typography style={webStyle.borderBottom} />
                      </div>
                      <div style={webStyle.typeOuterDiv}>
                        <div style={webStyle.typeDiv}>
                          <Typography style={webStyle.type}>Type:</Typography>
                          <Typography style={webStyle.workingType}>
                            {post.profile.data.attributes.work}
                          </Typography>
                        </div>
                        <div style={webStyle.professionDiv}>
                          <Typography style={webStyle.profession}>
                            Profession:
                          </Typography>
                          <Typography style={webStyle.professionType}>
                            {post.profile.data.attributes.profession}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className="table-mobile-wrapper">
                      <div
                        style={webStyle.levelOuterDiv}
                        className="locationOuterDiv"
                      >
                        <Typography style={webStyle.describtion}>
                        {post.profile.data.attributes.headline}
                        {hasHourlyRate ? " | " +`£${post.profile.data.attributes.hourly_rate_from} - £${post.profile.data.attributes.hourly_rate_to}` : ""}
                        </Typography>
                        <div style={webStyle.levelDiv} className="levelDivMobile">
                          <Typography style={webStyle.level}>Level:</Typography>
                          <Typography style={webStyle.levelPosition}>
                            {post.profile.data.attributes.seniority_level}
                          </Typography>
                        </div>
                        <div className="typeMobileDiv levelDivMobile">
                          <Typography style={webStyle.type}>Type:</Typography>
                          <Typography style={webStyle.workingType}>
                            {post.profile.data.attributes.work}
                          </Typography>
                        </div>
                        <div className="typeMobileDiv levelDivMobile">
                          <Typography style={webStyle.profession}>
                            Profession:
                          </Typography>
                          <Typography style={webStyle.professionType}>
                            {post.profile.data.attributes.profession}
                          </Typography>
                        </div>
                        <div style={webStyle.locationDiv} className="levelDivMobile">
                          <Typography style={webStyle.location}>
                            Location:
                          </Typography>
                          <LocationOnIcon style={webStyle.locationIcon} />
                          <Typography style={webStyle.locationName}>
                            {post.profile.data.attributes.city +
                              ", " +
                              post.profile.data.attributes.country}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div style={webStyle.borderDiv}>
                      <Typography
                        style={webStyle.borderBottom}
                        className="collabBtmBorderMob"
                      />
                    </div>
                    {this.creatorProfileLike(isCollaborationPost, post, isLiked, isSavedProfile)}
                  </div>
                  {this.ShareLinkDialoge()}
                  {this.ShareLinkChat()}
                </div>
              </div>
              {this.carouselCreatorPost()}
            </StyledProfile>
          </Container>
        </div>

      </ThemeProvider>
    );
    // Customizable Area End  
  }
}

// Customizable Area Start
export const webStyle = {
  boxContainer: {
    border: "1px solid #D9D9D9",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    marginTop: "100px",
    padding: "40px 0"
  },
  deleteModalOverlay: {
    position: "fixed" as "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  headingText: {
    width: "100%", textAlign: "center",
    fontSize: '24px',
    fontWeight: 500, color: "#010101"
  },
  modelHeadingstyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  profileinfoDiv: {
    maxWidth: "1093px",
    display: "flex",
    margin: "auto",
    paddingTop: "34px",
    flexWrap: "wrap" as "wrap",
    justifyContent: "space-between"
  },
  profileDiv: {
    display: "flex",
    position: "relative" as "relative",
    maxWidth: "1093px",
    gap: "40px",
    margin: "auto",
  },
  profileimgContainer: {
    maxWidth: "100px",
  },
  profileImgNew: {
    width: "60px",
    Height: "60px",
    borderRadius: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  describtionInterior: {
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#121212"
  },
  userNameInterior: {
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "normal",
    fontStyle: "normal",
    color: "#64748B"
  },
  postImg: {
    width: "100%",
    height: "500px",
    backgroundRepeat: "no-repeat"
  },
  postImgDiv: {
    position: 'relative' as 'relative',
    padding: "30px 100px 10px 100px"
  },
  postImgDivNew: {
    position: 'relative' as 'relative',
    padding: "10px 100px 10px 100px"
  },
  borderBottomNew: {
    borderBottom: "1px solid",
    color: "#DFDFDF",
    margin: "0 100px"
  },
  tagCard: {
    width: "134px",
    height: "33px",
    borderRadius: "2px",
    border: "1px solid #DFDFDF",
  },
  tagContent: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "14.48px",
    color: "#000000",
    paddingTop: "8px"
  },
  collabHeader: {
    width: "100%",
    fontSize: "20px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "24.13px",
    color: "#000000",
    margin: "20px 100px 10px 100px"
  },
  collabCard: {
    width: "305px",
    height: "280.5px",
    borderRadius: "2px",
    border: "1px solid #DFDFDF",
    padding: '18px'
  },
  collabProfileImg: {
    height: '100px',
    width: '100px',
    borderRadius: '50%'
  },
  collabBtmBorder: {
    borderBottom: "1px solid #DFDFDF",
    width: "100%",
    fontFamily: "Silika-Light"
  },
  postShareButton: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 100px"

  },
  profileimgDiv: {
    maxWidth: "189px",
  },
  profileImg: {
    borderRadius: "100px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "189px",
    fontSize: "50px",
  },
  userNameDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  userName: {
    fontSize: "26px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "normal",
    fontStyle: "normal",
  },
  describtion: {
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    marginTop: "18px",
  },
  borderDiv: {
    marginTop: "15px",
  },
  borderBottom: {
    borderBottom: "1px solid",
    color: "#DFDFDF",
  },
  levelOuterDiv: {
    display: "flex",
    marginTop: "14px",
    marginLeft: "50px",
  },
  levelDiv: {
    display: "flex",
    width: "50%",
  },
  level: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#9B9B9D",
    alignSelf: "center",
  },
  levelPosition: {
    fontFamily: "Silika-Medium",
    fontSize: '12px',
    lineHeight: "140.625%",
    fontStyle: "normal",
    alignSelf: "center",
    marginLeft: "13px",
  },
  locationDiv: {
    display: "flex",
  },
  location: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#9B9B9D",
    alignSelf: "center",
  },
  locationIcon: {
    height: "20px",
    width: "20px",
    flexShrink: 0,
    marginLeft: "7px",
    marginRight: "4px",
  },
  locationName: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    alignSelf: "center"
  },
  typeOuterDiv: {
    display: "flex",
    marginTop: "14px",
    marginLeft: "50px",
  },
  typeDiv: {
    display: "flex",
    width: "50%",
  },
  type: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#9B9B9D",
    alignSelf: "center",
  },
  workingType: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    alignSelf: "center",
    marginLeft: "13px",
  },
  professionDiv: {
    display: "flex",
    width: "50%",
  },
  profession: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#9B9B9D",
    alignSelf: "center",
  },
  professionType: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    alignSelf: "center",
    marginLeft: "13px",
  },
  descBox: {
    padding: "30px 100px"
  },
  description: {
    fontSize: "14px",
    fontFamily: "Silika-Light",
    lineHeight: "16.88px",
    color: "#000000",
    paddingTop: "10px",
    whiteSpace: 'pre-wrap' as 'pre-wrap' 
  },
  shareButton: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px"
  },
  imgPaddingLeft: {
    paddingRight: "10px",
    cursor: "pointer"
  },
  rightAlignOption: {
    display: "flex" as "flex",
    height: '100%',
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute" as "absolute",
    right: 0
  },
  headerImage: {
    height: "24px",
    width: "24px",
    flexShrink: 0,
    cursor: "pointer"
  },
  optionHyperLinkDiv: {
    position: "absolute" as "absolute",
    top: "50px",
    right: "5px",
    zIndex: 99999,
    background: "#FFF",
    borderRadius: "1px",
    padding: "4px",
    border: "1px solid #9B9B9D",
    gap: "2px",
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start" as "flex-start",
  },
  discoverHyperLink: {
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Silika-Medium",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#0D0C22",
    padding: "9px 20px 9px 16px",
    gap: "8px",
    cursor: 'pointer',
    textAlign: "start" as "start"
  },
  discoverOptionBorder: {
    borderBottom: "0.6px solid #9B9B9D",
    width: "148px",
    opacity: "16%",
    margin: "auto"
  },
  discoverDropDownWidth: {
    width: "148px"
  },
  buttonstyles: {
    border: "1px solid  #010101",
    borderRadius: "2px",
    width: "50%"
  },
  textStyles: {
    fontFamily: "Silika-light",
    fontSize: "16px",
    fontWeight: 300,
    width: "60%",
    alignItems: "center",
    color: "#0D0C22"
  },
  containedButton: {
    border: "1px solid  #010101",
    borderRadius: "2px",
    backgroundColor: "#0D0C22",
    color: "white",
    width: "50%"
  },
  headerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonAlignment: {
    justifyContent: "space-around"

  },
  outerMostContainerSize: {
    marginBottom: '100px',
    position: 'relative' as 'relative',
    margin: "auto",
  },
  profileImgStyle: {
    width: "68px",
    height: "68px",
    fontSize: "30px",
  },
  collabImgStyle: {
    width: "100px",
    height: "100px",
    fontSize: "30px",
  },
  openCloseBtn: {
    position: 'absolute' as 'absolute',
    right: "62px",
    top: "18px",
  },
  openEyeIcon: {
    position: 'absolute' as 'absolute',
    right: "67px",
    top: "22px",
  },
  closeEyeIcon: {
    position: 'absolute' as 'absolute',
    right: "92px",
    top: "22px",
  },
  openEyeIcons: {
    position: 'absolute' as 'absolute',
    right: "67px",
    top: "5px",
  },
  closeEyeIcons: {
    position: 'absolute' as 'absolute',
    right: "92px",
    top: "5px"
  },
  collabAddBox: {
    display: "flex",
    justifyContent: "space-evenly"
  },
  addCollabIcon: {
    height: "32px",
    width: "32px",
    flexShrink: 0,
    cursor: "pointer",
    marginRight: "100px",
    marginTop: "20px"
  },
  deleteCollabIcon: {
    height: "20px",
    width: "20px",
    cursor: "pointer",
    color: "#9B9B9D"
  },
  modelHeadingstyleChatModel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
};


export const StyledEyeIcon= styled("div")(({theme}: {theme: Theme}) => ({
  height: "48px",
  width: "48px",
  background: "#fff",
  right: "128px",
  top: "56px",
  position: 'absolute' as 'absolute',
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "2px",
  [theme.breakpoints.down("sm")]:{
    right: "24px",
    top: "42px",
  },
  "& img":{
  height: "24px",
  width: "24px",
  [theme.breakpoints.down("sm")]:{
    right: "0"
  }
 }
}))
// Customizable Area End
