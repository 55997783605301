import React from "react";

// Customizable Area Start
import {
  createTheme,
  styled,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { orangeBanner } from "./assets";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import SubscriptionCards from "../../../components/src/CommonSubscription.web";
// Customizable Area End

import LandingPage3Controller, { Props } from "./LandingPage3Controller";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const StyledContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  "& *": {
    fontFamily: "Silika-Medium",
  },
  "& .topTitle": {
    fontSize: "64px",
    fontWeight: "500",
    lineHeight: "70.4px",
    color: "#0D0C22",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
      lineHeight: "39.6px",
      marginBottom: "16px",
    },
  },
  "& .tagLine": {
    fontSize: "16px",
    fontWeight: "300",
    lineHeight: "25px",
    color: "gray",
    marginTop: "20px",
    fontFamily: "Silika-Light",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  "& .paymentContainer": {
    backgroundColor: "#F5F3F3",
    justifyContent: "center",
    width: "100%",
  },
  "& .paymentTitle": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "116px",
    paddingLeft: "64px",
    paddingRight: "64px",
    "@media(max-width:790px)": {
      paddingLeft: "25px",
      paddingRight: "25px",
      paddingTop: "71px",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  "& .startProject": {
    backgroundImage: `url(${orangeBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "146px",
    "@media(max-width:1168px)": {
      paddingBottom: "73px",
    },
    "@media(max-width:550px)": {
      padding: "36px 46px",
    },
  },
  "& .startProjectTitle": {
    fontWeight: "500",
    fontSize: "72px",
    marginTop: "146px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      fontSize: "36px",
    },
  },
  "& .startBtnProject": {
    backgroundColor: "#9B9B9D",
  },
  "& .getStartedBtn": {
    width: "125px",
    height: "48px",
    borderRadius: "2px",
    color: "white",
    textTransform: "none",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
    },
  },
  "& .myBrandsContainer": {
    paddingRight: "133px",
    paddingLeft: "155px",
    paddingBottom: "141px",
    backgroundColor: "white",
    "& *": {
      fontFamily: "Silika-Medium",
    },
    "@media(max-width:686px)": {
      paddingLeft: "25px",
      paddingRight: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "72px",
    },
  },
  "& .cardDiv": {
    width: "25%",
    padding: "10",
    "@media(max-width:1184px)": {
      width: "40%",
    },
    "@media(max-width:950px)": {
      width: "50%",
    },
    "@media(max-width:560px)": {
      width: "90%",
    },
    "@media(max-width:390px)": {
      width: "100%",
    },
  },
}));

const StyledAccordian = styled("div")(({ theme }: { theme: Theme }) => ({
  flexDirection: "column",
  paddingTop: "61px",
  [theme.breakpoints.down("sm")]: {
    paddingTop: "72px",
  },
  display: "flex",
  "@media(max-width:1200px)": {
    paddingRight: "0px",
    paddingTop: "30px",
  },
  "@media(max-width:390px)": {
    paddingTop: "0px",
  },
  "& .accordianMainDiv": {
    "@media(max-width:560px)": {
      marginTop: "0px !important",
    },
  },
  justifyContent: "center",
  width: "100%",
  "& .accodianTitle": {
    fontSize: "48px",
    fontWeight: "500",
    color: "#0D0C22",
    lineHeight: "52.8px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
      lineHeight: "39.6px",
    },
  },
  "& .MuiAccordion-root": {
    width: "100%",
    borderRadius: "0px",
    boxShadow: "none",
  },
  "& .MuiIconButton-label": {
    fontSize: "20px",
    color: "#000000",
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "44px",
  },
  "& .accordianDetails": {
    backgroundColor: "white",
    paddingLeft: "64px",
    paddingRight: "64px",
    paddingTop: "60px",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  "& .titleBrands": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width:1496px)": {
      marginTop: "0px",
    },
    "@media(max-width:1334px)": {
      marginTop: "20px",
    },
  },
  "& .paraBrands": {
    fontWeight: "300",
    fontSize: "16px",
    color: "#0D0C22",
    opacity: "0.8",
    fontFamily: "Silika-Light",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  "& .brandQuoteDiv": {
    backgroundColor: "#66A2EB",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "48px",
    paddingLeft: "64px",
    paddingRight: "64px",
    paddingTop: "204px",
    paddingBottom: "204px",
    marginTop: "134px",
    width: "100%",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      marginTop: "72px",
      fontSize: "24px",
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "72px",
      paddingBottom: "72px",
    },
  },
  "& .authorQuote": {
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "24px",
    marginTop: "51px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      marginTop: "24px",
    },
  },
  "& .backTop": {
    textTransform: "none",
    backgroundColor: "white",
    width: "171px",
    height: "44px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "78px",
    fontWeight: "500",
    fontSize: "16px",
    color: "#010101",
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
    },
  },
  "& .contentOfContainer": {
    justifyContent: "space-between",
    paddingLeft: "64px",
    paddingRight: "64px",
    marginTop: "0",
    display: "flex",
    flexWrap: "wrap",
    "@media(max-width:1650px)": {
      paddingLeft: "0px",
      paddingRight: "41px",
      margin: "auto",
    },
  },
  "& .titleDivAcc": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "0px",
    marginBottom: "auto",
    fontSize: "48px",
    "@media(max-width:1604px)": {
      //marginTop:"auto !important",
    },
  },
}));

const StyledSubscriptionContainer = styled("div")(
  ({ theme }: { theme: Theme }) => ({
    padding: "49px 64px 0 64px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
    "& .subscriptionCardsList": {
      display: "flex",
      justifyContent: "center",
    },
  })
);
// Customizable Area End
export default class LandingPage3Web extends LandingPage3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <LandingHeader navigation={this.props.navigation}/>
                <StyledContainer>
                    <Box className="paymentContainer">
                        <Grid container className="paymentContainer">
                            <Grid item xl={12} className="paymentTitle">
                                <Typography className="topTitle">{this.state.landingPageData[14]?.attributes.title}</Typography>
                                <Typography className="tagLine">{this.state.landingPageData[14]?.attributes.description} </Typography>
                            </Grid>
                        </Grid>
                        <StyledSubscriptionContainer>
                        <SubscriptionCards 
                            onClickBackToTop={this.handleScrollUp}
                            onClickFeaturesOpen={this.handleFeaturesOpen}
                            subscriptionFeatureCategories={this.state.subscriptionFeatureCategories}
                            subscriptionFeatures={this.state.subscriptionFeatures}
                            subscriptionPlans={this.state.subscriptionPlans}
                            windowWidth={this.state.windowWidth}
                            viewAllFeaturesOpen={this.state.viewAllFeaturesOpen}
                            isHeaderNotNeeded={true}
                        />
                        </StyledSubscriptionContainer>
                        <Box className="myBrandsContainer">
                        <StyledAccordian style={{}}>                
                            <Grid container className="contentOfContainer">
                                <Grid item xl={6} className="titleDivAcc" style={{maxWidth:"459px"}}>
                                    <Typography className="accodianTitle">{this.state.landingPageData[15]?.attributes.title}</Typography>
                                </Grid>
                                <Grid item xl={6} className="titleBrands" style={{maxWidth:"552px"}}>
                                    <Typography className="paraBrands">
                                    {this.state.landingPageData[15]?.attributes.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container className="brandQuoteDiv">
                                <Grid item xl={12}>
                                 {this.state.landingPageData[16]?.attributes.description}
                                    <Box className="authorQuote"> {this.state.landingPageData[16]?.attributes.title}</Box>
                                </Grid>
                            </Grid>
                        </StyledAccordian>
                        </Box>
                       <Box className="startProject">
                            <Typography className="startProjectTitle">{this.state.landingPageData[17]?.attributes.title}</Typography>
                            <Typography className="tagLine" style={{maxWidth:"582px"}}>{this.state.landingPageData[17]?.attributes.description}
                            </Typography>
                            <Button className="getStartedBtn startBtnProject">Get Started</Button>
                       </Box>
                    </Box>
                </StyledContainer>
                <LandingFooter navigation={this.props.navigation}/>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
// Customizable Area End
