import React from "react";

// Customizable Area Start
import { Box, 
         Button,
         Switch, 
         Typography, 
         Theme, 
         styled, TextField } from "@material-ui/core";
import { createTheme, 
          ThemeProvider, 
          withStyles } from "@material-ui/core/styles";
import { forgewebLogo, animationVideo } from "./assets"
import { ToastContainer } from "react-toastify";

const StyledMainDiv = styled("div")(({theme}: {theme: Theme}) => ({
  display: "flex",
  height: "100vh",
  width: "100vw"
}));

const StyledRightDiv = styled("div")(({theme}: {theme: Theme}) => ({
  flex: "2 1 0%",
  height : "100vh",
  overflowY : "auto",
  [theme.breakpoints.down("sm")]:{
    flex: "1 1 100%",
  }
}));

const StyledLeftAnimation = styled("div")(({theme}: {theme: Theme}) => ({
  flex: "1 1 0%",
  overflow: "hidden",
  position: "relative",
  [theme.breakpoints.down("sm")]:{
    display: "none"
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: '300px',
  [theme.breakpoints.up('sm')]: {
    width: '327px',
  },
  [theme.breakpoints.up('md')]: {
    width: '360px',
  },
}));

export const StyledLogo = styled(Box)(({ theme }) => ({
  width: '18.55px',
  height : "24px",
  [theme.breakpoints.up('sm')]: {
   width: '34px',
    height : "44px"
  },
  [theme.breakpoints.up('md')]: {
    width: '34px',
    height : "44px"
  }
}));

export const StyledButtonFloat = styled(Button)(({ theme }) => ({
  width: '100%',
  textTransform: 'capitalize', alignSelf: "flex-end",
  marginTop: "auto", marginBottom: "50px",

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    marginTop: "auto",
  },
  [theme.breakpoints.up('md')]: {
    width: '129px', height: '48px', float: 'right',
    marginTop: 0, marginBottom: 0,
  },
}));

export const StyledBackButtonFloat = styled(Button)(({ theme }) => ({
  width: '300px',
  textTransform: 'capitalize', alignSelf: "flex-end",
  marginTop: "auto", marginBottom: "50px",
  display : "none",
  backgroundColor : "none",
  [theme.breakpoints.up('sm')]: {
    display : 'none'
  },
  [theme.breakpoints.up('md')]: {
    display : "block",
    width: '129px', height: '48px', float: 'right',
    marginTop: 0, marginBottom: 0,
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign : "left",
  [theme.breakpoints.up('sm')]: {
    textAlign: 'center', 
  }
}));

export const StyledTypography2 = styled(Typography)(({ theme }) => ({
  fontFamily:"Silika-Medium",
  fontSize: "15px",
  fontWeight : 500,
  lineHight : "24px", 
  color: '#0F172A',
  marginBottom : "200px",
  [theme.breakpoints.down('sm')]: {
    marginBottom : "50px",
  }
}));


export const StyledBackButton = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.up('md')]: {
    display : "none" 
  }
}));

const StyledLeftDiv = styled(Box)(({ theme }) => ({
  height  :"calc(100vh - 82px)",
  display: "flex", 
  padding: "0px 24px",
  justifyContent: "center",
  alignItems:"center",
  overflowY : "auto",
  position : "relative",
  [theme.breakpoints.down('sm')]: {
    display: "flex", 
    height  :"calc(100vh - 52px)",
    alignItems : "flex-start"
  }
}));

export const IOSSwitch = withStyles((theme) => ({
  root: {
    padding: 0,
    width: 48,
    height: 28,
  },
  switchBase: {
    padding: 1,
    top: 1,
    left: 1,
    '&$checked': {
      // transform: 'translateX(17px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        border: 'none',
        backgroundColor: "black ",
      },
    },
    "& .MuiSwitch-colorSecondary-50.Mui-checked + .MuiSwitch-track-54": {
      backgroundColor: '#00FFA7 !important'
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #F1F5F9',
    },
    '& #swith': {
      marginTop: "1px",
    },
    "& .MuiSwitch-root-45": {
      marginRight: '16px !important'
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 29 / 2,
    border: `0px solid ${theme.palette.grey[400]}`,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    backgroundColor: '#E2E8F0',
  },
  checked: {},
  focusVisible: {},
}))(Switch);

export const StyledTextField = styled((props) => (
  <TextField
    {...props}
    InputProps={{
      ...props.InputProps,
      style: {
        borderRadius: '2px',
        fontFamily : "Silika-Medium",
        fontWeight : 500,
        fontSize  :"16px",
        lineHeight  :"19.3px",
        letterSpacing  :"-0.15px",
        ...(props.InputProps?.style || {}),
      },
    }}
    FormHelperTextProps={{
      style: {
        color: '#FF5959',
      },
    }}
  />
))({
  
});

const LogoContainer = styled(Box)(({ theme }) => ({
  padding : "35px 0 0 35px",
  [theme.breakpoints.down('md')]: {
    padding : "12px 0 0 12px",
  }
}));

export const BorderOr = styled(Typography)(({ theme }) => ({
  borderBottom: "1px solid #DFDFDF",
  width: "165px",
  [theme.breakpoints.down('sm')]: {
    width  :"120px",
  }
}));




// Customizable Area End

import AccountLoginController, { Props } from "./AccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class LoginSignUp extends AccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    const { children } = this.props;
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <StyledMainDiv>
          <StyledRightDiv>
            <LogoContainer >
              <StyledLogo>
              <img data-test-id="navigationMain" onClick={(e) => {
                     this.handleNavigateMainPage()
                    }} src={forgewebLogo} alt="logo" style={webStyle.logoImg} />
              </StyledLogo>
             
            </LogoContainer>
            <Box
              display={{ xs: "block", sm: "none" }}
              style={webStyle.borderBottom}
            >
            </Box>
            <StyledLeftDiv>{children}</StyledLeftDiv>
          </StyledRightDiv>
          <StyledLeftAnimation>
            <video
              autoPlay
              muted
              loop
              data-test-id="videoAnimation"
              disablePictureInPicture
              style={{ objectFit: 'cover', width: "100%", height: "100%", position : "absolute", top : "0", left : 0}}
              src={animationVideo}
            />
          </StyledLeftAnimation>
          <ToastContainer />
        </StyledMainDiv>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  loginOuterMostContainer: {
    display: "flex",
    height: "100vh"
  },
  leftDiv: {
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
    height: "100%",
    overFlowY : "auto"
  },
  borderBottom: {
    borderBottom: "1px solid #DFDFDF",
    // marginBottom : "35px"
  },
  logoImg: {
    height: "100%",
    width: "100%",
    flexShrink: 0,
    cover: "no-repeat",
    cursor : 'pointer'
  },
  logInLeft: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent : "center"
  },
  leftContainer: {
    margin: "auto",
    flexShrink: 0,
  },
  formTitleContainer: {
    marginBottom: "45px",

  },
  formTitleText: {
    color: "#000",
    fontFamily: "Silika-Medium",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
    marginTop : "50px"
  },
  inputfieldHeight: {
    height: "56px",
    width: '100%',
    marginBottom: "16px",
  },
  inputfieldHeightWithError: {
    height: "56px",
    border: "1px solid #DC2626",
    borderRadius: "2px",
  },
  passwordInputPosition: {
    position: "relative" as "relative",
  },
  isForgotPasswordOuterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "16px"
  },
  forgotPassword: {
    fontFamily: "Silika-Light",
    fontSize: "12px",
    fontWeight: 200,
    lineHeight: "22px",
    color: "#000000",
    textDecoration: "none",
  },
  isForgotPassword: {
    color: "#9B9B9D",
    fontFamily: "Silika-Light",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "22px",
    cursor: "pointer",
    marginBottom  :"48px"
  },
  buttonMargin: {
    marginTop: "50px",
  },
  orDiv: {
    display: "flex",
    alignItems: "center",
  },
  or: {
    marginLeft: "8px",
    marginRight: "8px",
    color: "#9B9B9D",
    fontFamily: "Silika-Light",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
  borderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent:  "center",
    marginTop: "25px",
    marginBottom: "18px",
  },
  logInButton: {
    height: "56px",
    borderRadius: "2px",
    color: "#FFF",
    backgroundColor: "black",
    fontFamily: "Silika-Medium",
    fontSize: '16px',
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    cursor: "pointer"
  },
  disabledButton: {
    backgroundColor: "#555",
    cursor: "not-allowed",
    opacity: 0.5,
  },
  isAccountDiv: {
    display: "flex",
    justifyContent: "center",
  },
  isAccountStatement: {
    color: "#9B9B9D",
    fontFamily: "Silika-Light",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    marginBottom  :"16px"
  },
  isTermsAndConditionStatement : {
    color :  "#0F172A",
    fontFamily: "Silika-Light",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "22px",
    marginBottom  :"16px"
  },
  forgePurposeStatement: {
    color: "#0F172A",
    fontFamily: "Silika-Light",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "24px",
    textAlign : "center" as "center",
    marginBottom : "48px",
    paddingTop : "18px",
  },
  signUplink: {
    color: "#000",
    fontFamily: "Silika-Light",
    cursor: "pointer",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    marginLeft: "8px",
  },
  errorMessage: {
    color: "#DC2626",
    fontFamily: "Silka",
    fontSize: "12px",
    fonStyle: "normal",
    fontWeight: 200,
    lineHeight: "normal",
    letterSpacing: "0.24px",
  },
  passwordVisibilityOffIcon: {
    position: "absolute" as "absolute",
    right: "20px",
    top: "16px",
    width: "24px",
    height: "24px",
    flexShrink: 0,
    color: "#9B9B9D",
    cursor: "pointer",
  },
  passwordVisibilityOnIcon: {
    position: "absolute" as "absolute",
    right: "20px",
    top: "16px",
    width: "24px",
    height: "24px",
    flexShrink: 0,
    color: "#000",
    cursor: "pointer",
  },
  logInRight: {
    width: "40%",
    height: "100vh",
    overflow : "hidden",
    position: 'sticky' as 'sticky', 
    top: 0, 
  },
  rightImage: {
    height: "100%",
    width: "100%",
    objectfit : 'cover'
  },
  membershipTitle : {
    fontFamily : "Silika-Medium",
    fontWeight  : 600,
    fontSize  :"24px",
    lineHeight  :"32px",
    letterSpacing : "-0.12px",
    marginBottom  :"36px"
  },
  membershipPlanTitle : {
    fontFamily : "Silika-Medium",
    fontWeight  : 500,
    fontSize  :"24px",
    lineHeight  :"43.68px",
    letterSpacing : "-0.56px",
  },
  selectedMemberShipBlock : {
    width : "100%",
    padding : "26px 0 26px 0",
    marginTop : "26px",
    borderTop : "1px solid #DFDFDF",
    borderBottom : "1px solid #DFDFDF",
    display  :"flex",
    justifyContent : "space-between",
    flexWrap  :"wrap" as "wrap"
  },
  selectedPlan : {
    fontFamily : "Silika-Medium",
    fontWeight  : 500,
    fontSize  :"24px",
    lineHeight  :"28.96px",
    letterSpacing : "-0.56px",
  },
  selectedPlanFeature : {
    fontFamily : "Silika-Light",
    fontWeight  : 300,
    fontSize  :"16px",
    lineHeight  :"19.29px",
    color : "#242121",
    margin : "10px 0 10px 0"
  },
  billingBlock : {
    display : "flex",
    padding : "26px 0 26px 0",
    borderBottom  :"1px solid #DFDFDF",
    justifyContent : "space-between",
    flexWrap  :"wrap" as "wrap"
  },
  subTitle : {
    fontFamily : "Silika-Light",
    fontWeight  : 400,
    fontSize  :"14px",
    lineHeight  :"16.8px",
    color : "#242121",
  },
  billingRight : {
    display  :"flex",
    gap : "20px"
  },
  labelStyle : {
    fontFamily : "Silika-Medium",
    fontWeight  : 500,
    fontSize  :"16px",
    lineHeight  :"19.3px",
    marginBottom : "5px",
    display : "block"
  },
  backAndNextbtnDiv : {
    display: "flex",
    gap: "16px",
    justifyContent: "flex-end",
    height: "auto",
    marginTop: "138px",
    marginBottom: "10px",
  },
  invitationText : {
    fontFanily : "Silika-Medium",
    fontWeight : 400,
    fontSize: "16px",
    lineHeight : "24px",
    marginBottom  :"38px"
  },
  textInputs : {
    marginBottom  :"16px"
  },
};

// Customizable Area End
