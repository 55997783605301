import React from "react";
// Customizable Area Start
import {
    Typography,
    Dialog,
    DialogContent,
    useMediaQuery,
    Box,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CloseIcon from '@mui/icons-material/Close';
type Props = {
    open: boolean,
    title: string,
    description: string,
    handleClosePopUp: () => void;
}
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
    height: "100vh",
    width: "100vw",
    "& .backButtonTxt": {
        fontFamily: "Silika-Medium",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "40px",
    },
    "& .crossIcon": {
        display: "block",
        height: "20px",
        width: "20px",
        position: "absolute",
        top: "30px",
        right: "35px"
    },
    "& .parent": {
        height: "469px",
        maxWidth: "540px",
        overflow: "hidden",
        position: "relative",
    },
    "& .body": {
        height: "100%",
        width: "100%",
        overflow: "hidden",
    },
    "& .title": {
        fontFamily: "Silika-Medium",
        fontWeight: 600,
        fontSize: "24px",
        marginTop: "65px",
        marginBottom: "24px"
    },
    "& .scrollDiv": {
        height: "calc(100% - 140px)",
        maxWidth: "100%",
        overflowY: "auto",
        paddingRight: "20px",
        paddingBottom: "10px",
        fontFamily: "Silika-Light"
    },
    "& .backBtnDiv": {
        display: "none",
    },
    [theme.breakpoints.down(650)]: {
        width: '100%',
        height : "100vh",
        overflowY : "hidden",
        "& .parent": {
            height: '100vh',
            overflow : "auto",
            maxWidth: "100vw",
        },
        "& .body": {
            height: "auto",
        },
        "& .crossIcon": {
            display: "none",
        },
        "& .backBtnDiv": {
            display: "flex",
            alignItems : "center"
        },
        "& .scrollDiv": {
            height : "auto"
        },
        "& .title": {
            fontWeight: 500,
            fontSize: "24px",
            marginTop: "24px",
            marginBottom: "24px"
        },
    }
}));

function Modal(props: Props) {
    const {
        open,
        title,
        description,
        handleClosePopUp
    } = props;
    const isSmallScreen = useMediaQuery('(max-width:650px)');
    return (
        <StyledDialog
            open={open}
            aria-describedby="alert-dialog-slide-description"
            fullScreen={isSmallScreen}
        >
            <DialogContent className="parent">
                <Box>
                    <div className="backBtnDiv">
                        <ChevronLeftIcon onClick={handleClosePopUp} style={{ cursor: "pointer" }} />
                        <Typography className="backButtonTxt">
                            Back
                        </Typography>
                    </div>
                    <CloseIcon data-test-id="closeIcon" onClick={handleClosePopUp}
                        className="crossIcon" />
                    <div className="body">
                        <Typography className="title">
                            {title}
                        </Typography>
                        <div className="scrollDiv"
                            dangerouslySetInnerHTML={{ __html: description }}
                        >
                        </div>
                    </div>
                </Box>
            </DialogContent>
        </StyledDialog>
    );
}

export default Modal
