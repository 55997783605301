import React from "react";
import { styled, Grid, Theme, Typography } from "@material-ui/core";
import NoFavourites from "./NoFavourites";
import { FavouriteProject, FavouritesProjectsProps } from "../types";

const StyledProjectsContainer = styled(Grid)(({theme}: {theme : Theme}) => ({
    marginTop: "95px",
    display: "inline-flex",
    flexWrap: "wrap",
    [theme.breakpoints.down('sm')]: {
        marginTop: "24px",
    },
    "& .project":{
        display: "flex",
        position: "relative",
        width: "302px",
        marginRight: "12px",
        marginBottom: "12px",
        [theme.breakpoints.down('sm')]: {
            flexFlow: "column",
            padding: "0px !important",
            margin: "0px 20px 24px 20px !important",
            width: "100%",
            "& .projectName":{
                fontSize: "16px",
                marginTop: "8px",
                marginBottom: "4px"
            },
            "& .projectOwner":{
                fontSize: "14px",
                fontWeight: 400
            }
        },
        "& img":{
            width: "100%",
            height: "228px",
            border: "1px solid black",
            borderRadius: "4px",
            [theme.breakpoints.down('sm')]: {
                height: "250px"
            }
        }
    },
    "& .hoverOverlay":{
        position: "absolute",
        left: 0,
        width: "100%",
        height: "230px",
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0,
        fontFamily: "Silika-Medium",
        color: "#010101"
    },
    "& .project:hover .hoverOverlay":{
        opacity: 1,
        backgroundColor: "rgba(217, 217, 217, 0.36)",
        transition: "opacity 0.3s ease",
        filter:"grayscale(0.3)"
    }
}));

class FavouriteProjectsList extends React.Component<FavouritesProjectsProps>{
    constructor(props: FavouritesProjectsProps){
        super(props);
    }

    render(){
        const {favouritesProjects, windowWidth, onClickNavigateToPostDetails} = this.props;
        const isMobile = windowWidth && windowWidth <= 599;
        if(favouritesProjects.length > 0){
            return (<StyledProjectsContainer data-test-id="favouriteProject" >
                {favouritesProjects.map((favProject: FavouriteProject) => <div className="project" onClick={() => onClickNavigateToPostDetails(favProject.favouritableId)}>
                <img src={favProject.image} alt={favProject.title} />
                <div className={isMobile ? "": "hoverOverlay"}>
                <Typography className="projectName">{favProject.title}</Typography>
                <Typography className="projectOwner">{favProject.postCreator}</Typography>
                </div>
            </div>)}
            </StyledProjectsContainer>)
        }else{
            return <NoFavourites data-test-id="no-fav-projects" title="You don’t have favourites projects yet"/>
        }
    }
}

export default FavouriteProjectsList;