import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../../packages/framework/src/Utilities";
export interface OptionType {
  firstName: string;
  lastName: string;
  photo: string;
};
export interface PostData  {
  id: string;
  project_title: string;
  description: string;
  images: Image[];
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  isEdited:boolean;
  profileImage: any;
  onClickClose?: () => void;
  // Customizable Area End
}

// Customizable Area Start
export interface PopUpSaved {
  projectTitle: string;
  description: string;
  tags: { id: number; label: string; }[];
  collabarators: { id: string, label: string, profileImg: string | null }[];
  confidential: boolean;
  images: Image[];
  hides_images? : number[];
}


 export type Post = {
  id: string;
  type: string;
  attributes: Attributes;
};

type Attributes = {
  id: number;
  project_title: string;
  description: string;
  location: string | null;
  account_id: number;
  confidential: boolean;
  tag_list: any[]; // Adjust type as necessary
  collaborator_ids: string[];
  created_at: string;
  updated_at: string;
  model_name: string;
  profile: {"data" : Profile}; // The profile object with its own `id`
  collaborators: Collaborator[];
  images: Image[];
  like_counts: number;
};

type Profile = {
  id: string;
  type: string;
  attributes: {
      id: number;
      country: string;
      county: string;
      city: string;
      postal_code: string | null;
      account_id: number;
      first_name: string;
      last_name: string;
      bio: string | null;
      skill: string | null;
      phone_number: string;
      profession: string[] | null;
      headline: string | null;
      company_name: string;
      company_number: string;
      registered_address: string;
      vat_number: string;
      availability: string;
      preferred_type_of_work: string | null;
      visibility_option: string;
      hourly_rate_option: string | null;
      online_option: string | null;
      dates: string | null;
      seniority_level: string | null;
      work_location: string | null;
      work: string[];
      hourly_rate: number | null;
      company_trading_name?: string | null;
      collaborators: any[]; // Adjust type as necessary
      tags: any[]; // Adjust type as necessary
      description: string | null;
      photo: string;
      media: any; // Adjust type as necessary
      unavailable_dates: string;
      hourly_rate_from: number | null;
      hourly_rate_to: number | null;
      email: string;
      role?: string | null;
  };
};

type Collaborator = {
  id: number;
  email: string;
  profile: {
      first_name: string;
      last_name: string;
      description: string | null;
      photo: string;
  },
  error?: {}
};

type Image = {
  id: number;
  url: string;
  is_visible: boolean;
};

type AllLikes ={
    id: string,
    type: string,
    attributes: {
        likeable_id: number,
        likeable_type: string,
        like_by_id: number,
        created_at: string,
        updated_at: string
    }
}

type AllPostLikes ={
  id: string,
  type: string,
  attributes: {
      id: number,
      account_id: number,
      post_id: number,
      created_at: string,
      updated_at: string
  }
}

type AllSavedProfile = {
    id: string,
    type: string,
    attributes: {
        favouriteable_id: number,
  }
}


// Customizable Area End


export interface S {
  // Customizable Area Start
  optionModal: boolean,
  editId : string,
  editPostPopUp : boolean,
  isDropdownOpen: boolean,
  isOpenArrow: boolean,
  isDrawerOpen: boolean,
  italicTitle:boolean,
  boldITitle:boolean,
  italicdescription:boolean,
  bolddescription:boolean,
  deleteID: number,
  deleteCollabPopUp: boolean,
  deletePostPopUp : boolean,
  deleteModal: boolean;
  refresh: boolean,
  tags: { id: number, label: string }[],
  image: string[],
  collabImgHovered:(boolean)[];
  savedValues: {
    projectTitle: string;
    description: string;
    tags: { id: number; label: string; }[];
    collabarators: { id: string, label: string, profileImg: string | null }[];
    confidential: boolean;
    images: Image[];
    hides_images? : number[];
  }
  addPost: {
    projectTitle: string;
    description: string;
    tags: { id: number; label: string; }[];
    collabarators: { id: string, label: string, profileImg: string | null }[]
    confidential: boolean;
    images: Image[]
  },

  uploadMultipleImages: string[]
  uploadMultipleImagesAPI: File[]
  displayDialoug: boolean
  displayPreview : boolean
  getProfile: {
    id: string;
    type: string;
    attributes: {
      id: number;
      country: string;
      county: string;
      city: string;
      postal_code: string;
      account_id: number;
      first_name: string;
      last_name: string;
      bio: string;
      skill: string[];
      phone_number: string;
      profession: string[];
      headline: string;
      company_name: string;
      company_number: string;
      registered_address: string;
      vat_number: string;
      availability: string;
      thumbs_up_count: number;
      visibility_option: string;
      dates: string[];
      seniority_level: string;
      work_location: string;
      work: any[];
      hourly_rate: string;
      collaborators: string[];
      tags: string[];
      description: string;
      photo: string;
      media: {
        google: string;
        twitter: string;
        youtube: string;
        linkedin: string;
        instagram: string;
      };
      unavailable_dates: string[];
    };
  }
  anchorEl: any;
  editPost: boolean;
  imageSize: boolean;
  getTagsList: { id: string, label: string, profileImg: string | null }[]
  addCollabator: boolean;
  addCollabInnitialValue: {
    selectedCollabarator: string[]
    emailCollab: string[]
  }
  listOfCollabarators: {
    id: number;
    email: string;
    profile: {
      first_name: string;
      last_name: string;
      description: string;
      photo: string;
    };
  }[]
  deletePost:boolean,
  deletePostId:string | number
  postsListData:Post[],
  postData:Post,
  selectedID:string,
  isPostLiked : boolean,
  allLikes : AllLikes[],
  allSavedProfile: AllSavedProfile[],
  isOpenModelShare:boolean,
  isCopied:boolean|null,
  msgSend: boolean,
  linkShare:string,
  isSendLinkToChatModalOpen:boolean,
  shareData:OptionType[],
  sendChatMessage:string,
  getAllData:Profile[],
  AllPostLikes : AllPostLikes[],
  allSavedPost : AllSavedProfile[]
  getAllCreatorPost:Post[]
  hideImages: number[]
  // Customizable Area End
}

export interface ListCollab {
  id: number;
  email: string;
  profile: {
    first_name: string;
    last_name: string;
    description: string;
    photo: string;
  };
};
// Customizable Area Start
export interface ProjectData {
  projectTitle: string;
  description: string;
  tags: { id: number; label: string; }[];
  collabarators: { id: string, label: string, profileImg: string | null }[];
  confidential: boolean;
  images: Image[]; 
  hides_images? : number[];
}
// Customizable Area End
interface SS {
  id: any;
}


export default class PostCreationWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addpostApiCallId: string = "";
  getTagsApiTags: string = "";
  token: string | null = "";
  getProfileApiCallId: string = ""
  addCollab: string = "";
  getIndexList: string = ""
  getCollabApiId: string = ""
  listCollabarators: string = ""
  deleteCollabApiID: string = ""
  deleteApi: string = "";
  deletePostApiCallId: string = "";
  getPostsApiId: string = "";
  editpostApiCallId : string = "";
  likePostApiID : string = "";
  getLikesPostApiID : string = "";
  setVisibleApiCallId : string = "";
  id:number=0;
  getAllLikesAPICallID: string = "";
  likeProfileAPICallId: string = "";
  saveProfileAPICallId:string = "";
  getAllSavedProfileAPICallId: string = "";
  deleteSavedApiCallId:string = "";
  getAllDataProfileApiCallId:string = "";
  getAllCreatorPostApiCallId:string = "";
  getAllSavedPostAPICallId: string = "";
  deletePostSavedApiCallID:string="";
  creatorPostLike:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationScreenNameMessage)
    ];

    this.state = {
      optionModal: false,
      editId : "0",
      editPostPopUp : false,
      isDropdownOpen: false,
      isOpenArrow: false,
      isDrawerOpen: false,
      italicTitle:false,
      boldITitle:false,
      italicdescription:false,
      bolddescription:false,
      deleteID: 0,
      deleteCollabPopUp: false,
      deletePostPopUp : false,
      deleteModal : false,
      tags: [],
      image: [],
      refresh: false,
      collabImgHovered:[false],
      addPost: {
        projectTitle: "",
        description: "",
        tags: [],
        collabarators: [],
        confidential: false,
        images: []
      },
      savedValues: {
        projectTitle: "",
        description: "",
        tags: [],
        collabarators: [],
        confidential: false,
        images: [],
        hides_images : []
      },
      uploadMultipleImages: [],
      uploadMultipleImagesAPI: [],
      displayDialoug: true,
      displayPreview : false,
      getProfile: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          country: "",
          county: "",
          city: "",
          postal_code: "",
          account_id: 0,
          first_name: "",
          last_name: "",
          bio: "",
          skill: [],
          phone_number: "",
          profession: [],
          headline: "",
          company_name: "",
          company_number: "",
          registered_address: "",
          vat_number: "",
          availability: "",
          thumbs_up_count: 0,
          visibility_option: "",
          dates: [],
          seniority_level: "",
          work_location: "",
          work: [],
          hourly_rate: "",
          collaborators: [],
          tags: [],
          description: "",
          photo: "",
          media: {
            google: "",
            twitter: "",
            youtube: "",
            linkedin: "",
            instagram: "",
          },
          unavailable_dates: []
          
        },
      },
      anchorEl: null,
      editPost: false,
      imageSize: false,
      getTagsList: [],
      addCollabator: false,
      addCollabInnitialValue: {
        selectedCollabarator: [],
        emailCollab: []
      },
      listOfCollabarators: [],
      deletePost:false,
      deletePostId:"",
      postsListData:[],
      postData:{
        id: "",
        type: "",
        attributes: {
          id: 0,
          project_title: "",
          description: "",
          location: null,
          account_id: 0,
          confidential: false,
          tag_list: [],
          collaborator_ids: [],
          created_at: "",
          updated_at: "",
          model_name: "",
          like_counts : 0,
          profile: {"data": {
            id: "",
            type: "",
            attributes: {
              id: 0,
              country: "",
              county: "",
              city: "",
              postal_code: null,
              account_id: 0,
              first_name: "",
              last_name: "",
              bio: null,
              skill: null,
              phone_number: "",
              profession: [],
              headline: "",
              company_name: "",
              company_number: "",
              registered_address: "",
              vat_number: "",
              availability: "",
              preferred_type_of_work: null,
              visibility_option: "",
              hourly_rate_option: null,
              online_option: null,
              dates: null,
              seniority_level: "",
              work_location: null,
              work: [],
              hourly_rate: null,
              collaborators: [],
              tags: [],
              description: null,
              photo: "",
              media: undefined,
              unavailable_dates: "",
              hourly_rate_from: null,
              hourly_rate_to: null,
              email: ""
            }
          }
          },
          collaborators: [],
          images: []
        }
      },
      selectedID:"",
      isPostLiked : false,  
      allLikes :[],
      allSavedProfile:[],
      isOpenModelShare:false,
      isCopied:null,
      msgSend:false,
      linkShare:"",
      isSendLinkToChatModalOpen:false,
      shareData: [],
      sendChatMessage:"",
      getAllData: [], 
      getAllCreatorPost:[],
      AllPostLikes : [],
      allSavedPost : [],
      hideImages: []
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyDownDes = this.handleKeyDownDes.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    // Customizable Area Start
    let authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
    this.id = this.props.navigation.getParam('id');
    this.getAllTags()
    this.handleGetUserProfile()
    this.handleIndexList()
    this.getCollabaratorList()
    this.getAllPosts()
    this.getAllLikes()
    this.getAllSavedProfile()
    this.getPostLikes();
    this.getAllSavedPost();

    // Customizable Area End
  }
  


 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
      if (responseJson) {
        this.handleApiResponse(apiRequestCallId, responseJson);
      }
    }
  }

  private handleApiResponse(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.getTagsApiTags:
        this.handleTagsApiResponse(responseJson);
        break;
      case this.getProfileApiCallId:
        this.handleProfileApiResponse(responseJson);
        break;
      case this.getIndexList:
        this.handleIndexListApiResponse(responseJson);
        break;
      case this.listCollabarators:
        this.handleListCollaboratorsApiResponse(responseJson);
        break;
      case this.getCollabApiId:
        this.handleGetCollabApiResponse();
        break;
      case this.deleteCollabApiID:
        this.handleDeleteCollabApiResponse();
        break;
      case this.addpostApiCallId:
        this.handleAddPostApiResponse(responseJson);
        break;
      case this.deletePostApiCallId:
        this.handleDeletePostApiResponse();
        break;
      case this.editpostApiCallId:
        this.handleEditPostApiResponse();
        break;
      case this.getAllLikesAPICallID:
        this.handleGetAllLikesApiResponse(responseJson);
        break;
      case this.likeProfileAPICallId:
        this.handleLikeProfileApiResponse(responseJson);
        break;
      case this.saveProfileAPICallId:
        this.handleSaveProfileApiResponse(responseJson);
        break;
      case this.getAllSavedProfileAPICallId:
        this.handleGetAllSavedProfileApiResponse(responseJson);
        break;
      case this.deleteSavedApiCallId:
        this.handleDeleteSavedApiResponse();
        break;
      case this.getAllDataProfileApiCallId:
        this.handleGetAllDataProfileApiResponse(responseJson);
        break;
      case this.getAllCreatorPostApiCallId:
        this.handleCreatorPostApiResponse(responseJson);
        break;
      case this.getLikesPostApiID:
        this.handleGetPostLikeResponse(responseJson);
        break;
      case this.likePostApiID:
        this.handlePostApiLikeResponse(responseJson);
        break;
      case this.getAllSavedPostAPICallId:
        this.handleGetAllSavedPostApiResponse(responseJson);
        break;
      case this.deletePostSavedApiCallID:
        this.handleDeletePostSavedApiResponse();
        break;
      case this.setVisibleApiCallId:
        this.handleVisible(responseJson)
        break;
      case this.creatorPostLike:
        this.handleCreatorPostLikeResponse(responseJson);
        break;
      default:
        this.getPostList(apiRequestCallId, responseJson);
    }
  }
  
  private handleTagsApiResponse(responseJson: any) {
    const arrayData = responseJson.tags.map((item: any, index: number) => ({
      id: index,
      label: `${item}`
    }));
    this.setState({ tags: arrayData });
  }
  
  private handleProfileApiResponse(responseJson: any) {
    this.setState({ getProfile: responseJson.data });
  }
  
  private handleIndexListApiResponse(responseJson: any) {
    const getTagsList = responseJson.data.map((item: any) => ({
      id: item.attributes.account_id,
      label: `${item.attributes.first_name} ${item.attributes.last_name}`,
      profileImg: item.attributes.photo || item.attributes.first_name[0]
    }));
    this.setState({ getTagsList });
  }
  
  private handleListCollaboratorsApiResponse(responseJson: any) {
    const filteredCollaborators = responseJson.collaborators.filter((collaborator: any) => !collaborator.error);
    this.setState({ listOfCollabarators: filteredCollaborators });
  }
  
  private handleGetCollabApiResponse() {
    this.setState({ addCollabator: false });
    this.getCollabaratorList();
    this.getAllPosts();
  }
  
  private handleDeleteCollabApiResponse() {
    this.getCollabaratorList();
    this.setState({ deleteCollabPopUp: false });
    this.getAllPosts();
  }
  
  private handleAddPostApiResponse(responseJson: any) {
    this.setState({ displayDialoug: false });
    this.navigateTo('ListPost', responseJson.data.id);
  }
  
  private handleDeletePostApiResponse() {
    this.setState({ displayDialoug: false });
    this.props.navigation.navigate('ProfileInfo');
  }
  
  private handleEditPostApiResponse() {
    this.setState({ displayDialoug: false, displayPreview: false });
    this.getAllPosts()
  }
  
  private handleGetAllLikesApiResponse(responseJson: any) {
    if (!responseJson.errors && responseJson.data) {
      this.setState({ allLikes: responseJson.data });
    }
  }
  
  private handleLikeProfileApiResponse(responseJson: any) {
    if (!responseJson.errors && responseJson.message) {
      this.getAllLikes();
    }
  }
  
  handleSaveProfileApiResponse(responseJson: any) {
    if (!responseJson.errors && responseJson.data) {
      if(responseJson.data.attributes.favouriteable_type === "BxBlockProfile::Profile"){
        this.getAllSavedProfile();
      }else{
        this.getAllSavedPost();
      }
      
    }
  }
  
  private handleGetAllSavedProfileApiResponse(responseJson: any) {
    if (!responseJson.errors && responseJson.data) {
      this.setState({ allSavedProfile: responseJson.data });
    }
  }

  private handleGetAllSavedPostApiResponse(responseJson : any){
    this.setState({ allSavedPost: responseJson.data });
  }
  
  private handleDeleteSavedApiResponse() {
      this.getAllSavedProfile();
  }
  
  private handleGetAllDataProfileApiResponse(responseJson: any) {
    if (!responseJson.errors && responseJson.data) {
      this.setState({ getAllData: responseJson.data });
    }
  }

  private handleCreatorPostApiResponse(responseJson:any){
    if (!responseJson.errors && responseJson.data) {
      this.setState({ getAllCreatorPost: responseJson.data });
    }
  }

  private handleGetPostLikeResponse(responseJson : any){
    if (!responseJson.errors && responseJson.data) {
      const isPostLiked = responseJson.data.some(
        (like: any) =>
          like?.attributes?.post_id === undefined 
        ? "" 
        : like.attributes.post_id.toString() === this.id
      );
      this.setState({ AllPostLikes: responseJson.data, isPostLiked : isPostLiked });
    }
  }

  private handleCreatorPostLikeResponse(responseJson : any){
     if (!responseJson.errors) {
      this.getPostLikes();
    }
  }

  handlePostApiLikeResponse(responseJson  :any){
    if (!responseJson.errors && responseJson) {
      if(responseJson.data){
        this.setState((prevState) => ({
          isPostLiked: true,
          postData: {
            ...prevState.postData,
            attributes: {
              ...prevState.postData.attributes,
              like_counts: prevState.postData.attributes.like_counts + 1
            }
          }
        }));
      }else{
        this.setState((prevState) => ({
          isPostLiked: false,
          postData: {
            ...prevState.postData,
            attributes: {
              ...prevState.postData.attributes,
              like_counts: prevState.postData.attributes.like_counts - 1
            }
          }
        }));
      }
      
    }
  }

  private handleDeletePostSavedApiResponse(){
    this.getAllSavedPost();
  }
  private handleVisible(responseJson:any) {
    if (!responseJson.errors ) {
      this.getAllPosts();
    }
  }
  

  urlToFile(url: string, filename: string, mimeType: string) {
    return fetch(url)
        .then((response) => response.blob())
        .then((blob) => new File([blob], filename, { type: mimeType }));
}

// Function to convert API images to File objects using Promises
convertApiImagesToFiles(apiImages : Image[]) {
    return Promise.all(
        apiImages.map((image: Image, index: any) =>{
            const urlParams = new URLSearchParams(image.url?.split('?')[1]);
            const type = urlParams.get('type');
            const isImage = type && type.includes('image');
            const fileExtension = isImage ? 'jpeg' : 'mp4';
            const mimeType = isImage ? 'image/jpeg' : 'video/mp4';
            return this.urlToFile(image.url, `api_media_${index}.${fileExtension}`, mimeType);
        })
    );
}

  getPostList = (apiRequestCallId:string, responseJson:any) => {
    if(apiRequestCallId === this.getPostsApiId){
      if (responseJson && !responseJson.errors && responseJson.data) {
            this.setState({ postData: responseJson.data });
          }
    }
    this.getCreatorPost();
  }
  getTagArray = (responseJson: { tags: string[] }) => {
    let arrayData: { id: number, label: string }[] = []
    {
      responseJson.tags.forEach((item: string, index: number) => {
        arrayData.push({ id: index, label: `${item}` })
      })
      return arrayData
    }
  }
  getToken = () => {
    let formattedToken
    let sessionToken = localStorage.getItem("token")
    if (sessionToken) {
      formattedToken = sessionToken.replace(/^"(.*)"$/, '$1');
    }
    return formattedToken
  }

  getDataStorage = async (value: string) => {
    return await getStorageData(value);
  };
  
  handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'b') {
      event.preventDefault();
      this.setState({
        boldITitle: true,
        italicTitle : false
      });
    }
    if ((event.metaKey || event.ctrlKey) && event.key === 'i') {
        event.preventDefault();
        this.setState({
          italicTitle: true,
          boldITitle: false,
        });
      }
  }
  handleKeyDownDes(event: React.KeyboardEvent<HTMLDivElement>) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'b') {
      event.preventDefault();
      this.setState({
        bolddescription: true,
        italicdescription : false
      });
    }
    if ((event.metaKey || event.ctrlKey) && event.key === 'i') {
        event.preventDefault();
        this.setState({
          bolddescription: false,
          italicdescription: true,
        });
      }
  }
  handleOptionOpen = () => {
    this.setState({
      optionModal: !this.state.optionModal,
    });
  };
  handleOptionClose = () => {
    this.setState({
      optionModal: false,
    });
  };
  handleisDropdownOpen = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen,
      isOpenArrow: !this.state.isOpenArrow,
    });
  };


  getAllTags = () => {
    const header = {
      "token": this.getToken()
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTagsApiTags = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetTags
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getAllPosts = () => {
    const header = {
      "token": this.getToken(),
      "Access-Control-Allow-Origin" : true
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPostsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postDetailsEndPoint+"/"+this.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getData = (formData: any) => {
    this.state.uploadMultipleImagesAPI.forEach((item) => {
      formData.append("images[]", item)
    })


  }
  updatePost = (value: ProjectData, isEdited = false) => {
    const header = {
      "token": this.getToken()
    };

    
    const formData = new FormData();
    formData.append("project_title", value.projectTitle || "");
    formData.append("description", value.description || "");
    formData.append("confidential", this.state.addPost.confidential.toString());
    this.state.hideImages.forEach((index) => {
      formData.append("hides_images[]", index.toString());
    });

    this.getData(formData)
    let collabArray = value.collabarators.map((item: any) => {
      return (item.id || item.label)
    })
    let tagAray = value.tags.map((item: any) => {
      return item.label
    })
    formData.append("tag_list[]", tagAray.length > 0 ? tagAray.join(",") : "");
    formData.append("collaborator_ids[]", collabArray.length > 0 ? collabArray.join(",") : "");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    if(!isEdited){
      this.addpostApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PostAPiMethod
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ceatePost
    );
      
    }else{
      this.editpostApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchPostAPiMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updatePostEndPoint+this.id
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  setVisibleImage = (image_id: number, is_visible: boolean) => {
    const header = {
      "token": this.getToken()
    };

    
    const formData = new FormData();
    formData.append("image_id", image_id.toString());
    formData.append("is_visible", is_visible.toString());

   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    this.setVisibleApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchPostAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.visibleImageEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ imageSize: false });
    const files = event.target.files;
  
    if (files) {
      const fileArray = Array.from(files);
  
      fileArray.forEach(file => {
        const fileSizeMB = file.size / (1024 * 1024); 
        if (fileSizeMB <= 5 && (file.type.startsWith("image/") || file.type.startsWith("video/"))) {
          this.setState(prevState => ({
            uploadMultipleImages: [
              ...prevState.uploadMultipleImages,
              URL.createObjectURL(file),
            ],
          }));
          this.setState(prevState => ({
            uploadMultipleImagesAPI: [
              ...prevState.uploadMultipleImagesAPI,
              file,
            ],
          }));
        } else {
          this.setState({ imageSize: true });
        }
      });
    }
  };
  

  removeImage = (event:React.MouseEvent<HTMLImageElement, MouseEvent>,indexToRemove: number) => {
    const updatedArray = this.state.uploadMultipleImages.filter((item: string, index: number) => index !== indexToRemove);
    const updatedArrayAPI = this.state.uploadMultipleImagesAPI.filter((item: File, index: number) => index !== indexToRemove);
    this.setState({
      uploadMultipleImages: updatedArray,
      uploadMultipleImagesAPI: updatedArrayAPI
    })
  }
  handlePreviewOpen = () => {
    this.setState({ displayPreview: true})
  }
  handleDisplayClose = () => {
    this.setState({ displayDialoug: false });
    this.props.onClickClose?.();
  }

  handlePreviewClose = () => {
    this.setState({ displayPreview: false})
  }

  savePopUpData = (values: PopUpSaved) => {
    const updatedImages = this.state.uploadMultipleImagesAPI.map((file, index) => ({
      id: index + 1, // Assigning a unique id based on index
      url: URL.createObjectURL(file), // Generate the URL for the file
      is_visible: true, // Set default visibility to false
    }));
  
    this.setState({
      savedValues: {
        ...values,
        images: [...this.state.savedValues.images, ...updatedImages], // Add the new images to the existing ones
      },
    });
  }
  handleHoverCollab(index: number) {const hoveredImages = Array(this.state.collabImgHovered.length).fill(false); hoveredImages[index] = !hoveredImages[index];this.setState({ collabImgHovered: hoveredImages });
}
  addCollabarators = () => {

    const header = {
      "token": this.getToken()
    };

    const formData = new FormData();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addCollab = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addCollabarators}${41}&email=${this.state.savedValues.collabarators[0]}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  handleGetUserProfile = () => {
    const header = {
      "token": this.getToken()
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfileEndPoint}/34`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handlePopOverOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event?.currentTarget })
  }
  handleClosePopOver = () => {
    this.setState({ anchorEl: null })
  };
  handleEdit = () => {
    this.handleClosePopOver()
    this.setState({ editPost: !this.state.editPost })
  }
  handleIndexList = () => {

    const header = {
      "token": this.getToken()
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getIndexList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
 
  openDialougCollab = () => {
    this.setState({
      addCollabator:true
    })
  }
  closeDialougCollab = () => {
    this.setState({
      addCollabator:false
    })
  }
  handleAddCollab = (values:any) => {
    let accoundData = values.selectedCollabarator.map((item: { id: string, label: string }) => {
      return item.id
    })
    let emailData = values.emailCollab?.map((item: { id: string, label: string }) => {
      return item.label
    })
    const header = {
      "token": this.getToken()
    }
    const emailParam = emailData && emailData.length > 0 ? `${emailData.join(",")}` : '';
    const formData = new FormData();
    formData.append("post_id", this.id.toString());
    if(accoundData.length > 0){
      formData.append("account_id", accoundData);
    }
    if(emailParam.length > 0){
      formData.append("email", emailParam);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCollabApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addCollab}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  deletePost = (id:string) => {
    runEngine.debugLog("Message Recived", id);
  }
  getCollabaratorList = () => {
    const header = {
      "token": this.getToken()
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.listCollabarators = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listCollabs}${83}`
   
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleDelete = () => {
    this.setState({ deletePost:!this.state.deletePost, deleteModal : !this.state.deleteModal})
  }
  handleDeleteCollab = ()=>{this.setState({ deleteCollabPopUp: false})}
    
  handleDeletePost = () => {
    const header = {
      "token": this.getToken()
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deletePostListEndPoint+this.state.deletePostId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.Delete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  deleteCollabApi = (account_id: number) => {
    const header = {
      "token": this.getToken()
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCollabApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCollab}post_id=${this.id}&account_id=${account_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.Delete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  saveDeleteId = (id: number) => {
    this.setState({ deleteCollabPopUp: true })
  }
  handleDeletePostPopUp = (id : string) =>{
    this.setState({ deleteModal: true , deletePostId : id })
  }
  handleDeleteClose = () => {
    runEngine.debugLog("Message Recived", "deleteclose");
  }
  handleDeleteOpen = (id:string) => {
    this.setState({ deletePost: true, deletePostId : id })
  }
  handleEditOpen = (post : Post) => {
    let  selectedCollaborators: { id: string; label: string; profileImg: string | null; }[] = [];

    if (post.attributes.collaborator_ids) {
      const collaboratorIds = post.attributes.collaborator_ids;
    
      selectedCollaborators = collaboratorIds.map(idOrEmail => {
        const foundTag = this.state.getTagsList?.find(tag => tag.id.toString() === idOrEmail || tag.label === idOrEmail);
    
        if (foundTag) {
          return {
            id: foundTag.id.toString(),
            label: foundTag.label,
            profileImg: foundTag.profileImg,
          };
        } else {
          return {
            id: idOrEmail,
            label: idOrEmail, 
            profileImg: null,
          };
        }
      });
    }
    
  const apiImages = post.attributes.images;
  this.convertApiImagesToFiles(apiImages)
  .then((convertedApiImages) => {
      this.setState({
        uploadMultipleImagesAPI: [...convertedApiImages],
      });
  })
  .catch((error) => {
      console.error('Error converting API images to files:', error);
  });
  const selectedTags = this.state.tags.filter(tag =>
    post.attributes.tag_list.includes(tag.label.toString())
);
    this.setState({editPostPopUp: true, displayDialoug :true, editId : post.id,
     addPost : {
      projectTitle: post.attributes.project_title,
      description: post.attributes.description,
      tags: selectedTags,
      collabarators: selectedCollaborators,
      confidential: post.attributes.confidential,
      images: post.attributes.images
    }})
  }



  handleChangeToggle = () => {
    this.setState(prevState => ({
      addPost: {
        ...prevState.addPost,
        confidential : !prevState.addPost.confidential
      },
      postData : {
        ...prevState.postData,
        attributes : {
          ...prevState.postData.attributes,
          confidential : !prevState.postData.attributes.confidential
        }
        
      }
    }));
  };
  

  navigateTo = (to: string, id: string) => {
    this.props.navigation.navigate(to, { id });
 }
 handleClick = () => {
  if (this.state.optionModal) {
    this.setState({ optionModal: false });
  }
};

getAllLikes = () => {
  const headers = {
    token: localStorage.getItem("token")
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getAllLikesAPICallID = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getAllApiEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
}

profileLike = async (profileId:number) => {
  const headers = {
    token: await this.getDataStorage("token"),
    "Content-Type": "application/json" 
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const body = {
      data:{
          attributes: {
              likeable_id: profileId,
              likeable_type: "BxBlockProfile::Profile"
          }
      }
  };

  this.likeProfileAPICallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getAllApiEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.PostAPiMethod
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
}

handleAddFavouriteProfile = async (id:number, type:string) => {
  const favoriteEvent={
    data:{
      favouriteable_id: id,
      favouriteable_type: type
    }
  }   

  const header = {
    token: await this.getDataStorage("token"),
    "Content-Type":configJSON.validationApiContentType
  };

  const requestMessageFavEvs = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.saveProfileAPICallId = requestMessageFavEvs.messageId;

  requestMessageFavEvs.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.favouriteEventEndPont
  );
  requestMessageFavEvs.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessageFavEvs.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.PostAPiMethod
  );
  requestMessageFavEvs.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(favoriteEvent)
  );
  runEngine.sendMessage(requestMessageFavEvs.id, requestMessageFavEvs);
  return true;
}

getAllSavedProfile = async() => {  
  const header = {
    token: await this.getDataStorage("token"),
    "Content-Type":configJSON.validationApiContentType
  };

  const requestMessageFavEvs = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getAllSavedProfileAPICallId = requestMessageFavEvs.messageId;

  requestMessageFavEvs.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getAllSavedProfileEndPoint
  );
  requestMessageFavEvs.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessageFavEvs.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessageFavEvs.id, requestMessageFavEvs);
  return true;
}

handleDeleteFavProfile = async (id:number, type:string)=> {
  const isSavedProfile = this.state.allSavedProfile.find((allSavedProfile)=>allSavedProfile.attributes.favouriteable_id === id);
  const isSavedPost = this.state.allSavedPost.find((post)=>post.attributes.favouriteable_id === id);
  const deleteId = type==="profile"? isSavedProfile?.id : isSavedPost?.id;
    const headers = {
      token: await this.getDataStorage("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if(type==="profile"){
      this.deleteSavedApiCallId = requestMessage.messageId;
    }else{
      this.deletePostSavedApiCallID = requestMessage.messageId;
    }

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouriteEventEndPont}/${deleteId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.Delete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleClose =()=>{
  this.setState({
    isOpenModelShare: false,
    isCopied:null,
    msgSend:false,
   })
}

handleClickCopy = () => {
  this.setState({msgSend:false})
  navigator.clipboard.writeText(this.state.linkShare)
    .then(() => {
      this.setState({ isCopied: true });
    })
    .catch((error) => { this.setState({ isCopied: false });});
}

handleChat=()=>{
  this.setState({isSendLinkToChatModalOpen:true, msgSend:false, isCopied:null})
  this.handleGetAllUserProfile();
}

openPopUpShareModal =(id:number, page:string)=>{
  this.setState({
   isOpenModelShare: true,
   linkShare:window.location.href.split("/",3).join("/")+`/${page}/`+`${id}`
  })
 }

handleNavigateBack=()=>{
  this.setState({
    isSendLinkToChatModalOpen:false,
    isOpenModelShare: true
   })
}

handleCloseChatModel = () => {
  this.setState({isSendLinkToChatModalOpen: false,sendChatMessage:'',shareData:[]});
}

handleSend=()=>{
  this.setState({isSendLinkToChatModalOpen: false,sendChatMessage:'',shareData:[], msgSend: true}); 
}

handlesendChatMessage=(event:{target:{value:string}})=>{
  this.setState({ sendChatMessage: event.target.value });
}

handleshareDataChatChange = (_event:  React.ChangeEvent<{}>,value: OptionType[]) => {
  this.setState({ shareData: value });
};

handleGetAllUserProfile = async() => {
  const headers = {
    token: await this.getDataStorage("token"),
  };
  const requestMessager = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getAllDataProfileApiCallId = requestMessager.messageId;

  requestMessager.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getProfileEndPoint}`
  );

  requestMessager.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessager.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessager.id, requestMessager);

  return true;
};
getPostLikes = () => {
    const header = {
      'Content-Type': configJSON.exampleApiContentType,
      "token": this.getToken()
    };  
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLikesPostApiID = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.alllikePostsEndPoint
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

getCreatorPost = () =>{
  const header = {
    'Content-Type': configJSON.exampleApiContentType,
    "token": this.getToken()
  };  

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getAllCreatorPostApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.allCreatorPostEndPoint + `${this.state.postData.attributes.account_id}`
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;

}
likePost = async (type : string) => {
    const account = this.state.getProfile.attributes;
    const post = this.state.postData.attributes; 
      const header = {
        "token": this.getToken(),
        "Content-Type": "application/json" 
      };
    
      const body={
          data:{
              type: type,
              attributes: {
                  account_id: account.account_id,
                  post_id:post.id
              }
          }
      }
      
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.likePostApiID = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PostAPiMethod
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.likePostEndPoint
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    
  }
getAllSavedPost = async() => {  
    const header = {
      token: await this.getDataStorage("token"),
      "Content-Type":configJSON.validationApiContentType
    };

    const requestMessageFavEvs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllSavedPostAPICallId = requestMessageFavEvs.messageId;
  
    requestMessageFavEvs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSavedPostEndPoint
    );
    requestMessageFavEvs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageFavEvs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessageFavEvs.id, requestMessageFavEvs);
    return true;
  }

  likeCreatorPost = (type : string,postId:number) => {
    const account = this.state.getProfile.attributes;
      const header = {
        "token": this.getToken(),
        "Content-Type": "application/json" 
      };
    
      const body={
          data:{
              type: type,
              attributes: {
                  account_id: account.account_id,
                  post_id:postId
              }
          }
      }
      
      const requestMessager = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.creatorPostLike = requestMessager.messageId;
    
      requestMessager.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessager.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      requestMessager.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PostAPiMethod
      );
    
      requestMessager.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.likePostEndPoint
      );
    
      runEngine.sendMessage(requestMessager.id, requestMessager);
      return true;
    
  }

  handleSetVisiblePreview = (id: number, is_visible: boolean, index: number) => {
    this.setState((prevState) => {
      const updatedImages = prevState.savedValues.images.map((image) => {
        if (image.id === id) {
          return { ...image, is_visible: is_visible };
        }
        return image;
      });
  
      let updatedHideImages = [...prevState.hideImages];
      if (!is_visible) {
        if (!updatedHideImages.includes(index)) {
          updatedHideImages.push(index);
        }
      } else {
        updatedHideImages = updatedHideImages.filter((i) => i !== index);
      }
  
      return {
        savedValues: {
          ...prevState.savedValues,
          images: updatedImages,
        },
        hideImages: updatedHideImages,
      };
    });
  };

  navigatePostDetailTOPostDetail= (postId:string) =>{
    this.id=+postId;
    this.getAllPosts();
    this.navigateTo("ListPost",postId);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  });
  }
  navigateToWorkTab = () => {
    this.props.navigation.navigate('ProfileInfo', { activeTab: 'work' });
}
  // Customizable Area End
}
