import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message"
import { BlockComponent } from "framework/src/BlockComponent"
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import {
  City,
  Country,
  ICity,
  ICountry,
  IState,
  State
} from "country-state-city";
import moment from "moment";
import { IEducationRecord, IExperienceRecord } from "../../../components/src/interfaces.web";

// Customizable Area Start
import _ from "lodash";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}
interface Profile {
  getProfile: {
    attributes: ProfileAttributes
  };
  id: string;
  type: string;
  attributes: ProfileAttributes;
}

interface ProfileAttributes {
  id: number;
  country: string;
  county: string;
  city: string;
  postal_code: string;
  account_id: number;
  first_name: string;
  last_name: string;
  bio: string;
  skill: string[];
  phone_number: string;
  profession: string[];
  headline: string;
  company_name: string;
  company_number: string;
  registered_address: string;
  vat_number: string;
  availability: string | null;
  thumbs_up_count: number;
  visibility_option: string;
  dates: string[];
  seniority_level: string;
  work_location: string;
  work: string[];
  hourly_rate: string;
  collaborators: string[];
  tags: string[];
  description: string;
  photo: string;
  media: string | null;
  unavailable_dates: string;
  is_like: boolean;
}


export interface IAttributeValues {
  name: string;
  value: string;
}
export interface OpenDatePicker {
  from: boolean;
  to: boolean;
}
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

interface OptionType {
  firstName: string;
  lastName: string;
  photo: string;
};

export interface IProfileValues {
  value: string;
}

export interface UpdatedValues {
  FirstName: string;
  LastName: string;
  Profession: string[];
  Seniority: string;
  Headline: string;
  Country: CountryInfo;
  Region: CityInfo;
  Town: CityInfo;
  PreferredWork: string[];
  image: string;
  imageError: string;
}

interface EducationTypeData {
  institution: string
  qualification: string
  fieldOfStudy: string
  Country: CountryInfo
  startMonth: string
  startYear: string
  endMonth: string
  endYear: string
  description: string
  skills: string[]
}
export interface IProfileData {
  [key: string]: FieldValue;
}

interface CompanyList {
  id: string,
  type: string,
  attributes: {
    id: number | string,
    name: string,
    logo: string | null
  }
}
type DateObject1 = {
  from: Date | null | string;
  to: Date | null | string;
};

interface UnavailableDate {
  unavailable_from: string;
  unavailable_to: string;
}

interface ParsedDate {
  from: Date;
  to: Date;
}

interface ErrorState {
  [key: string]: boolean;
}
// Customizable Area End

export const configJSON = require("./config");
export interface FormattedDateObject {
  unavailable_from: string | null,
  unavailable_to: string | null
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export interface DateObject {
  from: Date | null,
  to: Date | null
}
export interface S {
  // Customizable Area Start
  formattedSelectedDates: (FormattedDateObject)[];
  openDatePickers: (OpenDatePicker)[],
  selectedDates: DateObject1[];
  errors: ErrorState,
  isEditUnavailabilityOpen: boolean;
  getProfile: any;
  activeTab: string;
  myWorkDiv: boolean;
  collaborateDiv: boolean;
  deleteModal: boolean;
  collabImgHovered: (boolean)[];
  workImgHovered: (boolean)[];
  openPostCreation: boolean,
  getEducation: IEducationRecord[];
  getExperience: IExperienceRecord[];
  editProfile: boolean;
  editAbout: boolean;
  editSocial: boolean;
  editEducation: boolean;
  editExperiance: boolean;
  editProfileValues: {
    seniority: string,
    Country: any,
    County: any,
    Town: any,
    PreferredWork: string,
  };
  image: string
  editChip: string[];
  editProfileInitialValues: {
    FirstName: string;
    LastName: string;
    Profession: string;
    Seniority: string; // Assuming state.editProfileValues.seniority is of type string
    Headline: string;
    Country: CountryInfo; // Assuming state.editProfileValues.Country.name is of type string
    County: CityInfo; // Assuming state.editProfileValues.County.name is of type CityInfo
    Town: CityInfo; // Assuming state.editProfileValues.Town.name is of type string
    PreferredWork: string[]; // Assuming PreferredWork is an array of strings
    image: string;
  };
  editExperience: {
    companyName: string
    Profession: string[]
    Seniority: string
    startMonth: string
    startYear: string
    endMonth: string
    endYear: string
    Country: CountryInfo
    description: string
    skills: string[] | { id: string, label: string, profileImg: string }[],
    currentlyWorking: string
  };
  editEducationInitialValue: {
    institution: string
    qualification: string
    fieldOfStudy: string
    Country: CountryInfo
    startMonth: string
    startYear: string
    endMonth: string
    endYear: string
    description: string
    skills: string[]
  },
  editSocialIntialValues: {
    linkedin: string;
    instagram: string;
    behance: string;
    vimeo: string;
    youtube: string;
    tikTok: string;
  },
  infoWork: string,
  experienceId: number,
  educationId: number,
  avalibility: boolean,
  editAboutInitialValues: {
    aboutMe: string
    skills: string[]
  },
  openModel: boolean,
  isCopied: boolean | null,
  msgSend: boolean,
  isSendLinkToChatModalOpen: boolean
  openModelChatModel: boolean,
  shareLink: string;
  getAllData: Profile[],
  getProfessionData: string[],
  shareData: OptionType[],
  sendChatMessage: string,
  skill: string[],
  PreffredWork: string[],
  SeniorityData: string[]
  openSaveModal: boolean
  updatedValue: UpdatedValues
  allValue: UpdatedValues
  companyList: CompanyList[]
  InstituteList: CompanyList[]
  galleryData: any[]
  openDeleteExpModal: boolean;
  deleteExpId: string
  openDeleteEduModal: boolean;
  deleteEduId: string
  id: string;
  favorites: string[],
  collaboratedPosts: any[]
  deleteID: number
  postID: number
  spacing: number;
  isDeleteJob : boolean;
  isAddAdministrator : boolean;
  isDeleteAdministrator : boolean;
  isEditAdministrator : boolean;
  isEditProfile : boolean;
  selectedJob : number;
  isMorePopOverOpen : boolean;
  anchorEl: HTMLElement | null;
  administrators : any;
  selectedAdministratorId : number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface TimeZone {
  zoneName: string;
  gmtOffset: number;
  gmtOffsetName: string;
  abbreviation: string;
  tzName: string;
}

interface CountryInfo {
  isoCode: string;
  name: string;
  phonecode: string;
  flag: string;
  currency: string;
  latitude: string;
  longitude: string;
  timezones: TimeZone[];
}

type CountryInfoArray = CountryInfo[];

interface CityInfo {
  name: string;
  isoCode: string;
  countryCode: string;
  latitude: string;
  longitude: string;
}

export interface Experience {

  "id": 11,
  "profession": [
    "Developer"
  ],
  "is_current": true,
  "experience_from": "2010-01-01",
  "experience_to": "2014-01-01",
  "payscale": "High",
  "company_name": "TechCorp",
  "accomplishment": null,
  "sector": "Private",
  "created_at": "2024-04-09T10:35:39.177Z",
  "updated_at": "2024-04-09T10:35:39.177Z",
  "profile_id": 34,
  "country": "India",
  "seniority_level": "senior",
  "description": "Lead a team of developers on various projects",
  "skills": [
    "Ruby on Rails",
    "JavaScript"
  ]

}
export interface EducationType {
  "id": 10,
  "qualification": "M.tech",
  "created_at": "2024-04-09T10:38:48.943Z",
  "updated_at": "2024-04-09T10:38:48.943Z",
  "year_from": "2018-01-12",
  "year_to": "2022-12-12",
  "description": "Some description",
  "institution": "Some University",
  "field_of_study": "Computer Science",
  "country": "India",
  "skills": [
    "Skill1",
    "Skill2"
  ],
  "profile_id": 34
}



export interface ProfileFormik {
  FirstName: string;
  LastName: string;
  Profession: string;
  Seniority: string; // Assuming state.editProfileValues.seniority is of type string
  Headline: string;
  Country: CountryInfo; // Assuming state.editProfileValues.Country.name is of type string
  County: CityInfo; // Assuming state.editProfileValues.County.name is of type CityInfo
  Town: CityInfo; // Assuming state.editProfileValues.Town.name is of type string
  PreferredWork: string[]; // Assuming PreferredWork is an array of strings
  image: string;
}
export interface ExperienceFormik {
  companyName: string
  Profession: string
  Seniority: string
  startMonth: string
  startYear: string
  endMonth: string
  endYear: string
  Country: CountryInfo
  description: string
  skills: string[]
  currentlyWorking: boolean
}

export interface EduFormik {
  institution: string
  qualification: string
  fieldOfStudy: string
  Country: CountryInfo
  startMonth: string
  startYear: string
  endMonth: string
  endYear: string
  description: string
  skills: string[]
}
export interface SocialFormik {
  linkedin: string
  instagram: string
  behance: string
  vimeo: string
  youtube: string
  tikTok: string
}
export interface AboutMe {
  aboutMe: string,
  skills: any
}
type StateInfoArray = CityInfo[];


interface ExperienceType {
  id: number;
  profession: string[]; // Assuming there could be multiple professions
  is_current: boolean;
  experience_from: string; // Date string in ISO format
  experience_to: string; // Date string in ISO format
  payscale: string;
  company_name: string;
  accomplishment: string | null;
  sector: string;
  created_at: string; // Date string in ISO format
  updated_at: string; // Date string in ISO format
  profile_id: number;
  country: string;
  seniority_level: string;
  description: string;
  skills: string[];
}


export default class ProfileInfoController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProfileApiCallId: string = "";
  getAllDataProfileApiCallId: string = ""
  getEducationApiCallId: string = "";
  getExperienceApiCallId: string = "";
  editAboutApiCallId: string = "";
  editProfile: string = "";
  editSocialApi: string = "";
  token: string = "";
  editExperienceApiCallId: string = ""
  editExperienceSingleApiCallId = ""
  editEducationSingleApiCall = ""
  addEduApiCallIId: string = "";
  getUserGalleryApiCallId: string = "";
  getGalleryCollaboratedPostsApiCallId: string = "";
  getDeleteCollaboratedPostsApiID: string = "";
  editAvalibility: string = ""
  getprofessionsDataCallID: string = ""
  editProfileApiCallId: string = ""
  getSkillAPICallID: string = ""
  getCompanyListCallID: string = ""
  getInstituteListCallID: string = ""
  getSeniorityDataCallID: string = ""
  getpreffredDataCallID: string = ""
  deleteExperienceCallId: string = ""
  deleteEducationCallId: string = ""
  getLikeUnlikeApiCallId: string = ""
  getAddToFavoriteApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formattedSelectedDates: [],
      openDatePickers: [{ from: false, to: false }],
      selectedDates: [] as DateObject1[],
      errors: {},
      isEditUnavailabilityOpen: false,
      getProfile: {},
      activeTab: 'info',
      openPostCreation: false,
      myWorkDiv: true,
      collaborateDiv: true,
      collabImgHovered: [false],
      workImgHovered: [false],
      deleteModal: false,

      editProfile: false,
      editAbout: false,
      editSocial: false,
      getEducation: [],
      getExperience: [],
      editEducation: false,
      editExperiance: false,
      editProfileValues: {
        seniority: "",
        Country: {} as CountryInfo,
        County: {} as CityInfo,
        Town: {} as CityInfo,
        PreferredWork: "",
      },
      image: "",
      editChip: [],
      editProfileInitialValues: {
        FirstName: "",
        LastName: "",
        Profession: "",
        Seniority: "",
        Headline: "",
        Country: {} as CountryInfo,
        County: {} as CityInfo, 
        Town: {} as CityInfo, 
        PreferredWork: [],
        image: "",

      },
      editExperience: {
        companyName: "",
        Profession: [],
        Seniority: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        Country: {} as CountryInfo,
        description: "",
        skills: [],
        currentlyWorking: "Yes"
      },
      editEducationInitialValue: {
        institution: "",
        qualification: "",
        fieldOfStudy: "",
        Country: {} as CountryInfo,
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        description: "",
        skills: [],
      },
      editSocialIntialValues: {
        linkedin: "",
        instagram: "",
        behance: "",
        vimeo: "",
        youtube: "",
        tikTok: ""
      },
      infoWork: "info",
      experienceId: 0,
      educationId: 0,
      avalibility: false,
      editAboutInitialValues: {
        aboutMe: "",
        skills: []
      },
      openModel: false,
      isCopied: null,
      msgSend: false,
      isSendLinkToChatModalOpen: false,
      openModelChatModel: false,
      shareLink:"",
      getAllData: [],
      getProfessionData: [],
      shareData: [],
      sendChatMessage: '',
      openSaveModal: false,
      updatedValue: {} as UpdatedValues,
      skill: [],
      PreffredWork: [],
      SeniorityData: [],
      allValue: {
        FirstName: "",
        LastName: "",
        Profession: [],
        Seniority: "",
        Headline: "",
        Country: {} as CountryInfo,
        Region: {} as CityInfo,
        Town: {} as CityInfo,
        PreferredWork: [],
        image: "",
        imageError: "",
      },
      companyList: [],
      InstituteList: [],
      galleryData: [],
      openDeleteExpModal: false,
      openDeleteEduModal: false,
      deleteExpId: "",
      deleteEduId: "",
      id: "",
      favorites: [],
      collaboratedPosts: [],
      deleteID: 0,
      postID: 0,
      spacing:3,
      isDeleteJob :false,
      isAddAdministrator :false,
      isDeleteAdministrator : false,
      isEditAdministrator : false,
      isEditProfile :false,
      selectedJob : 0,
      isMorePopOverOpen : false,
      anchorEl:  null,
      administrators : [
        {
          id: 1,
          name: "Clement Thomas",
          headline: "Senior interior designer",
          roles: [
            {
              id: 1,
              title: "Project Manager"
            },
            {
              id: 2,
              title: "Purchase Authority"
            },
            {
              id: 3,
              title: "Approval Authority"
            }
          ]
        },
        {
          id: 2,
          name: "Alexandro Rodrice",
          headline: "Senior interior designer",
          roles: [
            {
              id: 1,
              title: "Project Manager"
            },
            {
              id: 2,
              title: "Purchase Authority"
            }
          ]
        },
        {
          id: 3,
          name: "John F. Kennedy",
          headline: "Senior interior designer",
          roles: [
            {
              id: 3,
              title: "Approval Authority"
            }
          ]
        },
        {
          id: 4,
          name: "Jeniffer Thompson",
          headline: "Senior interior designer",
          roles: [
            {
              id: 1,
              title: "Project Manager"
            },
            {
              id: 2,
              title: "Purchase Authority"
            }
          ]
        },
        {
          id: 5,
          name: "Clement Thomas",
          headline: "Senior interior designer",
          roles: [
            {
              id: 1,
              title: "Project Manager"
            },
            {
              id: 2,
              title: "Purchase Authority"
            },
            {
              id: 3,
              title: "Approval Authority"
            }
          ]
        },
        {
          id: 6,
          name: "Alexandro Rodrice",
          headline: "Senior interior designer",
          roles: [
            {
              id: 1,
              title: "Project Manager"
            },
            {
              id: 2,
              title: "Purchase Authority"
            }
          ]
        }
      ],
      selectedAdministratorId : 0
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.token = localStorage.getItem("token") || ""

    this.handleGetUserProfile();
    this.getEducation();
    this.getExperience();
    this.loadUserGallery()
    this.handleGalleryCollaboratedPosts()
    const activeTab = this.props.navigation.getParam('activeTab');
    if (activeTab) {this.setState({ activeTab });}
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start  

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      if (responseJson && !responseJson.errors) {
        this.getProfileDataReciveFunc(apiRequestCallId, responseJson)
        if (apiRequestCallId === this.getEducationApiCallId) {
          this.handleEducationAPI(responseJson)
        } else if (apiRequestCallId === this.getExperienceApiCallId) {
          this.handleExperienceAPI(responseJson)
        } else if (apiRequestCallId === this.editProfileApiCallId) {
          this.handleCloseSaveModal()
          this.handleEditDialougClose()
          this.handleGetUserProfile();


        } else if (apiRequestCallId === this.editAboutApiCallId) {
          this.setState({ editAbout: false })
          this.handleGetUserProfile();

        } else if (apiRequestCallId === this.editSocialApi) {
          this.handleGetUserProfile();
        } else if (apiRequestCallId === this.editExperienceApiCallId) {
          this.setState({ editExperiance: false })
          this.handleExperianceClose()
          this.getExperience();
        } else if (apiRequestCallId === this.addEduApiCallIId || apiRequestCallId === this.editEducationSingleApiCall) {
          this.setState({ editEducation: false })
          this.getEducation();
        } else if (apiRequestCallId === this.editExperienceSingleApiCallId) {
          this.setState({ editExperiance: false })
          this.getExperience();
        }
        this.getUserGalleryData(apiRequestCallId, responseJson)
        this.apiCallFunction(apiRequestCallId, responseJson)
        this.getProfessionDataRecive(apiRequestCallId, responseJson)
        this.updateAvalibilityRecive(apiRequestCallId)
        this.deleteExperienceReceive(apiRequestCallId, responseJson)
        this.likeUnlikeMediaReceive(apiRequestCallId, responseJson)
        this.galleryCollaboratedPostsReceive(apiRequestCallId, responseJson)
        this.deleteCollaboratedPostsReceive(apiRequestCallId, responseJson)
        this.addToFavoriteReceive(apiRequestCallId, responseJson);
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  onChangeText = _.debounce((inputValue: string, dates: DateObject1, gridIndex: number, type: string) => {
    const date = moment(inputValue, 'DD/MM/YYYY', true);
    const compareResult = type === "from" ? date.isSameOrBefore(moment(dates.to)) : date.isSameOrAfter(moment(dates.from));
    if (date.isValid() && inputValue.length === 10 && !date.isBefore(moment().startOf('day')) && compareResult) {
      this.handleDateChange(date.toDate(), type, gridIndex)
    }else{
      let newErrors = { ...this.state.errors };
      newErrors[`${type}-${gridIndex}`] = true;
      this.setState({errors: newErrors})
    }
  }, 2000);

  increaseSpacing = () => {
    this.setState({spacing: 24});
  }

  decreaseSpacing = () => {
    this.setState({spacing: 3});
  }

  getUserGalleryData = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getUserGalleryApiCallId) {
      this.setState({ galleryData: responseJson.user_gallery })
    }
  }

  likeUnlikeMediaReceive = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getLikeUnlikeApiCallId) {
        if (responseJson) { 
          this.handleGalleryCollaboratedPosts();
          this.loadUserGallery();
        } 
    }
};

addToFavoriteReceive = (apiRequestCallId: string, responseJson: any) => {
  if (apiRequestCallId === this.getAddToFavoriteApiCallId) {
      if (responseJson.message) { 
        this.handleGalleryCollaboratedPosts();
        this.loadUserGallery();
      } 
  }
};

galleryCollaboratedPostsReceive = (apiRequestCallId: string, responseJson: any) => {
  if (apiRequestCallId === this.getGalleryCollaboratedPostsApiCallId) {
    if (responseJson && responseJson.collaborated_gallery_data) {
      this.setState({
        collaboratedPosts: responseJson.collaborated_gallery_data.flat() 
      });
    } 
  }
};

  deleteCollaboratedPostsReceive = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getDeleteCollaboratedPostsApiID) {
        if (responseJson) {
            this.setState( {
                deleteModal: false,
                collaboratedPosts: this.state.collaboratedPosts.filter(data => data.post_id !== this.state.postID), 
          });
        }
    }
  }

  setupBeforeUnloadListener = () => {
    window.history?.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history?.pushState(null, '', window.location.href);
      if (this.state.editProfile) {
        this.handleClickOpenSaveModal(this.state.allValue)
      }
    };

  };

  apiCallFunction = (apiRequestCallId: string, responseJson: { data: Profile[] }) => {
    if (apiRequestCallId === this.getAllDataProfileApiCallId) {
      this.setState({
        ...this.state,
        getAllData: responseJson.data,
      });
    }
  }
  updateAvalibilityRecive = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.editAvalibility) {
      this.setState({isEditUnavailabilityOpen: false});
      this.handleGetUserProfile()
    }
  }
  deleteExperienceReceive = (apiRequestCallId: string, responseJson: { message: string } | { message: { message: string } }) => {
    if (apiRequestCallId == this.deleteExperienceCallId) {
      if (typeof responseJson.message === 'string') {
        this.getExperience()
        this.handleExperianceClose()
        this.closeDeleteModal()
      }
    }
    if (apiRequestCallId == this.deleteEducationCallId) {
      if (typeof responseJson.message === 'string') {
        this.getEducation()
        this.handleEducationClose()
        this.closeDeleteModal()
      }
    }
  }

  setUnavailableDates = (unavailableDates: string) => {
    const datesData: UnavailableDate[] = JSON.parse(unavailableDates);
    const dates: ParsedDate[] = datesData.map((dateObj: UnavailableDate) => {
      const fromDate = new Date(dateObj.unavailable_from);
      const toDate = new Date(dateObj.unavailable_to);
      if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
        return null;
      }
      return {
        from: fromDate,
        to: toDate,
      };
    }).filter((date): date is ParsedDate => date !== null);
    const newFormattedSelectedDates = dates.map(item => ({
      unavailable_from: item.from ? moment(item.from).format('YYYY-MM-DD') : null,
      unavailable_to: item.to ? moment(item.to).format('YYYY-MM-DD') : null,
    })); 
    this.setState({ selectedDates: dates, formattedSelectedDates: newFormattedSelectedDates });
  };

  handleExperienceAPI = (responseJson: { data: IExperienceRecord[] }) => {
    if (responseJson && responseJson.data && responseJson.data.length) {
      this.setState({
        getExperience: responseJson.data
      })
    } else {
      this.setState({
        getExperience: []
      })
    }
  }
  handleEducationAPI = (responseJson: { data: IEducationRecord[] }) => {
    if (responseJson && responseJson.data && responseJson.data.length) {
      this.setState({
        getEducation: responseJson.data
      })
    } else {
      this.setState({
        getEducation: []
      })
    }
  }


  getProfileDataReciveFunc = async (apiRequestCallId: string, responseJson: { data: Profile }) => {
    if (apiRequestCallId === this.getProfileApiCallId) {
      const countryData = this.getCountry()?.find((objs) => objs.label === responseJson.data.attributes.country)?.value;
      const stateData = this.getStateArray(countryData?.isoCode ?? '')?.find((stateData) => stateData.label === responseJson.data.attributes.county)?.value;
      const townData = this.getCityArray(countryData?.isoCode ?? '', stateData?.isoCode ?? '')?.find((townData) => townData.label === responseJson.data.attributes.city)?.value;
      const availability = responseJson.data.attributes.availability === "opened" ? true : false
      this.setState({
        ...this.state,
        getProfile: {
          ...responseJson.data,
          attributes: {
            ...responseJson.data.attributes,
            country: countryData,
            county: stateData,
            city: townData,
          }
        },
        avalibility: availability,
        allValue: {
          FirstName: responseJson.data.attributes.first_name,
          LastName: responseJson.data.attributes.last_name,
          Profession: responseJson.data.attributes.profession,
          Seniority: responseJson.data.attributes.seniority_level,
          Headline: responseJson.data.attributes.headline,
          Country: countryData as CountryInfo,
          Region: stateData as CityInfo,
          Town: townData as unknown as CityInfo,
          PreferredWork: responseJson.data.attributes.work,
          image: responseJson.data.attributes.photo,
          imageError: ''
        }
      });
      this.setUnavailableDates(responseJson.data.attributes.unavailable_dates);
      this.setState({
        ...this.state,
        editAboutInitialValues: {
          aboutMe: responseJson.data?.attributes?.description,
          skills: responseJson.data.getProfile?.attributes?.skill
        }
      })
    }
  }
  getProfessionDataRecive = (apiRequestCallId: string, responseJson: { professions: string[], skills: string[], data: CompanyList[], prefrred_type_of_works: string[], seniority_level: string[] }) => {
    if (apiRequestCallId === this.getprofessionsDataCallID) {
      this.setState({
        getProfessionData: responseJson.professions,
      });
    }
    else if (apiRequestCallId === this.getSkillAPICallID) {
      this.setState({
        skill: responseJson.skills,
      });
    }

    else if (apiRequestCallId === this.getCompanyListCallID) {
      this.setState({
        companyList: responseJson.data,
      });
    }

    else if (apiRequestCallId === this.getInstituteListCallID) {
      this.setState({
        InstituteList: responseJson.data,
      });
    }
    else if (apiRequestCallId === this.getSeniorityDataCallID) {
      this.setState({
        SeniorityData: responseJson.seniority_level,
      });
    }
    else if (apiRequestCallId === this.getpreffredDataCallID) {
      this.setState({
        PreffredWork: responseJson.prefrred_type_of_works,
      });
    }

  }
  getIds = () => {
    return sessionStorage.getItem("profileId")
  }
  handleDeleteModal = () => {
    this.setState({ deleteModal: false })
  }
  handleOpenWorkDiv() {
    this.setState({ myWorkDiv: !this.state.myWorkDiv })
  }
  handleOpenCollaborate() {
    this.setState({ collaborateDiv: !this.state.collaborateDiv })
  }
  handleTabChange(type: string) {
    this.setState({ activeTab: type }, () => {
      this.loadUserGallery()
      this.handleGalleryCollaboratedPosts()
    })
  }
  loadUserGallery = () => {
    const headers = {
      "token": window.localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserGalleryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserGalleryEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleGetUserProfile = () => {
    const headers = {
      "token": this.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfileEndPoint}/${this.getIds()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetAllUserProfile = async () => {
    const headers = {
      "token": await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllDataProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfileEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getEducation = () => {
    let formattedToken = this.token.replace(/^"(.*)"$/, '$1');
    const headers = {
      "token": formattedToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEducationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getEducationEndPoint}${this.getIds()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  calculateMonths = (startYr: string, endYr: string) => {
    const startDate = new Date(startYr);
    const endDate = new Date(endYr);
    const totalMonthsDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12
      + (endDate.getMonth() - startDate.getMonth());
    const years = Math.floor(totalMonthsDiff / 12);
    const months = totalMonthsDiff % 12;
    let result = "";
    if (years > 0) {
      result += `${years} year${years > 1 ? 's' : ''} `;
    }
    if (months > 0) {
      result += `${months} month${months > 1 ? 's' : ''}`;
    }
    return result.trim()
  };

  getExperience = () => {
    let formattedToken = this.token.replace(/^"(.*)"$/, '$1');
    const headers = {
      "token": formattedToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getExperienceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getExperience}${this.getIds()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  editAboutMe = (value: any) => {
    const values = {
      description: value.aboutMe,
      skill: value.skills
    }
    let formattedToken = this.token.replace(/^"(.*)"$/, '$1');

    const headers = {
      "Content-Type": "application/json",
      "token": formattedToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editAboutApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfileEndPoint}/${this.getIds()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  editExperienceUpdate = (value: any) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2);

    const formateDate = `${year}-${month}`;
    const formattedDate = formateDate.replace(/\s+/g, '');
    const skills = Array.isArray(value.skills) ? value.skills : []
    const medias = {
      "career": {
        "profession": value.Profession,
        "is_current": value.currentlyWorking === "Yes" ? true : false,
        "experience_from": `${value.startYear}-${this.getMonthNumber(value.startMonth)}`,
        "experience_to": value.currentlyWorking === "No" ? `${value.endYear}-${this.getMonthNumber(value.endMonth)}` : formattedDate,
        "company_name": value.companyName,
        "country": value.Country?.name,
        "seniority_level": value.Seniority,
        "description": value.description,
        "skills": [...skills]
      }
    }
    let formattedToken = this.token.replace(/^"(.*)"$/, '$1');

    const headers = {
      "Content-Type": "application/json",
      "token": formattedToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editExperienceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createExperienceEndPoint}?profile_id=${this.getIds()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(medias)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  editSingleExperience = (value: any) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2);
    const dateFormate = `${year}-${month}`;
    const cleanDate = dateFormate.replace(/\s+/g, '');
    const skills = Array.isArray(value.skills) ? value.skills : []
    
    const media = {
      career: {
        "profession": value.Profession,
        "is_current": value.currentlyWorking === "Yes" ? true : false,
        "experience_from": `${value.startYear}-${this.getMonthNumber(value.startMonth)}`,
        "experience_to": value.currentlyWorking === "No" ? `${value.endYear}-${this.getMonthNumber(value.endMonth)}` : cleanDate,
        "company_name": value.companyName,
        "country": value.Country?.name,
        "seniority_level": value.Seniority,
        "description": value.description,
        "skills": [...skills]
      }
    }
    let formattedToken = this.token.replace(/^"(.*)"$/, '$1');
    const headers = {
      "Content-Type": "application/json",
      "token": formattedToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editExperienceSingleApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editSingleExperience}${this.state.experienceId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(media)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  editSocial = (value: any) => {
    const medias = {
      media: { ...value }
    }
    let formattedToken = this.token.replace(/^"(.*)"$/, '$1');

    const headers = {
      "Content-Type": "application/json",
      "token": formattedToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editSocialApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfileEndPoint}/${this.getIds()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(medias)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  UpdateProfile = () => {
    const { updatedValue, allValue } = this.state
    const profession = updatedValue.Profession || allValue.Profession || [];
    const preferredWork = updatedValue.PreferredWork || allValue.PreferredWork || [];
    const formData = new FormData();
    if (updatedValue.image && typeof updatedValue.image === 'object' && 'size' in updatedValue.image && 'type' in updatedValue.image || allValue.image && typeof allValue.image === 'object' && 'size' in allValue.image && 'type' in allValue.image) {
      formData.append('photo', updatedValue.image || allValue.image);
    }

    formData.append('first_name', updatedValue.FirstName || allValue.FirstName);
    formData.append('last_name', updatedValue.LastName || allValue.LastName);
    profession.forEach((profession) => {
      formData.append(`profession[]`, profession);
    });
    formData.append('seniority_level', updatedValue?.Seniority || allValue.Seniority);
    formData.append('headline', updatedValue.Headline || allValue.Headline);
    formData.append('country', updatedValue.Country?.name || allValue?.Country?.name);
    formData.append('county', updatedValue.Region?.name || allValue?.Region?.name);
    formData.append('city', updatedValue.Town?.name || allValue?.Town?.name);
    preferredWork.forEach((work) => {
      formData.append(`work[]`, work);
    });


    let formattedToken = this.token.replace(/^"(.*)"$/, '$1');
    const headers = {
      redirect: "follow",
      "token": formattedToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfileEndPoint}/${this.getIds()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleEducation = () => {
    this.setState({ educationId: 0 })
    this.setState({ editEducation: true })
    this.getSkillData()
    this.getInstituteList()
    const updatedValue = {
      institution: "",
      qualification: "",
      fieldOfStudy: "",
      Country: {} as CountryInfo,
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      description: "",
      skills: [],
    }
    this.setState({ editEducationInitialValue: updatedValue })
  }
  handleEducationClose = () => {
    this.setState({ editEducation: false })
  }
  handleEditDialoug = () => {
    this.setState({ editProfile: true })
    this.getprofessionsData();
    this.getSeniorityData();
    this.getPreffredWorkData();
    this.setupBeforeUnloadListener()
  }
  handleEditDialougClose = () => {
    this.setState({ editProfile: false })


  }
  handleSocialOpenExp = () => {
    this.setState({ editSocial: true })
    const { linkedin, instagram, behance, vimeo, youtube, tikTok } = this.state.getProfile?.attributes.media;
    const updatedSocialInitialValues = {
      linkedin: linkedin || "",
      instagram: instagram || "",
      behance: behance || "",
      vimeo: vimeo || "",
      youtube: youtube || "",
      tikTok: tikTok || ""
    };

    this.setState({ editSocialIntialValues: updatedSocialInitialValues });
  }
  handleEditingExp = (record: IExperienceRecord) => {
    const { id, attributes: value } = record
    this.setState({ editExperiance: true })
    this.setState({ experienceId: Number(id) })
    this.getCompanyList();
    this.getSeniorityData();
    this.getprofessionsData();
    const month = value.experience_from.split("-")[1];
    const StartmonthName = new Date(`2022-${month}-01`).toLocaleString('default', { month: 'long' });
    const StartYear = value.experience_from.split("-")[0];

    const endMonth = value.experience_to.split("-")[1];
    const endMonthName = new Date(`2022-${endMonth}-01`).toLocaleString('default', { month: 'long' })
    const endYear = value.experience_to.split("-")[0];
    this.setState(prevState => ({
      ...prevState,
      editExperience: {
        ...prevState.editExperience,
        companyName: value.company_name.data[0]?.attributes?.name,
        Profession: value.profession,
        Seniority: (value.seniority_level ?? '').replace(/^./, (str: string) => str.toUpperCase()),
        startMonth: StartmonthName,
        startYear: StartYear,
        endMonth: value.is_current === true ? "" : endMonthName,
        endYear: value.is_current === true ? "" : endYear,
        Country: {
          name: value.country,
          isoCode: "",
          phonecode: "",
          flag: "",
          currency: "",
          latitude: "",
          longitude: "",
          timezones: [],
        },
        currentlyWorking: value.is_current === true ? "Yes" : "No",
        description: value.description,
        skills: value.skills
      }
    }));

  }
  handleEditAbout = () => {
    this.setState({ editAbout: !this.state.editAbout })
    this.getSkillData()
  }
  handlePostCreation = () => {
    this.setState({ openPostCreation: !this.state.openPostCreation })
  }
  handleEditSocial = () => {
    this.setState({ editSocial: !this.state.editSocial })
    const updatedSocialInitialValues = {
      linkedin: "",
      instagram: "",
      behance: "",
      vimeo: "",
      youtube: "",
      tikTok: ""
    };
    this.setState({ editSocialIntialValues: updatedSocialInitialValues });

  }
  handleExperiance = () => {
    this.setState({ editExperiance: true })
    this.setState({ experienceId: 0 })
    this.getSkillData()
    this.getprofessionsData();
    this.getCompanyList();
    this.getSeniorityData();
    const updateExperience = {
      companyName: "",
      Profession: [],
      Seniority: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: '',
      Country: {} as CountryInfo,
      description: "",
      skills: [],
      currentlyWorking: "Yes"
    }
    this.setState({ editExperience: updateExperience });
  }

  handleExperianceClose = () => {
    this.setState({ editExperiance: false })
  }

  updateFormValues = (keys: string, values: string) => {
    this.setState(prevState => ({
      editProfileValues: {
        ...prevState.editProfileValues,
        [keys]: values
      }
    }));
  }
  openPopUp = (event: React.MouseEvent<HTMLImageElement>, postId: number) => {
    event.stopPropagation();
    this.setState({
      openModel: true,
      shareLink: window.location.href.split("/",3).join("/")+"/PostDetails/"+`${postId}`
    })
  }
  handleClose = () => {
    this.setState({
      openModel: false,
      isCopied: null,
      msgSend: false,
      shareLink:""
    })
  }
  handleChat = () => {
    this.setState({ isSendLinkToChatModalOpen: true, msgSend: false, isCopied: null })
    this.handleGetAllUserProfile();
  }

  handleshareDataChatChange = (_event: React.ChangeEvent<{}>, value: OptionType[]) => {
    this.setState({ shareData: value });
  };
  handlesendChatMessage = (event: { target: { value: string } }) => {
    this.setState({ sendChatMessage: event.target.value });
  }

  handleClickCopy = () => {
    this.setState({ msgSend: false })
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        this.setState({ isCopied: true });
      })
      .catch((error) => { this.setState({ isCopied: false }); });
  }
  handleNavigateBack = () => {
    this.setState({
      isSendLinkToChatModalOpen: false,
      openModel: true
    })
  }
  handleCloseChatModel = () => {
    this.setState({ isSendLinkToChatModalOpen: false, shareData: [], sendChatMessage: '' });
  }

  handleSend = () => {
    this.setState({ isSendLinkToChatModalOpen: false, shareData: [], sendChatMessage: '' });
    this.setState({ msgSend: true });
  }
  updatePlace = (newValue: any, key: string) => {
    const { value } = newValue.target;
    this.setState(prevState => ({
      editProfileValues: {
        ...prevState.editProfileValues,
        [key]: value.name
      }
    }));
  }
  handleSenority = (event: {
    target: {
      value: string
    }
  }) => {
    const { value } = event.target;
    this.setState(preState => ({
      editProfileValues: {
        ...preState.editProfileValues,
        seniority: value
      }
    }))
  }


  handleImageChange = (event: any, setFieldValue: any) => {
    const file = event?.currentTarget?.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];

    if (file && !allowedTypes.includes(file.type)) {
      setFieldValue("imageError", "File type not allowed. Please upload an image in JPEG, PNG, JPG, or WebP format.");
      return;
    }
    setFieldValue("imageError", "");
    setFieldValue('image', file);
    this.setState(prevState => ({
      allValue: {
        ...prevState.allValue,
        image: file
      }
    }));
  };
  handleChangeInfoWork = (value: string) => {
    this.setState({ infoWork: value })
  }
  addEducatioin = (value: EducationTypeData) => {
    const skillsArray = Array.isArray(value.skills) ? value.skills : []
    const medias = {
      education: {
        qualification: value.qualification,
        year_from: `${value.startYear}-${this.getMonthNumber(value.startMonth)}`,
        year_to: `${value.endYear}-${this.getMonthNumber(value.endMonth)}`,
        description: value.description,
        institution: value.institution,
        field_of_study: value.fieldOfStudy,
        country: value.Country?.name,
        skills: [...skillsArray]
      }
    }
    let formattedToken = this.token.replace(/^"(.*)"$/, '$1');

    const headers = {
      "Content-Type": "application/json",
      "token": formattedToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addEduApiCallIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createEddducation}${this.getIds()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(medias)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  editEducationPopUp = (Item: IEducationRecord) => {
    const { id, attributes: value } = Item
    this.setState({ editEducation: true })
    this.setState({ educationId: Number(id) })
    this.getSkillData()
    this.getInstituteList()
    const month = value.year_from.split("-")[1];
    const StartmonthName = new Date(`2022-${month}-01`).toLocaleString('default', { month: 'long' });
    const StartYear = value.year_from.split("-")[0];

    const endMonth = value.year_to.split("-")[1];
    const endMonthName = new Date(`2022-${endMonth}-01`).toLocaleString('default', { month: 'long' })
    const endYear = value.year_to.split("-")[0];

    this.setState(prevState => ({
      ...prevState,
      editEducationInitialValue: {
        ...prevState.editEducationInitialValue,
        institution: value.institution?.data[0]?.attributes.name,
        qualification: value.qualification,
        startMonth: StartmonthName,
        startYear: StartYear,
        endMonth: endMonthName,
        endYear: endYear,
        Country: {
          name: value.country,
          isoCode: "",
          phonecode: "",
          flag: "",
          currency: "",
          latitude: "",
          longitude: "",
          timezones: [],
        },
        fieldOfStudy: value.field_of_study,
        description: value.description,
        skills: value.skills
      }
    }));
  }

  editSingleEducation = (value: any) => {
    let skillList
    if (value.skills?.[0]?.label) {
      skillList = value.skills.map((obj: { id: number, label: string, profileImg: string }) => obj.label)
      skillList.push(...this.state.editEducationInitialValue.skills);
    } else {
      skillList = value.skills
    }
    const media = {
      education: {
        "qualification": value.qualification,
        "description": value.description,
        "year_from": `${value.startYear}-${this.getMonthNumber(value.startMonth)}`,
        "year_to": `${value.endYear}-${this.getMonthNumber(value.endMonth)}`,
        "institution": value.institution,
        "field_of_study": value.fieldOfStudy,
        "country": value.Country?.name,
        "skills": skillList
      }
    }
    let formattedToken = this.token.replace(/^"(.*)"$/, '$1');

    const headers = {
      "Content-Type": "application/json",
      "token": formattedToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editEducationSingleApiCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.EditSingleEducation}${this.state.educationId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(media)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  handleAvalibility = () => {
    this.setState(
      prevState => ({ avalibility: !prevState.avalibility }),
      () => {
        this.updateAvalibility();
      }
    );
  }
  getCallApi = (values: any) => {
    if (this.state.experienceId == 0) {
      this.editExperienceUpdate(values)
    } else {
      this.editSingleExperience(values)
    }
  }
  getApiCalled = (values: any) => {
    if (this.state.educationId == 0) {
      this.addEducatioin(values)
    } else {
      this.editSingleEducation(values)
    }
  }
  getStateArray = (iosCode: string) => {

    let newArray = State.getStatesOfCountry(iosCode).map((countryVal: IState) => ({ value: countryVal, label: countryVal.name }))
    return newArray
  }

  getCityArray = (iosCodeCountry: string, iosCodeCounty: string) => {
    let newArray = City.getCitiesOfState(iosCodeCountry, iosCodeCounty).map((countryVal: ICity) => ({ value: countryVal, label: countryVal.name }))
    return newArray
  }
  getCountry = () => {
    let CounntryArray: ICountry[] = Country.getAllCountries()
    let newArray = CounntryArray.map((countryVal: ICountry) => ({ value: countryVal, label: countryVal.name }))
    return newArray
  }
  getMonthNumber = (monthName: string): string | undefined => {
    const months: { [key: string]: string } = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12"
    };

    return months[monthName];
  }
  addNewDatePicker = () => {
    this.setState(prevState => ({
      selectedDates: [...prevState.selectedDates, { from: null, to: null }],
      openDatePickers: [...prevState.openDatePickers, { from: false, to: false }],
    }));
  };
  handleDateChange = (selectedDate: Date | null, type: string, gridIndex: number) => {
    this.setState(prevState => {
      const newSelectedDates = [...prevState.selectedDates];
      const newErrors = {...prevState.errors}
      if (type === 'from') {
        newSelectedDates[gridIndex] = {
          ...newSelectedDates[gridIndex],
          from: selectedDate,
        };
        newErrors[`from-${gridIndex}`] = false;
      } else {
        newSelectedDates[gridIndex] = {
          ...newSelectedDates[gridIndex],
          to: selectedDate,
        };
        newErrors[`to-${gridIndex}`] = false;
      }

      // Create formatted dates
      const newFormattedSelectedDates = newSelectedDates.map(item => ({
        unavailable_from: item.from ? moment(item.from).format('YYYY-MM-DD') : null,
        unavailable_to: item.to ? moment(item.to).format('YYYY-MM-DD') : null,
      }));

      return {
        selectedDates: newSelectedDates,
        formattedSelectedDates: newFormattedSelectedDates,
        errors: newErrors
      };
    });
  }

  handleDateInputRemove = (index: number) => {
    this.setState(prevState => {
      const updatedDates = [...prevState.selectedDates];
      updatedDates.splice(index, 1);

      return {
        selectedDates: updatedDates,
        formattedSelectedDates: updatedDates.map(item => ({
          unavailable_from: moment(item.from).format('YYYY-MM-DD'),
          unavailable_to: moment(item.to).format('YYYY-MM-DD')
        }))
      }
    });
  }

  handleDatePickerClose = (type: string, gridIndex: number) => {
    const newOpenDatePickers = [...this.state.openDatePickers];
    if (type == 'from') {
      newOpenDatePickers[gridIndex].from = false;
    }
    else {
      newOpenDatePickers[gridIndex].to = false;
    }
    this.setState({ openDatePickers: newOpenDatePickers });
  };
  handleDatePickerOpen = (type: string, gridIndex: number) => {
    const newOpenDatePickers = [...this.state.openDatePickers];

    if (!newOpenDatePickers[gridIndex]) {
      newOpenDatePickers[gridIndex] = newOpenDatePickers[gridIndex] || { from: false, to: false };

    }
    if (type == 'from') {
      newOpenDatePickers[gridIndex].from = true;
    }
    else {
      newOpenDatePickers[gridIndex].to = true;
    }
    this.setState({ openDatePickers: newOpenDatePickers });
  };

  handleCheckForErrors = () => {
    let isValid = true;
    Object.keys(this.state.errors).forEach(key => {
      if(this.state.errors[key] === true){
        isValid = false;
      }
    });
    return isValid;
  }


  handleDisableSaveButton = () => {
    if (this.state.selectedDates.length > 0 && !this.handleCheckForErrors()) {
      const allObjectsValid = this.state.selectedDates.every(item => item.from !== null && item.to !== null);
      return !allObjectsValid; 
    }
    return false;
  };

  handleUnavailabilityOption = () => {
    this.setState(prevState => ({...prevState, isEditUnavailabilityOpen: !prevState.isEditUnavailabilityOpen}));
  };

  updateAvalibility = () => {
    let values = {
      availability: this.state.avalibility ? "opened" : "closed",
      unavailable_dates: JSON.stringify(this.state.formattedSelectedDates)
    }
    let formattedToken = this.token.replace(/^"(.*)"$/, '$1');
    const headers = {
      "Content-Type": "application/json",
      "token": formattedToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editAvalibility = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfileEndPoint}/${this.getIds()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  renderCopyStatus() {
    if (this.state.msgSend) {
      return "Message sent!"
    }
    if (this.state.isCopied !== null) {
      return this.state.isCopied ? "Link copied!" : "Copy error to clipboard";
    }
  }

  getprofessionsData = async () => {

    let token = await getStorageData("token")
    const headers = {
      "token": token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `profession/professions`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    this.getprofessionsDataCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getSeniorityData = async () => {

    let token = await getStorageData("token")
    const headers = {
      "token": token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `profession/seniority_level`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    this.getSeniorityDataCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPreffredWorkData = async () => {

    let token = await getStorageData("token")
    const headers = {
      "token": token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `profession/work_types`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    this.getpreffredDataCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClickOpenSaveModal = (value: UpdatedValues) => {


    this.setState({ openSaveModal: true, updatedValue: value });
  };

  handleCloseSaveModal = () => {
    this.setState({ openSaveModal: false });
  };


  getSkillData = async () => {

    let token = await getStorageData("token")
    const headers = {
      "token": token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `skill/skills`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    this.getSkillAPICallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDataSet = (value: UpdatedValues) => {
    this.setState({ updatedValue: value });
    if (value?.imageError == "") {
      this.UpdateProfile()
    }
  };

  handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | unknown>,
    setFieldValue: (name: string, value: string) => void
  ) => {
    if (event && event.target) {
      const target = event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

      if ('name' in target && 'value' in target) {
        const { name, value } = target;
        setFieldValue(name, value);

        this.setState((prevState) => ({
          allValue: {
            ...prevState.allValue,
            [name]: value,
          },
        }));
      }
    }
  };


  handleAutoComplateChange = (fieldName: string, newValue: string[], setFieldValue: (fieldName: string, newValue: string[]) => void) => {
    setFieldValue(fieldName, newValue);
    this.setState(prevState => ({
      allValue: {
        ...prevState.allValue,
        [fieldName]: newValue
      }
    }))
  };
  getCompanyList = async () => {
    let token = await getStorageData("token")
    const headers = {
      "token": token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `profession/companies`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getCompanyListCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInstituteList = async () => {
    let token = await getStorageData("token")
    const headers = {
      "token": token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `profession/institutions`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getInstituteListCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  parseDate(date: string | Date | null): Date | null {
    if (date instanceof Date) {
      return date;
    }
    if (typeof date === 'string') {
      const parsedDate = new Date(date);
      return isNaN(parsedDate.getTime()) ? null : parsedDate;
    }
    return null;
  }
  navigateToAvailability = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), `SettingsProfile`);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const payloadMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage))
    payloadMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { tab: 1 })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), payloadMessage);
    this.send(message);
  }

  closeDeleteModal = () => {
    this.setState({
      openDeleteExpModal: false,
      openDeleteEduModal: false,
      deleteExpId: "",
      deleteEduId: ""
    })
  }
  openDeleteExperience = (expId: string) => {
    this.setState({
      openDeleteExpModal: true,
      deleteExpId: expId,
    })
  }
  openDeleteEducation = (eduId: string) => {
    this.setState({
      openDeleteEduModal: true,
      deleteEduId: eduId,
    })
  }
  deleteExperience = () => {
    const { deleteExpId } = this.state
    
    const formattedToken = this.token.replace(/^"(.*)"$/, '$1');
    const headers = {
      "token": formattedToken,
    };

    const endpoints = `${configJSON.ENDPOINTS.DELETE_EXPERIENCE}/${deleteExpId}`

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteExperienceCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endpoints);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteEducation = () => {
    const { deleteEduId } = this.state

    const formattedToken = this.token.replace(/^"(.*)"$/, '$1');
    const headers = {
      "token": formattedToken,
    };

    const endpoints = `${configJSON.ENDPOINTS.DELETE_EDUCATION}/${deleteEduId}`
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteEducationCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endpoints);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleLikeUnlikeMedia = (event: React.MouseEvent<SVGSVGElement>, index: number, id: string) => {
    event.stopPropagation();
    const isCurrentlyLiked = this.state.galleryData[index].is_like;
    const actionType = isCurrentlyLiked ? "unlike" : "like";
  
    const headers = {
      "token": window.localStorage.getItem('token') || '',
    };
  
    const formData = new FormData();
    formData.append("id", id);
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLikeUnlikeApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/posts/like_image"
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    this.setState(prevState => {
      const updatedGalleryData = [...prevState.galleryData];
      updatedGalleryData[index].is_like = actionType === "like";
      return { galleryData: updatedGalleryData };
    });
  
    return true;
  };
  
  handleAddToFavorite = async (event: React.MouseEvent<SVGSVGElement>, index: number, id: string) => {
    event.stopPropagation();
    const isCurrentlyFavorite = this.state.galleryData[index].is_favourite;
    const actionType = isCurrentlyFavorite ? "unfavorite" : "favorite";
  
    const headers = {
      "token": window.localStorage.getItem('token') || '',
    };
  
    const formData = new FormData();
    formData.append("id", id);
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAddToFavoriteApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/posts/add_to_favorite"
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    this.setState(prevState => {
      const favorites = [...prevState.galleryData];
      favorites[index].is_favourite = actionType === "favorite";
      return { galleryData: favorites };
    });
  };

  handleGalleryCollaboratedPosts = () => {
    const headers = {
      "token": window.localStorage.getItem('token')
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getGalleryCollaboratedPostsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/posts/gallery_of_collaborated_posts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleDeleteCollaboratedPosts = () => {

    const {postID} = this.state;
    const deleteID = this.state.getProfile.attributes.account_id;

    const headers = {
      "token": window.localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDeleteCollaboratedPostsApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCollabPosts}?post_id=${postID}&account_id=${deleteID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  

  navigateTo = (to: string, id: string) => {
    this.props.navigation.navigate(to, { id });
  }
  handleCloseDeletePopUp = ()=>{
    this.setState({isDeleteJob : false})
  };
  handleOpenDeletePopUp = (jobId : number) =>{
    this.setState({isDeleteJob : true, selectedJob : jobId})
  };
  handleDeleteJob = () =>{

  };
  handleClick = (event: React.MouseEvent<SVGSVGElement>, id:number) => {
    this.setState({
      anchorEl: event.currentTarget as unknown as HTMLElement,
      selectedAdministratorId: id,
    });
  };
  handleOpenAdministratorPopUp = () =>{
    this.setState({isAddAdministrator : true})
  };
  handleCloseAdministratorPopUp = () =>{
    this.setState({isAddAdministrator : false})
  };
  handleUpdateAdministrator = () =>{

  };
  handleEditProfilePopUpOpen = ()=>{
    this.setState({isEditProfile : true})
  }
  handleEditProfilePopUpClose = ()=>{
    this.setState({isEditProfile : false})
  };
  handleDeleteAdministratorPopUpOpen = () =>{
    this.setState({isDeleteAdministrator : true})
    
  };
  handleDeleteAdministratorPopUpCLose = () =>{
    this.setState({isDeleteAdministrator : false})
  };
  handleDeleteAdministrator = () =>{
    this.setState({
      isDeleteAdministrator : false,
      administrators: this.state.administrators.filter((administrator:any) => administrator.id !== this.state.selectedAdministratorId),
      anchorEl : null
    });
  };
  handleEditAdministratorPopUpOpen = () =>{
    this.setState({isEditAdministrator : true})
  };
  handleEditAdministratorPopUpCLose = () =>{
    this.setState({isEditAdministrator : false})
  };
  handleEditAdministrator = () =>{

  };
  handleClosePopUp = () => {
    this.setState({
      anchorEl: null,
    });
  };
   
  getErrors = (type: string, gridIndex: number) => {
      if(this.state.errors && this.state.errors[`${type}-${gridIndex}`]){
        return `Please add valid ${type} date`
      }
  }
  // Customizable Area End
}
