import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider, Theme } from "@material-ui/core/styles";
import { Box, Button, Grid, ImageList, ImageListItem, Typography } from "@material-ui/core";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import { arrowRight } from "./assets";
// Customizable Area End

import LandingPage2Controller, {
    Props,
} from "./LandingPage2Controller";

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
    typography:{
        fontFamily:"Silka"
    }
});
const StyledContainer = styled(Box)(({theme}: {theme: Theme}) => ({
    "& .findTalentContainer":{
        paddingLeft:"64px",
        paddingRight:"34px",
        "@media(max-width:959px)": {
            paddingLeft:"34px",
            paddingTop:"0px !important",
            paddingBottom:"30px !important"
        },
        [theme.breakpoints.down('sm')]:{
            paddingBottom: "32.5px"
        },
        "&:nth-child(2)":{
            [theme.breakpoints.down('sm')]:{
                "& .findSubTitle":{
                    marginTop:"16.5px !important"
                 }
            }
        },
    },
    "& .leftFindTalent":{
       paddingRight:"100px",
       "@media(max-width:959px)": {
        paddingRight:"0px !important",
      },
    },
    "& .controlProjectTitle":{
       maxWidth:"532px"
    },
    "& .controlProjectDesc":{
       maxWidth:"582px"
    },
    "& .ensureprojectTitle":{
       maxWidth:"398px",
       marginTop:"80px",
       fontFamily:"Silika-Medium",
       fontWeight:"500",
       lineHeight:"52.8px",
       fontSize:"48px",
       "@media(max-width:959px)": {
        marginTop:"10px",
        justifyContent:"flex-start !important"
     },
    },
    "& .ensureprojectTitleDiv":{
       "@media(max-width:959px)": {
        justifyContent:"flex-start !important"
     },
    },
    "& .eliminateDesc":{
       maxWidth:"582px"
    },
    "& .eliminateMeetingTitle":{
       maxWidth:"570px"
    },
    "& .findTitle": {
        fontWeight: 500,
        fontSize: "30px",
        lineHeight: "36px",
        color:"#9B9B9D",
        fontFamily: "Silika-Medium",
        [theme.breakpoints.down('sm')]:{
            fontSize: "16px",
            lineHeight: "19.3px"
        }
    },
    "& .findSubTitle": {
        fontWeight: 500,
        fontSize: "64px",
        lineHeight: "70.4px",
        color:"#0D0C22",
        marginTop:"30px",
        fontFamily: "Silika-Medium",
        [theme.breakpoints.down('sm')]:{
            fontSize: "36px",
            lineHeight: "39.6px",
            marginTop:"39.5px",
        },
        "@media(max-width:959px)": {
            marginTop: "10px"
         },
    },
    "& .subDesc":{
        marginTop:"30px",
        [theme.breakpoints.down('sm')]:{
            marginTop: "24px",
            display: "flex",
            flexFlow: "column",
            alignItems: "center"
        },
        "@media(max-width:959px)": {
           alignItems: "flex-start"
        },
    },
    "& .findSubDesc":{
        fontWeight: 300,
        fontSize: "16px",
        lineHeight: "25px",
        color:"#0D0C22",
        fontFamily: "Silika-Light",
        [theme.breakpoints.down('sm')]:{
            margin: "0 21px"
        },
        "@media(max-width:959px)": {
           margin: "0"
        },
    },
    "& .findSubDesc2":{
        fontWeight: 300,
        fontSize: "16px",
        lineHeight: "25px",
        color:"#0D0C22",
        fontFamily: "Silika-Light",
        marginTop:"49px",
        "@media(max-width:959px)": {
            marginTop: "10px"
         },
    },
    "& .getStartedBtn":{
        width:"125px",
        height: "48px",
        borderRadius: "2px",
        background: "#0D0C22",
        color: "white",
        textTransform: "none",
        fontFamily: "Silika-Medium",
        marginTop: "60px",
        [theme.breakpoints.down('sm')]:{
            marginTop: "24px"
        }
    },
    "& .groupPng":{
        height:"921px",
        width:"100%",
        [theme.breakpoints.down('sm')]:{
            marginTop: "24px",
            height: "460.5px"
        }
    },
    "& .groupPng2":{
        height:"921px",
        width:"100%",
        [theme.breakpoints.down('sm')]:{
            marginTop: "0px",
            height: "460.5px"
        }
    },
    "& .findTelentLeft": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "@media(max-width:959px)": {
           paddingLeft:"0px !important"
         },
    },
    "& .profileTitle": {
        fontSize: "48px",
        lineHeight:"52.8px !important"
    },
    "& .projectInfoImgDiv": {
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    "& .projectInfoImg": {
        height: "442px",
        width: "398px",
        [theme.breakpoints.down('sm')]:{
            height: "293px",
            width: "calc(100vw - 65px)"
        }
    },
    "& .profileListPng": {
        width: "249px",
        height: "650px"
    },
    "& .grayPaidContent": {
        backgroundColor: "#D9D9D9",
        height: "552px",
        width: "100%",
        marginRight: "90px",
        [theme.breakpoints.down('sm')]:{
            margin: "24px 0",
            height: "294px"
        }
    },
    '& .projectTitle': {
        fontSize: "48px",
        lineHeight: "52.8px",
        alignContent: "center",
        fontWeight: 500,
        fontFamily: "Silika-Medium",
        [theme.breakpoints.down('sm')]:{
            marginTop: "40px",
            marginBottom: "24px",
            fontSize: "36px",
            lineHeight: "39.6px"
        }   
    },
    '& .projectDiv': {
        display: "flex",
        marginBottom: "80px",
        paddingLeft: "69px",
        paddingRight: "69px",
        background: "white",
        [theme.breakpoints.down('sm')]:{
            paddingLeft: "25px",
            paddingRight: "24px",
            marginBottom: "31.62px"
        }
    },
    '& .projectDescription': {
        fontWeight: 300,
        fontSize: "16px",
        marginTop: "80px",
        lineHeight: "25px",
        maxWidth:"582px",
        color: "#0D0C22",
        fontFamily: "Silika-Light",
        [theme.breakpoints.down('sm')]:{
            marginTop: "24px",
            marginRight: "42px"
        }
    },
    '& .projectTitleDiv': {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .brandsLogoContainer": {
        "& *":{
            fontFamily: "Silika-Light"
        },
        backgroundColor: "#F5F3F3",
        paddingTop: "70px",
        paddingLeft: "64px",
        paddingRight: "64px",
        paddingBottom: "70px",
        [theme.breakpoints.down('sm')]:{
            paddingTop: "40px",
            paddingLeft: "25px",
            paddingRight: "25px",
            paddingBottom: "31.63px"
        },
        "& span":{
            maxWidth: "600px",
            textAlign: "center"
        }
    },
    "& .logoDesc": {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        width: "100%"
    },
    "& .logoImg": {
        marginLeft: "auto",
        marginRight: "auto",
        flexWrap: "wrap"

    },
    "& .startBtnProject": {
        backgroundColor: "#9B9B9D"
    },
    "& .logos": {
        width: "20%",
        height: "90px",
        marginTop:"15px",
        [theme.breakpoints.down('sm')]:{
            marginTop: "32px",
            width: "50%",
        },
        "@media(max-width:290px)": {
            width: "100%",
        }
    },
    '& .arrowRight': {
        marginRight: "14px"
    },

}))

// Customizable Area End

export default class LandingPage2Web extends LandingPage2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderDesktopPic = (image: string|undefined, gridClass: string, imgClass: string) => {
        const {windowWidth} = this.state;
        const isMobile = windowWidth <= 599;
        return !isMobile && <Grid item xl={6} lg={6} md={6} sm={12} className={gridClass}>
                    <img src={image} className={imgClass} />
                </Grid>
    }

    renderMobilePic = (image: string|undefined, imgClass: string) => {
        const {windowWidth} = this.state;
        const isMobile = windowWidth <= 599;
        return isMobile && <img src={image} className={imgClass} />
    }
    index=0;
    scrollableImageGrid = () => (
            <ImageList cols={2} rowHeight={242} gap={10}>
            {this.state.landingPageData[7]?.attributes.images.map((item:{url:string,id:number}) => {        
               const rowPattern = this.index % 4;
               let cols = 1;
               switch (rowPattern) {
                   case 2:
                       cols = this.index % 2 === 0 ? 2 : 1;
                       break;
                   case 3:
                       cols = 2;
                       break;  
               }
               this.index++;
              return (
                <ImageListItem key={item.id} cols={cols} rows={1}>
                  <img
                    src={`${item.url}?w=${121 * cols}&h=121&fit=crop&auto=format`}
                    srcSet={`${item.url}?w=${121 * cols}&h=121&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.id.toString()}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        );
    // Customizable Area End
    render() {
         // Customizable Area Start
         const {windowWidth} = this.state;
         const isMobile = windowWidth <= 599;
         const landingPageData=this.state.landingPageData;
       
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <StyledContainer>
                    <LandingHeader navigation={this.props.navigation} />
                    <Grid container className="findTalentContainer">
                        <Grid item xl={6} lg={6} md={6} sm={12} className="findTelentLeft leftFindTalent">
                            <Typography className="findTitle"><img src={arrowRight} className="arrowRight" alt="arrow" />FIND TALENT</Typography>
                            <Typography className="findSubTitle">{landingPageData[7]?.attributes.title}</Typography>          
                            <div className="subDesc">
                                <Typography className="findSubDesc">
                                 {this.state.landingPageData[7]?.attributes.description}
                                </Typography>
                                <Button className="getStartedBtn" onClick={() => this.handleNavigation("LandingPage3Web")} data-test-id="findTalentBtn">Get Started</Button>
                            </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} style={{maxHeight:"899px",overflow:"scroll",overflowX:"hidden"}}>
                        {this.scrollableImageGrid()}
                        </Grid>
                    </Grid>
                    <Grid container className="findTalentContainer" style={{ backgroundColor: "#F5F3F3" }}>
                        {!isMobile && <Grid item xl={6} lg={6} md={6} sm={12} className="projectInfoImgDiv">
                            <img src={landingPageData[8]?.attributes.images[0]?.url}className="groupPng profileListPng" />
                        </Grid>}
                        <Grid item xl={6} lg={6} md={6} sm={12} className="findTelentLeft" style={{ paddingLeft: isMobile ? "": "20px" }}>
                            <Typography className="findSubTitle profileTitle">{this.state.landingPageData[8]?.attributes.title}</Typography>
                            {this.renderMobilePic(landingPageData[8]?.attributes.images[0]?.url, "groupPng profileListPng")}
                            <Typography className="findSubDesc2">{this.state.landingPageData[8]?.attributes.description}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className="findTalentContainer" style={{ paddingTop: isMobile ? "0": "150px", paddingBottom: isMobile ? "31.95px": "150px" }}>
                        <Grid item xl={6} lg={6} md={6} sm={12} className="findTelentLeft">
                            <Typography className="findSubTitle profileTitle controlProjectTitle">{landingPageData[9]?.attributes.title}</Typography>
                            {this.renderMobilePic(landingPageData[9]?.attributes.images[0]?.url, "groupPng2 projectInfoImg")}
                            <div className="subDesc">
                            <Typography className="findSubDesc2 controlProjectDesc">
                            {this.state.landingPageData[9]?.attributes.description}
                            </Typography>
                            <Button className="getStartedBtn" onClick={() => this.handleNavigation("LandingPage3Web")} data-test-id="controlProjectBtn">Get Started</Button>
                            </div>
                        </Grid>
                        {this.renderDesktopPic(landingPageData[9]?.attributes.images[0]?.url, "projectInfoImgDiv", "groupPng projectInfoImg")}
                    </Grid>
                    <Grid container className="findTalentContainer" style={{
                        backgroundColor: "#F5F3F3",
                        paddingTop: isMobile ? "0.5px": "90px", paddingBottom: isMobile ? "31.91px": "90px"
                    }}>
                        {!isMobile && <Grid item xl={6} lg={6} md={6} sm={12} className="projectInfoImgDiv">
                            <Box className="grayPaidContent">  </Box>
                        </Grid>}
                        <Grid item xl={6} lg={6} md={6} sm={12} className="findTelentLeft">
                            <Typography className="findSubTitle profileTitle eliminateMeetingTitle">{landingPageData[10]?.attributes.title}</Typography>
                            {isMobile && <Box className="grayPaidContent">  </Box>}
                            <Typography className="findSubDesc2 eliminateDesc">{landingPageData[10]?.attributes.description}
                            </Typography>
                            <Button className="getStartedBtn" onClick={() => this.handleNavigation("")} data-test-id="saveTimeBtn">Get Started</Button>
                        </Grid>
                    </Grid>
                    <Grid container className="projectDiv">
                        <Grid container>
                            <Grid item xl={6} lg={6} md={6} sm={12} style={{display:"flex",justifyContent:"center"}} className="ensureprojectTitleDiv">
                                <Typography className="ensureprojectTitle">{landingPageData[11]?.attributes.title}</Typography>
                            </Grid>
                            {isMobile && <img src={landingPageData[11]?.attributes.images[0]?.url} height={isMobile? "189px": "756px"} width="100%" alt="carInner" />}
                            <Grid item xl={6} lg={6} md={6} sm={12}>
                                <Typography className="projectDescription">{landingPageData[11]?.attributes.description}
                                </Typography>
                            </Grid>
                        </Grid>
                        {!isMobile && <Grid container style={{ marginTop: "50px", marginBottom: "50px" }}>
                            <img src={landingPageData[11]?.attributes.images[0]?.url} height="756px" width="100%" alt="carInner" />
                        </Grid>}
                    </Grid>
                    <Box className="brandsLogoContainer">
                        <Typography className="logoDesc" style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span>{landingPageData[12]?.attributes.description}</span>
                        </Typography>
                        <Box className="logoDesc logoImg" style={{ marginTop: isMobile ? "0px" : "48px" }}>
                            {landingPageData[12]?.attributes.images.map((item:{url:string,id:number}, index:number) => (
                                    <Grid item className="projectTitleDiv logos" key={index}>
                                        <img src={item.url} width="111px" height="auto" alt="bently" /></Grid>
                                ))
                            }        
                        </Box>
                    </Box>
                    <Grid container className="findTalentContainer">
                        <Grid item xl={6} lg={6} md={6} sm={12} className="findTelentLeft">
                            <Typography className="projectTitle" >{landingPageData[13]?.attributes.title}</Typography>
                            <Typography className="projectDescription">{landingPageData[13]?.attributes.description}
                            </Typography>
                            <Button className="getStartedBtn startBtnProject" onClick={() => this.handleNavigation("LandingPage3Web")} data-test-id="startProjectBtn" >Get Started</Button>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} style={{maxHeight:"899px",overflow:"scroll",overflowX:"hidden"}}>
                        {this.scrollableImageGrid()}
                        </Grid>
                    </Grid>
                </StyledContainer>
                <LandingFooter navigation={this.props.navigation} />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
// Customizable Area End