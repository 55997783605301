import { Box, Button, Grid, Theme, Typography, createStyles, makeStyles, Dialog, Avatar, styled } from '@material-ui/core';
import React from 'react';
import { ProjectData, S } from '../PostCreationWebController'
import { cancle,eyeCloseIcon, eyeOpenIcon } from "../assets";
import {returnTruthyString } from "../../../../components/src/HelperUtils"


const ProfileAvatar = styled(Avatar)({
  backgroundColor: "#DFDFDF",
  color: "#9B9B9D",
  fontFamily: "Silika-Medium !important",
  "& .MuiAvatar-img": {
    objectFit: "fill",
  }
});


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalBackdrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        GridMain: {
            border: "1px solid #D9D9D9",
            backgroundColor : "white",
            paddingLeft: "30px",
            paddingRight: "30px",
            [theme.breakpoints.down('xs')]: {
              paddingLeft: "2px",
              paddingRight: "2px",
              border: "none"
            },
        },
        imageDiv: {
          width: "100%",
          height: "60vh",
          position : "relative",
          marginBottom: "50px"
      },
      imageBox: {
        width: "100%",
        padding: "20px"
      },
        imageStyle: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        },
        buttonOutline: {
            borderRadius: "2px",
            borderColor: "black",
            marginTop: "20px",
            float: "right",
            textDecoration: "none",
            textTransform: "none"
        },
        buttonFilled: {
            marginLeft: "20px",
            borderRadius: "2px",
            backgroundColor: "black",
            color: "white",
            marginTop: "20px",
            float: "right",
            textDecoration: "none", textTransform: "none"
        },
        buttonClass: {
            width: "100%",
            paddingTop: "20px",
            paddingBottom: "20px", 
            "& button":{
              fontFamily: "Silika-Medium"
            }
        },
        projectTitle: {
            fontFamily: "Silika-Medium",
            fontSize: "20px",
            fontWeight: 500,
            padding: "20px"
        },
        projectDescription: {
            fontFamily: "Silika-Medium",
            fontSize: "16px",
            fontWeight: 300,
            padding: "20px",
            color: "#000000",
            whiteSpace: 'pre-wrap',
            [theme.breakpoints.down('xs')]: {
              width: "218px",
              fontSize: "14px",
              wordWrap: "break-word",
            },
        },
        descriptionText: {
            display: "flex !important",
            flexDirection: "column",
        },
        profileDiv: {
            position: "relative" as "relative",
            display: "flex",
            gap: "18px",
            marginTop: "80px",
            marginLeft: "22px",
            width: "100%",
            [theme.breakpoints.down('xs')]: {
              marginTop: "25px",
              gap: "10px",
            },
          },
          profileImgNew: {
            width: "60px",
            Height: "60px",
            borderRadius: "50px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          },
          userNameDiv: {
            display: "flex",
            justifyContent: "space-between",
          },
          describtionInterior: {
            fontSize: "14px",
            fontFamily: "Silika-Medium",
            fontWeight: 500,
            lineHeight: "140.625%",
            fontStyle: "normal",
            color: "#121212",
            [theme.breakpoints.down('xs')]: {
              fontSize: "12px",
              width: "120px",
              wordWrap: "break-word",
            },
          },
          userNameInterior: {
            fontSize: "14px",
            fontFamily: "Silika-Medium",
            fontWeight: 500,
            lineHeight: "normal",
            fontStyle: "normal",
            color: "#64748B",
            [theme.breakpoints.down('xs')]: {
              fontSize: "12px",
              width: "120px",
              wordWrap: "break-word",
            },
          }, 
          rightAlignOption: {
            position: "absolute" as "absolute",
            right: "16px"
          },
          headerImage: {
            height: "32px",
            width: "32px",
            flexShrink: 0,
            [theme.breakpoints.down('xs')]: {
              height: "25px",
              width: "25px",
            },
          },
          profileImgStyle : {
            width: "68px",
            height: "68px",
            [theme.breakpoints.down('xs')]: {
              width: "40px",
              height: "40px",
            },
          },
          openEyeIcon: {
            position: 'absolute',
            right: "-35px",
            top: "-10px",
          },
          closeEyeIcon: {
            position: 'absolute',
            right: "-10px",
            top: "-9px",
          },
    })
);
type Props = {
    state: S
    updatePost: (value: ProjectData, isEdited:boolean) => void
    handlePreviewClose : ()=>void
    isEdited:boolean
    profileImage:any
    handleSetVisiblePreview : (id : number, is_visible : boolean, index: number) =>void
}

function Preview(this: any, props: Props) {
    const { state, updatePost, handlePreviewClose, isEdited, profileImage, handleSetVisiblePreview } = props
    const classes = useStyles();
    const post = state.postData.attributes
    const avatarName = returnTruthyString(post?.profile?.data.attributes?.first_name[0]) + returnTruthyString(post.profile.data.attributes.last_name[0]);
    const showVisibleInvisible = (image:any, index: number)=>{
      return(
      <>
      {state.uploadMultipleImagesAPI.length > 1 &&
      <>
      {image.is_visible ? (
        <img
          onClick={() =>
            handleSetVisiblePreview(image.id, false, index)
          }
          src={eyeOpenIcon}
          alt="eyeOpen"
          data-test-id="eyeOpen"
          className={classes.openEyeIcon}
        />
      ) : (
        <img
          onClick={() =>
            handleSetVisiblePreview(image.id, true, index)
          }
          src={eyeCloseIcon}
          alt="eyeClose"
          data-test-id="eyeClose"
          className={classes.closeEyeIcon}
        />
      )}
      </>}
      </>
      )
    }
    const showImagesVideos = (image:any, index:number) =>{
      return(
        <>
      {image.type.includes("image") ? (
        <div className={classes.imageDiv}>
          <img
            className={classes.imageStyle}
            src={URL.createObjectURL(image)}
            style={{ filter: state.savedValues.images[index]?.is_visible ? "blur(0)" : "blur(2px)" }}
          />
          {showVisibleInvisible(state.savedValues.images[index], index)}
        </div>
      ) : 
      <div className={classes.imageDiv}>
          <video className={classes.imageStyle} controls style={{ filter: state.savedValues.images[index].is_visible ? "blur(0)" : "blur(2px)" }}>
            <source
              src={URL.createObjectURL(image)}
              type="video/mp4"
            />
          </video>
          {showVisibleInvisible(state.savedValues.images[index], index)}
        </div>
        }
        </>
      )
    } 
    return (
        <Dialog
      open={state.displayPreview}
      onClose={handlePreviewClose}
      PaperProps={{
        style: { backgroundColor: 'transparent', boxShadow: 'none', maxWidth: "1200px" },
      }}
      BackdropProps={{
        classes: {
          root: classes.modalBackdrop,
        },
      }}
    >
        <Grid container sm={12} item className={classes.GridMain}>
        <div className={classes.profileDiv}>
            <div>
            <ProfileAvatar src={profileImage} className={classes.profileImgStyle}>
                {avatarName}
              </ProfileAvatar>
            </div>
            <div className={classes.userNameDiv}>
              <div style={{paddingTop: "8px"}}>
                <Typography className={classes.describtionInterior}>{state.savedValues.projectTitle}</Typography>
                <Typography className={classes.userNameInterior}>
                  {sessionStorage.getItem("firstName")+" "+sessionStorage.getItem("lastName")}
                </Typography>
              </div>
            </div>
            <Box className={classes.rightAlignOption}>
            <img
              src={cancle}
              alt="option"
              className={classes.headerImage}
              onClick={handlePreviewClose}
            />
          </Box>
          </div>
          <div className={classes.imageBox}>
            {state.uploadMultipleImagesAPI.length > 0 && (
            <>
             {showImagesVideos(state.uploadMultipleImagesAPI[0], 0)}
            </>
            )}
          </div>
            <Box className={classes.descriptionText}>
                <Typography className={classes.projectDescription}>{state.savedValues.description}</Typography>
            </Box>
            <div className={classes.imageBox}>
            {state.uploadMultipleImagesAPI.slice(1)?.map((srcImage, index) => (
                    showImagesVideos(srcImage, index+1)
            )
            )}
            <Box className={classes.buttonClass}>
                <Button variant="contained" id='publishPost' className={classes.buttonFilled}  type="submit" onClick={() => updatePost(state.savedValues, isEdited)}>Publish</Button>
                <Button variant="outlined" className={classes.buttonOutline} onClick={handlePreviewClose} >Cancel</Button>
            </Box>
            </div>
        </Grid>
        </Dialog>
    )
}

export default Preview
