import React, {useEffect, useState} from "react";
import { InputAdornment, Button, ButtonGroup, styled, Theme, Typography, TextField } from "@material-ui/core";
import { FavouriteTabsProps } from "../types";
import FavouriteEventsList from "./FavouriteEventsList";
import FavouriteProjectsList from "./FavouriteProjectsList";
import FavouritePeopleList from "./FavouritePeopleList";
import SearchIcon from '@material-ui/icons/Search';

const StyledTextField = styled(TextField)(({theme}:{theme: Theme}) => ({
    [theme.breakpoints.down('sm')]: {
        margin: "0 6px",
        width: "calc(100% - 12px) !important",
    },
    "& .textfield":{
        height: "48px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px",
            "& input":{
                width: "calc(100% - 38px) !important"
            },  
        }
    }
}));

const StyledContainer = styled("div")(({ theme }: { theme: Theme }) => ({
    margin: "70px 80px auto 80px",
    "& *": {
        fontFamily: "Silika-Medium",
        textTransform: "none"
    },
    [theme.breakpoints.down('sm')]: {
        margin: "0 auto",
        width: "calc(100vw - 40px)"
    },
    "& .title": {
        fontSize: "32px",
        margin: "30px 6px 95px 6px",
        marginBottom: "95px",
        [theme.breakpoints.down('sm')]: {
            marginTop: "0px",
            marginBottom: "24px",
            fontSize: "24px"
        }
    }
}));

const StyledTabs = styled(ButtonGroup)(({ theme }: { theme: Theme }) => ({
    height: "67px",
    margin: "0px 0px 50px 0px",
    "& button": {
        border: "none",
        borderBottom: "2px solid #DFDFDF",
        borderRadius: "0",
        fontSize: "24px",
        color: "#9B9B9D",
        [theme.breakpoints.down('sm')]: {
            fontSize: "13px",
            borderRadius: "82px !important",
            border: "none !important"
        }
    },
    [theme.breakpoints.down('sm')]: {
        height: "27px",
        marginBottom: "24px",
        width: "calc(100vw - 50px) !important"
    },
    "& .activeTab": {
        borderBottom: "2px solid #0D0C22 !important",
        color: "#0D0C22 !important",
        [theme.breakpoints.down('sm')]: {
            borderRadius: "82px !important",
            background: "#0D0C22 !important",
            color: "#fff !important"
        }
    }
}));
interface S {
    windowWidth:number
}
class FavouriteTabs extends React.Component<FavouriteTabsProps, S>{
    constructor(props: FavouriteTabsProps){
        super(props);
        this.state= {
            windowWidth: window.innerWidth
        }
    }

    componentDidMount(): void {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        this.setState({windowWidth: window.innerWidth});
    }

    getSearchPlaceholder = (activeTab: string) => {
        if(activeTab === "events"){
            return {
                web: "Event name, Organizer",
                mobile: "Event name, Organizer"
            }
        }else if(activeTab === "projects"){
            return {
                web: "Project name, Organizer",
                mobile: "Project name, Organizer"
            }
        }else{
            return {
                web: "Eg. Brand name, project title, skills",
                mobile: "Eg. Brand name, project title, skills used"
            }
        }
    };

    render(){
        const {activeTab, onTabChange, onClickNavigateToPostDetails} = this.props;
        const isMobile = this.state.windowWidth <= 599;
        const placeholder = this.getSearchPlaceholder(activeTab);
            return <StyledContainer data-test-id="favouritesTabContainer">
            <Typography className="title">Favourites</Typography>
            <StyledTabs fullWidth>
            <Button
                data-test-id="projects"
                onClick={() => onTabChange("projects")}
                className={activeTab === "projects" ? "activeTab" : ""}>
                Projects
            </Button>
            <Button
                data-test-id="people"
                onClick={() => onTabChange("people")}
                className={activeTab === "people" ? "activeTab" : ""}>
                People
            </Button>
            <Button
                data-test-id="jobs"
                onClick={() => onTabChange("jobs")}
                className={activeTab === "jobs" ? "activeTab" : ""}>
                Jobs
            </Button>
            <Button
                data-test-id="events"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => onTabChange("events")}
                className={activeTab === "events" ? "activeTab" : ""}>
                Events
            </Button>
            </StyledTabs>
            <StyledTextField 
                fullWidth
                variant="outlined"
                type="text"
                data-test-id="searchFav"
                onChange={this.props.onChangeSearchKey}
                value={this.props.searchKey}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                        ),
                    className: "textfield"
                }}
                placeholder={ isMobile ? placeholder.mobile : placeholder.web}
            />
            {activeTab === "projects"? <FavouriteProjectsList onClickNavigateToPostDetails={onClickNavigateToPostDetails} windowWidth={this.state.windowWidth} favouritesProjects={this.props.favouritesProjects} />: ""}
            {activeTab === "people" ? <FavouritePeopleList windowWidth={this.state.windowWidth} favouritesPeople={this.props.favouritesPeople}/>: ""}
            {activeTab === "events"
            ? <FavouriteEventsList 
                shareLink={this.props.shareLink}
                profiles={this.props.profiles} 
                favourites={this.props.favourites}
                onShareDataChange={this.props.onShareDataChange}
                onChangeChatMessage={this.props.onChangeChatMessage}
                chatMessage={this.props.chatMessage}
                shareData={this.props.shareData}
                onClickShareButton={this.props.onClickShareButton}
                isShareModalOpen={this.props.isShareModalOpen}
                isChatSend={this.props.isChatSend}
                isChatModalOpen={this.props.isChatModalOpen}
                onClickBackButton={this.props.onClickBackButton}
                onClickChatButton={this.props.onClickChatButton}
                onClickSendChat={this.props.onClickSendChat}
                onClose={this.props.onClose}
                onClickDeleteFavourite={this.props.onClickDeleteFavourite}
                onNavigateToDetailsPage={this.props.onNavigateToDetailsPage}
                isLoading={this.props.isLoading}
                count={this.props.count}
                onLoadMoreClick={this.props.onLoadMoreClick}
                needLoadMoreBtn={this.props.needLoadMoreBtn}
            />: ""}
        </StyledContainer>
    }
} 
export default FavouriteTabs;