import { ReactComponent as InstagramIcon } from "../assets/instagram.svg";
import { ReactComponent as LinkedInIcon } from "../assets/linkedin.svg";
import { ReactComponent as BehanceIcon } from "../assets/behance.svg";
import { ReactComponent as ViomIcon } from "../assets/vimo.svg";
import { ReactComponent as YoutubeIcon } from "../assets/yt.svg";
import { ReactComponent as TikTokIcon } from "../assets/tt.svg";

export const profileImg = require("../assets/profileImg.svg");
export const imageTemplate = require("../assets/imageTemplate.svg");
export const likeImage = require("../assets/likeImage.svg");
export const dislikeImage = require("../assets/dislikeImage.svg");
export const shareImage = require("../assets/share.png");
export const saveImage = require("../assets/saveImage.svg");
export const fullScreen = require("../assets/fullscreen.png");
export const exitFullScreen =require("../assets/exitfullscreen.png");
export const upArrow = require("../assets/upArrow.png");
export const downArrow =require("../assets/downArrow.png");
export const favIcon =require("../assets/heart.png");
export const poundIcon =require("../assets/pound.png");
export const removeIcon =require("../assets/minus.png");
export const addIcon =require("../assets/plus.svg");
export const searchIcon = require("../assets/search.png");
export const leftArrow = require("../assets/left-arrow.png");
export const rightArrow = require("../assets/right-arrow.png");
export const closeImage = require("../assets/close.svg");
export const instagram = require("../assets/instagram.svg");
export const linkedin = require("../assets/linkedin.svg");
export const behance = require("../assets/behance.svg");
export const viom = require("../assets/vimo.svg");
export const yt = require("../assets/yt.svg");
export const tt = require("../assets/tt.svg");
export const ChatIcon = require("../assets/image__.svg");
export const CopyImage = require("../assets/group_icon.svg");
export const BackBtn = require("../assets/back.svg");
export const userImage = require("../assets/user.png");
export const optionIcon = require("../assets/button_icon_options.svg");
export const EditProfileDefault = require("../assets/Default_Image.svg");
export const location = require("../assets/location.svg")
export const companyLogo = require("../assets/companyLogo.png")
export { InstagramIcon, LinkedInIcon, BehanceIcon, ViomIcon, YoutubeIcon, TikTokIcon };








