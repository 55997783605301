import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import ImageDiv, { StyledContainer } from "./components/ImageDiv";
import TitleContainer from "./components/TitleContainer";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class LandingPage5Web extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const findDesignTalents = this.state.findDesignTalents[0]?.attributes || {};
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <LandingHeader navigation={this.props.navigation} />
        <StyledContainer>
          <TitleContainer
            title={findDesignTalents.title}
            description={findDesignTalents.description}
            backgroundColor="#F5F3F3"
          />
          {findDesignTalents.design_talent_features?.data.map((item, index) => (
            <ImageDiv
              title={item.attributes.title}
              description={item.attributes.description}
              image={item.attributes.image}
              direction={index % 2 === 1 ? "right" : "left"}
              handleNavigation={this.handleNavigation}
            />
          ))}
        </StyledContainer>
        <LandingFooter navigation={this.props.navigation} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
