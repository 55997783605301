import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik } from "formik";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TermsAndConditions from "../../email-account-login/src/TermsAndConditions.web";
import PrivacyPolicy from "../../email-account-login/src/PrivacyPolicy.web";
import LoginSignUp, { webStyle , StyledButton, StyledTypography, StyledTextField, BorderOr} from "../../email-account-login/src/LoginSignUp.web";
import "../../customisableuserprofiles/src/ProfileInfo.css"
import AccountRegistrationController, { Props } from "./AccountRegistrationController";


// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },

});



export default class AccountRegistration extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    
    return (
      // Customizable Area Start
      // Required for all blocks
      
      <ThemeProvider theme={theme}>
        <div style={{ overflow: 'hidden'}} data-test-id="AccountRegiContainer">
          <LoginSignUp navigation={this.props.navigation} id={""}>
          {this.state.termsPopup && <TermsAndConditions open={this.state.termsPopup} onClose={this.handleTermsNavigate} />}
          {this.state.privacyPopup && <PrivacyPolicy open={this.state.privacyPopup} onClose={this.handlePrivacyPolicyClick} />}
            <Box width={{xs : "300px", sm : "327px",  md: "360px"}}>
              <Formik
                initialValues={{
                  email: this.state.signUpFormData.email,
                  password: this.state.signUpFormData.password,
                  confirmPassword: this.state.signUpFormData.confirmPassword
                }}
                validateOnChange={false}
                data-testid="formikForm"
                onSubmit={() => { }}
              >
                {({ handleSubmit}) => {
                   const isDisabled = !this.state.signUpFormData.email || !this.state.signUpFormData.password || !this.state.signUpFormData.confirmPassword;
                  return(
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <div style={webStyle.formTitleContainer}>
                      <StyledTypography style={{ ...webStyle.formTitleText, fontFamily: 'Silika-Medium' }}>
                        Join Forge
                      </StyledTypography>
                    </div>
                    <Box >
                      <StyledTextField
                        placeholder={this.isCreative?"Email":"Company Email"}
                        variant="outlined"
                        value={this.state.signUpFormData.email}
                        error={!!this.state.emailError}
                        helperText={this.state.emailError}
                        style={{ ...webStyle.inputfieldHeight }}
                        data-test-id={"txtEmail"}
                        name={"email"}
                        onChange={(event: { target: { name: string; value: string; }; }) => {
                          this.handleChangeUpload(event)
                        }}
                        onBlur={() => { this.checkValidEmail() }}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: 0,
                          }
                        }}
                      />
                    </Box>
                    <Box style={webStyle.passwordInputPosition}>
                      <div style={webStyle.isAccountDiv}>
                        <Typography style={{ ...webStyle.isAccountStatement, fontSize: "12px" }}>
                          The password should have at least one lower case and one upper case characters
                        </Typography>
                      </div>
                      <StyledTextField
                        fullWidth
                        placeholder="Password"
                        data-test-id={"txtPassword"}
                        name="password"
                        error={!!this.state.passFieldError}
                        helperText={this.state.passFieldError}
                        style={{ ...webStyle.inputfieldHeight }}
                        variant="outlined"
                        type={this.state.showPassword ? "text" : "password"}
                        value={this.state.signUpFormData.password}
                        autoComplete='new-password'
                        onChange={(event: { target: { name: string; value: string; }; }) => {
                          this.handleChangeUpload(event)
                        }}
                        onBlur={() => { this.checkValidPassword() }}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: 0,
                          }
                        }}
                        InputProps={{
                          endAdornment:
                            !this.state.showPassword
                              ? (<VisibilityOffIcon
                                style={webStyle.passwordVisibilityOffIcon}
                                onClick={this.handlePassShow}
                              />)
                              : (<VisibilityIcon
                                style={webStyle.passwordVisibilityOnIcon}
                                onClick={this.handlePassShow}
                              />)
                        }}
                      />
                    </Box>
                    <Box style={webStyle.passwordInputPosition}>
                      <StyledTextField
                        fullWidth
                        placeholder="Re-enter Password"
                        data-test-id={"confirmPassword"}
                        onBlur={() => { this.checkConfirmPassword() }}
                        style={{ ...webStyle.inputfieldHeight }}
                        error={!!this.state.confirmPassError}
                        helperText={this.state.confirmPassError}
                        variant="outlined"
                        type={this.state.showConfirmPassword ? "text" : "password"}
                        value={this.state.signUpFormData.confirmPassword}
                        name="confirmPassword"
                        onChange={(event: { target: { name: string; value: string; }; }) => {
                          this.handleChangeUpload(event)
                        }}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: 0,
                          }
                        }}
                        InputProps={{
                          endAdornment:
                            !this.state.showConfirmPassword
                              ? (<VisibilityOffIcon
                                style={webStyle.passwordVisibilityOffIcon}
                                onClick={this.handleConfirmPassShow}
                                data-test-id="showConfirmPassword"
                              />)
                              : (<VisibilityIcon
                                style={webStyle.passwordVisibilityOnIcon}
                                onClick={this.handleConfirmPassShow}
                                data-test-id="hideConfirmPassword"
                              />)
                        }}
                      />
                    </Box>
                    <Box>
                      <div style={{ ...webStyle.isAccountDiv, marginTop : "10px" }}>
                        <Typography style={{ ...webStyle.isTermsAndConditionStatement}}>
                          By joining Forge you agree to the<span
                            data-test-id={"termsConditions"}
                            style={{
                              ...webStyle.signUplink,
                              fontSize: "12px",
                              fontFamily: 'Silika-Medium',
                              textDecoration: 'underline'
                            }}
                            onClick={() => {
                              this.handleTermsNavigate()
                            }}>
                            Terms & Conditions </span>and
                          <span
                            onClick={() => {
                              this.handlePrivacyPolicyClick()
                            }}
                            data-test-id={"privacyPolicy"}
                            style={{ ...webStyle.signUplink, fontFamily: 'Silika-Medium', fontSize: "12px",  textDecoration: 'underline' }}>Privacy Policy</span>
                        </Typography>
                      </div>
                    </Box>
                    <div>
                      <StyledButton
                        variant="outlined"
                        style={{
                          ...webStyle.logInButton,
                          textTransform: "none",
                          ...(isDisabled ? webStyle.disabledButton : {}),
                        }}
                        type="submit"
                        data-test-id='submitBtn'
                        onClick={() => this.handleSignInSubmit()}
                        disabled={isDisabled}
                      >
                        {this.isCreative?"Sign Up":"Next"}
                      </StyledButton>
                    </div>
                    <div style={webStyle.borderContainer}>
                      <div>
                        <BorderOr/>
                      </div>
                      <Typography style={webStyle.or}>or</Typography>
                      <div>
                        <BorderOr />
                      </div>
                    </div>
                    <div style={webStyle.isAccountDiv}>
                      <Typography style={webStyle.isAccountStatement}>
                        Already have an account?
                      </Typography>
                      <span
                        onClick={() => { this.handleLoginClick() }}
                        data-test-id={"userLogin"}
                        style={{ ...webStyle.signUplink, fontFamily: 'Silika-Medium' }}>Log In</span>
                    </div>

                  </form>
                )}}
              </Formik>
            </Box>
          </LoginSignUp>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

