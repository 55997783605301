import { Container, Typography, createTheme } from "@material-ui/core";
import React from "react";
import {  ThemeProvider } from "react-native-elements";
// Customizable Area Start
import HeaderNavbar from "../../../components/src/Navbar";
import JobProfileWebController, {Props} from "./JobProfileWebController";
import CoverImageComponent from "../../../components/src/CoverImageComponent.web";
import JobCard from "../../../components/src/JobCard.web";
import {shareImage, saveImage, companyLogo} from "./assets"

const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff"
      }
    }
  });
const headingBar = (title : string) =>{
    return(
      <div style={webStyle.headingBar}>
          <Typography style={webStyle.blockHeadding}>
            {title}
          </Typography>
      </div>
    )
  }
export const ProfileCard = ()=>{
    return(
      <div data-test-id="profile-container" style={webStyle.profileContainer}>
          <JobCard
              title="Automotive Designer"
              category="Supercars"
              updatedTime="5 days ago"
              level="Senior"
              location="Coventry, UK"
              type="Full Time | Remote"
              profession="Interior Design | UI/UX Design | Visualization"
              dates="13 Mar 24 - 25 Apr 24"
              rate="£45.00/hr"
              budget="£2,000"
              description=""
              imageUrl={companyLogo}
          />
      </div>
    )
  }

  const DescriptionContainer = ()=>{
    return(
      <div style={webStyle.administratorBlock}>
          {headingBar("Description")}
          <div style={webStyle.descriptionContainer}>
        <Typography style={webStyle.descriptionTitle}>
            Automotive Designer
        </Typography>
        <Typography style={webStyle.descriptionDes}>
        An Automotive Designer is responsible for creating the design and  styling of new vehicles, both for production and concept models. Working  with a team of designers, engineers and other professionals, the  Automotive Designer will develop ideas and concepts to meet the needs of  customers and stakeholders. This involves researching markets, trends  and customer needs, sketching ideas and creating 3D models. The  Automotive Designer will be responsible for creating sketches, renders  and illustrations of their concepts, as well as creating presentations  to showcase their ideas. The Automotive Designer must have a strong  understanding of the engineering and manufacturing processes, as well as  how to use CAD software to design and create models. A strong eye for  detail, excellent communication and problem-solving skills are  essential.   
        An Automotive Designer is responsible for creating the design and  styling of new vehicles, both for production and concept models. Working  with a team of designers, engineers and other professionals, the  Automotive Designer will develop ideas and concepts to meet the needs of  customers and stakeholders. This involves researching markets, trends  and customer needs, sketching ideas and creating 3D models. The  Automotive Designer will be responsible for creating sketches, renders  and illustrations of their concepts, as well as creating presentations  to showcase their ideas. The Automotive Designer must have a strong  understanding of the engineering and manufacturing processes, as well as  how to use CAD software to design and create models. A strong eye for  detail, excellent communication and problem-solving skills are  essential.

        Details:   

        An Automotive Designer is responsible for creating the design and  styling of new vehicles, 
        Responsible for creating the design and  styling of new vehicles, 
        An Automotive Designer is responsible for creating the design and  styling of new vehicles, 
        Creating the design and  styling of new vehicles, 

        </Typography>
      </div>
      </div>
    )
  }

  const OpportunityContainer = ()=>{
    return(
      <div style={webStyle.administratorBlock}>
          {headingBar("Recomended Opportunities")}
          <div style={webStyle.opportunityBody}>
            <Opportunity />
            <Opportunity />
            <Opportunity />
          </div>
      </div>
    )
  }

  const Opportunity=()=>{
    return(
      <div style={webStyle.opportunityCard}>
        <img src={companyLogo} style={webStyle.opportunityImage} alt="Job" />
        <div style={webStyle.titleDiv}>
          <Typography style={webStyle.opportunityTitle}>
            Automotive Designer
          </Typography>
          <div style={webStyle.iconDIv}>
            <img src={shareImage} style={webStyle.iconStyle}></img>
            <img src={saveImage} style={webStyle.iconStyle}></img>
          </div>
        </div>
        <div style={webStyle.subTitleDiv}>
        <Typography style={webStyle.opportunityCategory}>
            Supercars
          </Typography>
          <Typography style={webStyle.opportunityTime}>
            A3 Days Ago
          </Typography>
        </div>
        <Typography style={webStyle.opportunityDescription}>
          An Automotive Designer is responsible for creating the design and  styling of new vehicles, both for production and concept models. Working  with a team of designers, engineers and other professionals..
          </Typography>
      </div>
    )
  }
// Customizable Area End

export default class JobProfile extends JobProfileWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    
    return (
      <ThemeProvider theme={theme}>
        <HeaderNavbar navigation={this.props.navigation}/>
        <Container maxWidth='xl'  style ={webStyle.parentDiv}>
          <CoverImageComponent />
          <ProfileCard />
          <DescriptionContainer />
          <OpportunityContainer />
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End  
  }
}

// Customizable Area Start
export const webStyle = {
    parentDiv : {
        width : "100%",
        padding: "0 0 95px 0",
        display : 'flex', 
        flexDirection : 'column' as 'column', 
        alignItems : 'center'
      },
      profileContainer : {
        marginTop : `-110px`,
        width: '85%',
        border: '1px solid #DFDFDF',
        zIndex: 2,
        backgroundColor: "white",
        borderRadius: '4px',
      },
      headingBar : {
        height : "60px",
        width : "100%",
        backgroundColor : "#0D0C22",
        padding : "0 0 0 48px",
        display : "flex",
        alignItems : "center"
        
      },
      blockHeadding : {
        color : "white",
        fontFamily : "Silika-Medium",
        fontWeight : 500,
        fontSize : "16px",
        lineHeight : "19.3px"
      },
      administratorBlock : {
        width : '85%',
        border : '1px solid #DFDFDF',
        marginTop : '70px',
        overflow : "hidden",
        borderRadius : '4px',
      },
      descriptionDes : {
        fontFamily : "Silika-Light",
        fontWeight : 200,
        fontSize : "16px",
        lineHeight : "18.98px",
      },
      descriptionContainer : {
        width : '100%',
        padding : "48px",
        boxSizing: 'border-box' as 'border-box',
        display : "flex",
        flexDirection : "column" as "column",
        gap : "24px"
      },
      opportunityBody : {
        width : '100%',
        padding : "48px",
        boxSizing: 'border-box' as 'border-box',
        display : "flex",
        flexWrap  :"wrap" as "wrap",
        justifyContent : "space-evenly"
      },
      descriptionTitle : {
        fontFamily : "Silika-Medium",
        fontWeight : 500,
        fontSize : "16px",
        lineHeight : "19.3px",
      },
      opportunityCard : {
        height : "310px",
        width : "375.97px",
        border : "1px solid #DFDFDF",
        padding : "20px",
        boxSizing: 'border-box' as 'border-box',
        borderRadius : "2px"
      },
      opportunityImage : {
        height : "88px",
        width : "94.26",
        border : "1px solid #DFDFDF",
        borderRadius : "2px"
      },
      titleDiv : {
        display : "flex",
        justifyContent : "space-between",
        marginTop : "38px",
        alignItems : "center"
      },
      opportunityTitle : {
        fontFamily : "Silika-Medium",
        fontSize : "20px",
        LineHeight : "24.13px"
      },
      iconDIv : {
        display : "flex",
        gap : "4px"
      },
      iconStyle : {
        height : "24px",
        width : "24px"
      },
      subTitleDiv : {
        display : "flex",
        gap : "75px",
        marginTop : "5px",
        paddingBottom  :"9px",
        borderBottom : "1px solid #DFDFDF"
      },
      opportunityCategory : {
        fontFamily : "Silika-Light",
        fontWeight : 300,
        fontSize: '14px',
        lineHeight : "19.69px"
      },
      opportunityTime : {
        fontFamily : "Silika-Medium",
        fontWeight : 500,
        fontSize: '12px',
        lineHeight : "16.88px",
        color : "#9B9B9B"
      },
      opportunityDescription : {
        fontFamily : "Silika-Light",
        fontWeight : 200,
        fontSize: '12px',
        lineHeight : "14.23px",
        marginTop : "20px"
      }
};
// Customizable Area End
